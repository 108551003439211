import {
  Paper,
  Title,
  Center,
  Loader,
  Table,
  Stack,
  Menu,
  Button,
  Anchor,
  Text,
  Box,
  Group,
} from '@mantine/core';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowRight, IconFile } from '@tabler/icons-react';
import { formatDate } from '@/utils/stringFormat';
import httpClient from '@/utils/httpClient';
import { TenancyCertificate } from '@/models/tenant/certificates.model';

interface TenancyCertificatesProps {
  tenancyId: number;
}

function useCertificates(tenancyId: number): UseQueryResult<TenancyCertificate[], Error> {
  return useQuery({
    queryKey: ['tenancy', 'certificates', tenancyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/tenancies/${tenancyId}/certificates`);
      return data;
    },
    enabled: !!tenancyId,
  });
}

export function TenancyCertificates({ tenancyId }: TenancyCertificatesProps) {
  const { data, error, isPending } = useCertificates(tenancyId);
  const isMobile = useMediaQuery('(max-width: 800px)');

  if (isPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Title order={4} my="sm">
        There are no certificates on this property yet
      </Title>
    );
  }

  const viewFileButton = (fileUrls: string[]) => {
    if (fileUrls && fileUrls.length > 1) {
      return (
        <Menu trigger="hover" shadow="md" width={120} position="right-start">
          <Menu.Target>
            <Button variant="light" rightSection={<IconArrowRight size="1rem" />}>
              View
            </Button>
          </Menu.Target>

          <Menu.Dropdown p={0}>
            {fileUrls.map((file, index) => (
              <Anchor key={index} href={file} target="_blank" underline="never">
                <Menu.Item leftSection={<IconFile />}>File {index + 1}</Menu.Item>
              </Anchor>
            ))}
          </Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <Anchor href={fileUrls[0]} target="_blank" underline="never">
        <Button variant="light" rightSection={<IconArrowRight size="1rem" />}>
          View
        </Button>
      </Anchor>
    );
  };

  const rows = data.map((row: TenancyCertificate) => (
    <Table.Tr key={row.id}>
      <Table.Td>{row.certificateTypeName}</Table.Td>
      <Table.Td>{formatDate(row.expiryDate)}</Table.Td>
      <Table.Td>{row.files?.length > 0 ? viewFileButton(row.files) : 'No files'}</Table.Td>
    </Table.Tr>
  ));

  const mobileCertificates = data.map((certificate: TenancyCertificate) => (
    <Paper key={certificate.id} shadow="xs" p="md">
      <Stack gap={4}>
        <Title order={4}>{certificate.certificateTypeName}</Title>
        <Group wrap="wrap" gap={6}>
          <Text fw="bold">Expiry Date:</Text>
          <Text>{formatDate(certificate.expiryDate)}</Text>
        </Group>
        {certificate.files?.length > 0 && <Box mt={6}>{viewFileButton(certificate.files)}</Box>}
      </Stack>
    </Paper>
  ));

  return (
    <>
      {!isMobile && (
        <Paper shadow="xs" my="sm">
          <Title order={3} mb="xs" px="md" pt="md">
            Certificates
          </Title>

          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Certificate</Table.Th>
                <Table.Th>Expiry Date</Table.Th>
                <Table.Th>Files</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </Paper>
      )}

      {isMobile && (
        <Stack mt="xs">
          <Title order={3}>Certificates</Title>

          <Center>
            <Stack w="100%">{mobileCertificates}</Stack>
          </Center>
        </Stack>
      )}
    </>
  );
}
