import { Text, Container, Title, useMantineColorScheme, Box } from '@mantine/core';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

export function MaintenanceImageUploader({ uppy }: { uppy: Uppy }) {
  const { colorScheme } = useMantineColorScheme();

  return (
    <Container>
      <Title order={4}>Please upload some photos of the issue.</Title>
      <Text c="dimmed">
        This will help us understand the issue better and provide a faster resolution.
      </Text>

      <Box mt="md">
        <Dashboard
          uppy={uppy}
          height={300}
          hideUploadButton
          hideCancelButton
          proudlyDisplayPoweredByUppy={false}
          theme={colorScheme}
        />
      </Box>
    </Container>
  );
}
