import '@mantine/core/styles.css';

import ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MantineProvider } from '@mantine/core';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import { Router } from '@/Router';
import { theme } from './theme';
import AuthProvider from '@/hooks/useAuth';

Sentry.init({
  dsn: 'https://cb12ea506706f9a549e571171075b2fd@o4506112020316160.ingest.sentry.io/4506773427453952',
  integrations: [],
  enabled: process.env.NODE_ENV === 'production',
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={theme}>
          <Router />
        </MantineProvider>

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AuthProvider>
  </StrictMode>
);
