import { Paper, Title, Text, Flex, Grid, Alert, Anchor, Group, Spoiler } from '@mantine/core';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { formatCurrency, formatShortDate, formatTime } from '@/utils/stringFormat';
import { TeamDetailsCard } from '@/components/Common/TeamDetailsCard/TeamDetailsCard';
import httpClient from '@/utils/httpClient';
import { TenancyViewing } from '@/models/tenant/viewings.model';
import { TenancyData } from '@/models/tenant/tenancy.model';

function useViewings(tenancyId: number | undefined): UseQueryResult<TenancyViewing[], Error> {
  return useQuery({
    queryKey: ['tenancy', 'viewings', tenancyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/tenancies/${tenancyId}/viewings`);
      return data;
    },
    enabled: !!tenancyId,
  });
}

interface TenancyDetailsProps {
  tenancyDetails: TenancyData;
}

export function TenancyDetails({ tenancyDetails }: TenancyDetailsProps) {
  const { data: viewings } = useViewings(tenancyDetails.id);

  return (
    <Grid p={0} mt="xs">
      {viewings && viewings.length > 0 && (
        <Grid.Col span={12}>
          <Alert
            title={`There ${viewings.length === 1 ? 'is 1 viewing' : `are ${viewings.length} viewings`} scheduled to take place soon:`}
            pb={8}
          >
            <Spoiler maxHeight={100} showLabel="Show all viewings" hideLabel="Show less" pb="xs">
              {viewings.map((viewing) => (
                <Group key={viewing.id} gap={0} mb={4}>
                  <Text fw={600} mr={6} lh={1.4}>
                    {formatShortDate(viewing.startDate)}
                  </Text>
                  <Text mr={6} lh={1.4}>
                    from
                  </Text>
                  <Text fw={600} mr={6} lh={1.4}>
                    {formatTime(viewing.startDate)}
                  </Text>
                  <Text mr={6} lh={1.4}>
                    to
                  </Text>
                  <Text fw={600} mr={6} lh={1.4}>
                    {formatTime(viewing.endDate)}
                  </Text>
                  {viewing.agentName && (
                    <>
                      <Text mr={6} lh={1.4}>
                        with {viewing.agentName}
                      </Text>
                      <Anchor href={`tel:${viewing.agentNumber}`} underline="hover" lh={1.4}>
                        ({viewing.agentNumber})
                      </Anchor>
                    </>
                  )}
                </Group>
              ))}
            </Spoiler>
          </Alert>
        </Grid.Col>
      )}

      <Grid.Col span={{ base: 12, sm: 6 }}>
        <Paper shadow="xs" p="md" h="100%">
          <Title order={4}>Deposit Details</Title>

          <Flex direction="column" mt={8}>
            <Text lh={1.2} c="dimmed">
              Deposit Amount
            </Text>
            <Text fw={500}>{formatCurrency(tenancyDetails.depositAmount, false)}</Text>
          </Flex>

          <Flex direction="column" mt={8}>
            <Text lh={1.2} c="dimmed">
              Deposit Scheme
            </Text>
            <Text fw={500}>{tenancyDetails.depositScheme || 'Unknown'}</Text>
          </Flex>

          <Flex direction="column" mt={8}>
            <Text lh={1.2} c="dimmed">
              Registration Number
            </Text>
            <Text fw={500}>{tenancyDetails.depositRegistrationNumber || 'Unknown'}</Text>
          </Flex>
        </Paper>
      </Grid.Col>

      <Grid.Col span={{ base: 12, sm: 6 }}>
        <Paper shadow="xs" p="md" h="100%">
          <Title order={4}>Utilities</Title>

          <Flex direction="column" mt={8}>
            <Text lh={1.2} c="dimmed">
              Move in Gas Reading{' '}
              {tenancyDetails.gasProvider ? `(${tenancyDetails.gasProvider})` : ''}
            </Text>
            <Text fw={500}>{tenancyDetails.gasReadingIn || 'No Reading'}</Text>
          </Flex>

          <Flex direction="column" mt={8}>
            <Text lh={1.2} c="dimmed">
              Move in Electricity Reading{' '}
              {tenancyDetails.electricProvider ? `(${tenancyDetails.electricProvider})` : ''}
            </Text>
            <Text fw={500}>{tenancyDetails.electricReadingIn || 'No Reading'}</Text>
          </Flex>

          <Flex direction="column" mt={8}>
            <Text lh={1.2} c="dimmed">
              Move in Water Reading{' '}
              {tenancyDetails.waterProvider ? `(${tenancyDetails.waterProvider})` : ''}
            </Text>
            <Text fw={500}>{tenancyDetails.waterReadingIn || 'No Reading'}</Text>
          </Flex>

          {tenancyDetails.telecomsProvider && (
            <Flex direction="column" mt={8}>
              <Text lh={1.2} c="dimmed">
                Telecoms Provider
              </Text>
              <Text fw={500}>{tenancyDetails.telecomsProvider}</Text>
            </Flex>
          )}
        </Paper>
      </Grid.Col>

      {tenancyDetails && (
        <Grid.Col span={12}>
          <TeamDetailsCard teamId={tenancyDetails.teamId} />
        </Grid.Col>
      )}
    </Grid>
  );
}
