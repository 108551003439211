import { useState } from 'react';
import { SegmentedControl } from '@mantine/core';
import { LandlordPropertyTenancy } from '@/models/landlord/tenancy.model';
import LandlordPropertyTenancyDetails from './components/LandlordPropertyTenancyDetails';
import LandlordPropertyTenancyTerms from './components/LandlordPropertyTenancyTerms';
import LandlordPropertyTenancyInspections from './components/LandlordPropertyTenancyInspections';

interface LandlordPropertyTenancyContentProps {
  tenancy: LandlordPropertyTenancy;
  showInspections: boolean;
}

export function LandlordPropertyTenancyContent({
  tenancy,
  showInspections,
}: LandlordPropertyTenancyContentProps) {
  const [selectedTab, setSelectedTab] = useState('details');

  const tenancyTabs = [
    { value: 'details', label: 'Details' },
    { value: 'terms', label: 'Terms' },
  ];
  if (showInspections) {
    tenancyTabs.push({ value: 'inspections', label: 'Inspections' });
  }

  return (
    <>
      <SegmentedControl value={selectedTab} onChange={setSelectedTab} data={tenancyTabs} mb="lg" />
      {selectedTab === 'details' && <LandlordPropertyTenancyDetails {...tenancy} />}
      {selectedTab === 'terms' && (
        <LandlordPropertyTenancyTerms activeTermId={tenancy.activeTermId} terms={tenancy.terms} />
      )}
      {selectedTab === 'inspections' && (
        <LandlordPropertyTenancyInspections inspections={tenancy.inspections} />
      )}
    </>
  );
}
