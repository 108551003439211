import { Paper, Title, Text, Image, Group, Badge, Stack, Grid } from '@mantine/core';
import classes from './LandlordPropertyHeader.module.css';
import { formatCurrency, pluraliseWord } from '@/utils/stringFormat';
import {
  LandlordPropertySummary,
  RentFrequencyToShortName,
} from '@/models/landlord/rental-property.model';

interface LandlordPropertyHeaderProps {
  headerData: LandlordPropertySummary;
  showViewings: boolean;
  showApplications: boolean;
}

export function LandlordPropertyHeader({
  headerData,
  showViewings,
  showApplications,
}: LandlordPropertyHeaderProps) {
  return (
    <Paper shadow="xs" p="0" style={{ overflow: 'hidden' }}>
      <Grid gutter={0}>
        <Grid.Col span={{ base: 12, xs: 'content' }}>
          <div style={{ position: 'relative' }}>
            <Image
              width={236}
              height={156}
              src={`${headerData.propertyImage}?width=525`}
              alt="Property Image"
              fallbackSrc="https://placehold.co/236x156?text=No%20Image"
              className={classes.imageContainer}
            />
            <Badge radius={0} variant="filled" className={classes.statusBadge}>
              {headerData.status} - {headerData.tenancyStatus}
            </Badge>
          </div>
        </Grid.Col>

        <Grid.Col span={{ base: 12, xs: 'auto' }}>
          <Stack gap={0} p="sm">
            <Title className={classes.title}>{headerData.addressLine1}</Title>
            <Text fw={600} size="lg">
              {headerData.addressLine2}
            </Text>
            <Text size="lg">
              {formatCurrency(headerData.marketingRent)}{' '}
              {RentFrequencyToShortName[headerData.rentFrequency]}
            </Text>
            {headerData.daysOnMarket >= 0 ? (
              <Text size="sm" fs="italic">
                {headerData.daysOnMarket} {pluraliseWord('day', headerData.daysOnMarket)} on market
              </Text>
            ) : (
              <Text size="sm" fs="italic">
                Not on market yet
              </Text>
            )}
            <Group mt={4} gap={8}>
              {showViewings && (
                <Badge variant="outline" color="violet">
                  {headerData.upcomingViewings} upcoming{' '}
                  {pluraliseWord('viewing', headerData.upcomingViewings)}
                </Badge>
              )}
              {showApplications && (
                <Badge variant="outline" color="grape">
                  {headerData.newApplications} new{' '}
                  {pluraliseWord('application', headerData.newApplications)}
                </Badge>
              )}
            </Group>
          </Stack>
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
