import { Title, Text, Button, Container, Group, useMantineColorScheme } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';

export default function NotFoundPage() {
  const { colorScheme } = useMantineColorScheme();
  const auth = useAuth();

  return (
    <Container py={40}>
      <Title
        ta="center"
        fw={900}
        fz={{ base: 120, sm: 220 }}
        lh={1}
        mb="xl"
        c={colorScheme === 'dark' ? 'var(--mantine-color-dark-4)' : 'var(--mantine-color-gray-2)'}
      >
        404
      </Title>
      <Title ta="center" fw={900} fz={{ base: 32, sm: 38 }}>
        This page could not be found.
      </Title>
      <Text ta="center" c="dimmed" size="lg" maw={600} my="md" mx="auto">
        This may be because it doesn&apos;t exist or you don&apos;t have access.
      </Text>
      <Group justify="center">
        {auth.token ? (
          <Button variant="filled" size="md" to="/" component={Link}>
            Take me back to home page
          </Button>
        ) : (
          <Button variant="filled" size="md" to="/auth/login" component={Link}>
            Login
          </Button>
        )}
      </Group>
    </Container>
  );
}
