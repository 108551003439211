import { TenancyInspectionStatus } from '@/models/tenant/inspections.model';
import { RentFrequencyEnum } from '@/models/landlord/rental-property.model';
import { RentalLeaseType, TenancyDurationType } from '@/models/landlord/application.model';

export interface LandlordPropertyTenancy {
  id: number;
  renters: string;
  additionalOccupants: string;
  status: TenancyStatus;
  depositAmount: number;
  depositScheme: string;
  depositReference: string;
  inspections: LandlordTenancyInspection[];
  terms: LandlordTenancyTerm[];
  activeTermId: number;
  utilities: LandlordTenancyUtilities;
}

export interface LandlordTenancyInspection {
  id: number;
  appointmentDate: string;
  status: TenancyInspectionStatus;
  rating: number;
  comments: string;
}

export interface LandlordTenancyTerm {
  id: number;
  startDate: string;
  endDate: string;
  rent: number;
  rentFrequency: RentFrequencyEnum;
  leaseType: RentalLeaseType;
  termDurationNumber: number;
  termDurationType: TenancyDurationType;
  termDurationDays: number;
}

export interface LandlordTenancyUtilities {
  gasReadingIn: string;
  gasReadingOut: string;
  gasProvider: string;
  electricReadingIn: string;
  electricReadingOut: string;
  electricProvider: string;
  waterReadingIn: string;
  waterReadingOut: string;
  waterProvider: string;
  telecomsProvider: string;
}

export enum TenancyStatus {
  Preparing = 0,
  Active = 1,
  Finished = 2,
  FallenThrough = 3,
}
export const TenancyStatusLabels = {
  [TenancyStatus.Preparing]: 'Preparing',
  [TenancyStatus.Active]: 'Active',
  [TenancyStatus.Finished]: 'Finished',
  [TenancyStatus.FallenThrough]: 'Fallen Through',
};
