import { Container, Title } from '@mantine/core';
import { DashboardHero } from '@/components/Common/DashboardHero/DashboardHero';
import { SellingProperties } from '@/components/Selling/SellingProperties/SellingProperties';

export function SellingDashboardPage() {
  return (
    <Container p={0}>
      <DashboardHero />

      <Title mb="md" ta={{ base: 'center', sm: 'left' }}>
        Properties
      </Title>

      <SellingProperties />
    </Container>
  );
}
