import { useState, useEffect } from 'react';
import { Container, Tabs, Paper, Breadcrumbs, Anchor, Center, Loader, Title } from '@mantine/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import httpClient from '@/utils/httpClient';
import {
  LandlordPropertySettings,
  LandlordPropertySummary,
} from '@/models/landlord/rental-property.model';
import { TeamDetailsCard } from '@/components/Common/TeamDetailsCard/TeamDetailsCard';
import { LandlordPropertyViewings } from '@/components/Landlord/LandlordPropertyViewings/LandlordPropertyViewings';
import { LandlordPropertyHeader } from '@/components/Landlord/LandlordPropertyHeader/LandlordPropertyHeader';
import { LandlordPropertyMarketing } from '@/components/Landlord/LandlordPropertyMarketing/LandlordPropertyMarketing';
import { LandlordPropertyApplications } from '@/components/Landlord/LandlordPropertyApplications/LandlordPropertyApplications';
import { LandlordPropertyCertificates } from '@/components/Landlord/LandlordPropertyCertificates/LandlordPropertyCertificates';
import { LandlordPropertyTenancies } from '@/components/Landlord/LandlordPropertyTenancies/LandlordPropertyTenancies';
import { LandlordPropertyMaintenance } from '@/components/Landlord/LandlordPropertyMaintenance/LandlordPropertyMaintenance';

const breadcrumbs = [
  { title: 'Home', href: '/landlord' },
  { title: 'Property', href: '#' },
].map((item, index) => (
  <Anchor key={index} to={item.href} component={Link}>
    {item.title}
  </Anchor>
));

function usePropertySettings(propertyId: number): UseQueryResult<LandlordPropertySettings, Error> {
  return useQuery({
    queryKey: ['landlord', 'settings', propertyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/landlord/${propertyId}/settings`);
      return data;
    },
    enabled: !!propertyId,
  });
}

function useHeaderData(propertyId: number): UseQueryResult<LandlordPropertySummary, Error> {
  return useQuery({
    queryKey: ['landlord', 'header', propertyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/landlord/${propertyId}/header`);
      return data;
    },
    enabled: !!propertyId,
  });
}

export function LandlordPropertyPage() {
  const { propertyId, activeTab } = useParams();
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const navigate = useNavigate();
  const {
    data: settings,
    error: settingsError,
    isPending: isSettingsPending,
  } = usePropertySettings(Number(propertyId));
  const {
    data: headerData,
    error: headerError,
    isPending: isHeaderPending,
  } = useHeaderData(Number(propertyId));

  useEffect(() => {
    if (settings) {
      setActiveTab(activeTab || null);
    }
  }, [activeTab, settings]);

  const setActiveTab = (tab: string | null) => {
    if ((tab === 'marketing' || tab === null) && settings?.hasMarketing) {
      setSelectedTab('marketing');
    } else if ((tab === 'viewings' || tab === null) && settings?.hasViewings) {
      setSelectedTab('viewings');
    } else if ((tab === 'applications' || tab === null) && settings?.hasApplications) {
      setSelectedTab('applications');
    } else if ((tab === 'certificates' || tab === null) && settings?.hasCertificates) {
      setSelectedTab('certificates');
    } else if ((tab === 'maintenance' || tab === null) && settings?.hasMaintenanceJobs) {
      setSelectedTab('maintenance');
    } else if ((tab === 'tenancies' || tab === null) && settings?.hasTenancies) {
      setSelectedTab('tenancies');
    } else {
      setSelectedTab('contact');
    }
  };

  const changeTab = (value: string | null) => {
    navigate(`/landlord/property/${propertyId}/${value}`);
  };

  if (settingsError || headerError) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {settingsError?.message || headerError?.message}
      </Title>
    );
  }

  if (!settings || isSettingsPending || isHeaderPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Container p={0}>
      <Breadcrumbs mb="md">{breadcrumbs}</Breadcrumbs>

      <LandlordPropertyHeader
        headerData={headerData}
        showViewings={settings.hasViewings}
        showApplications={settings.hasApplications}
      />

      <Tabs value={selectedTab} onChange={(value) => changeTab(value)}>
        <Paper shadow="xs" mt="lg">
          <Tabs.List>
            {settings.hasMarketing && <Tabs.Tab value="marketing">Marketing</Tabs.Tab>}
            {settings.hasViewings && <Tabs.Tab value="viewings">Viewings</Tabs.Tab>}
            {settings.hasApplications && <Tabs.Tab value="applications">Applications</Tabs.Tab>}
            {settings.hasCertificates && <Tabs.Tab value="certificates">Certificates</Tabs.Tab>}
            {settings.hasMaintenanceJobs && <Tabs.Tab value="maintenance">Maintenance</Tabs.Tab>}
            {settings.hasTenancies && <Tabs.Tab value="tenancies">Tenancies</Tabs.Tab>}
            <Tabs.Tab value="contact">Contact</Tabs.Tab>
          </Tabs.List>
        </Paper>

        {settings.hasMarketing && (
          <Tabs.Panel value="marketing" pt="xs">
            <LandlordPropertyMarketing propertyId={Number(propertyId)} teamId={headerData.teamId} />
          </Tabs.Panel>
        )}

        {settings.hasViewings && (
          <Tabs.Panel value="viewings" pt="xs">
            <LandlordPropertyViewings
              propertyId={Number(propertyId)}
              showFeedback={settings.hasViewingFeedback}
            />
          </Tabs.Panel>
        )}

        {settings.hasApplications && (
          <Tabs.Panel value="applications" pt="xs">
            <LandlordPropertyApplications propertyId={Number(propertyId)} />
          </Tabs.Panel>
        )}

        {settings.hasCertificates && (
          <Tabs.Panel value="certificates" pt="xs">
            <LandlordPropertyCertificates propertyId={Number(propertyId)} />
          </Tabs.Panel>
        )}

        {settings.hasMaintenanceJobs && (
          <Tabs.Panel value="maintenance" pt="xs">
            <LandlordPropertyMaintenance propertyId={Number(propertyId)} />
          </Tabs.Panel>
        )}

        {settings.hasTenancies && (
          <Tabs.Panel value="tenancies" pt="xs">
            <LandlordPropertyTenancies
              propertyId={Number(propertyId)}
              showInspections={settings.hasTenancyInspections}
            />
          </Tabs.Panel>
        )}

        <Tabs.Panel value="contact" pt="xs">
          <TeamDetailsCard teamId={headerData.teamId} my="sm" />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
}
