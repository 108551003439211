import {
  Group,
  UnstyledButton,
  Text,
  Image,
  Divider,
  Container,
  Menu,
  Burger,
  Drawer,
  Box,
  Stack,
  Button,
  useMantineColorScheme,
} from '@mantine/core';
import {
  IconHomeDollar,
  IconChevronDown,
  IconLogout,
  IconHomeLink,
  IconCurrencyPound,
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link, NavLink as RouterNavLink, useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import classes from './Header.module.css';
import LogoImg from '@/public/propertyhub_logo.png';
import LogoDarkImg from '@/public/propertyhub_logo_dark.png';
import { ColorSchemeToggle } from '@/components/Common/ColorSchemeToggle/ColorSchemeToggle';
import { UserAccess } from '@/models/auth/auth.model';
import httpClient from '@/utils/httpClient';
import { useAuth } from '@/hooks/useAuth';

interface NavLinkItem {
  link: string;
  label: string;
  icon: any;
}

function useUserAccess(): UseQueryResult<UserAccess, Error> {
  return useQuery({
    queryKey: ['userAccess'],
    queryFn: async () => {
      const { data } = await httpClient.get('/users/access');
      return data;
    },
  });
}

export function Header() {
  const { data, error } = useUserAccess();
  const [navLinks, setNavLinks] = useState<NavLinkItem[]>([]);
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { colorScheme } = useMantineColorScheme();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (error) {
      onLogout();
    }

    const links = [];
    if (data?.isBuyer) {
      links.push({
        link: '/buyer',
        label: 'Buyer',
        icon: IconHomeDollar,
      });
    }
    if (data?.sellingProperties && data?.sellingProperties.length > 0) {
      links.push({
        link: '/selling',
        label: 'Selling',
        icon: IconCurrencyPound,
      });
    }
    if (data?.isRenter) {
      links.push({
        link: '/renter',
        label: 'Renter',
        icon: IconHomeDollar,
      });
    }
    if (data?.landlordProperties && data?.landlordProperties.length > 0) {
      links.push({
        link: '/landlord',
        label: 'Landlord',
        icon: IconHomeDollar,
      });
    }
    if (data?.tenancies && data?.tenancies.length > 0) {
      links.push({
        link: data?.tenancies.length > 1 ? '/tenant' : `/tenant/tenancy/${data?.tenancies[0]}`,
        label: 'Tenant',
        icon: IconHomeLink,
      });
    }
    setNavLinks(links);
  }, [data, error]);

  const onLogout = () => {
    auth.logOut();
    queryClient.clear();
    navigate('/auth/login');
  };

  const links = navLinks.map((item) => (
    <UnstyledButton className={classes.link} key={item.link}>
      <RouterNavLink to={item.link} className={({ isActive }) => (isActive ? 'active' : '')}>
        <Group gap={4}>
          <Box visibleFrom="md">
            <item.icon size="1.4rem" stroke={1.5} />
          </Box>
          {item.label}
        </Group>
      </RouterNavLink>
    </UnstyledButton>
  ));

  const drawerLinks = navLinks.map((item) => (
    <UnstyledButton className={classes.drawerLink} key={item.link} onClick={closeDrawer}>
      <RouterNavLink
        key={item.label}
        to={item.link}
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        <Group gap="xs">
          <item.icon />
          {item.label}
        </Group>
      </RouterNavLink>
    </UnstyledButton>
  ));

  return (
    <Container h="100%">
      <Group h="100%">
        <Group justify="space-between" style={{ flex: 1 }}>
          <Link to="/">
            <Image h={40} w={149} src={colorScheme === 'dark' ? LogoDarkImg : LogoImg} alt="Logo" />
          </Link>

          <Group ml="xl" gap={0} visibleFrom="sm">
            {links}
          </Group>

          <Box visibleFrom="sm">
            <Menu
              width={260}
              position="bottom-end"
              transitionProps={{ transition: 'pop-top-right' }}
              onClose={() => setUserMenuOpened(false)}
              onOpen={() => setUserMenuOpened(true)}
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton
                  className={clsx(classes.user, { [classes.userActive]: userMenuOpened })}
                >
                  <Group gap={7}>
                    <Text
                      fw={500}
                      size="sm"
                      maw={180}
                      style={{
                        lineHeight: '1rem',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {`${auth.user?.firstName} ${auth.user?.lastName}`}
                    </Text>
                    <IconChevronDown stroke={1.2} size="1.2rem" />
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={() => onLogout()}>
                  <Group>
                    <IconLogout stroke={1.5} />
                    Logout
                  </Group>
                </Menu.Item>
                <Divider my="sm" />
                <ColorSchemeToggle />
              </Menu.Dropdown>
            </Menu>
          </Box>
        </Group>

        <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
      </Group>

      <Drawer
        opened={drawerOpened}
        size={300}
        onClose={closeDrawer}
        position="right"
        title={`${auth.user?.firstName} ${auth.user?.lastName}`}
        hiddenFrom="sm"
      >
        <Box mx="-md">
          <Divider mb="xs" w="100%" />

          <Stack gap={0} my={0}>
            {drawerLinks}
          </Stack>

          <Divider my="xs" w="100%" />

          <ColorSchemeToggle />

          <Divider my="xs" w="100%" />

          <Box mx="sm" my="lg">
            <Button rightSection={<IconLogout />} fullWidth onClick={() => onLogout()}>
              Logout
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Container>
  );
}
