import { useState } from 'react';
import {
  Paper,
  Accordion,
  Text,
  Group,
  RingProgress,
  Center,
  Title,
  Timeline,
  SegmentedControl,
  Table,
  Anchor,
  Stack,
  Loader,
} from '@mantine/core';
import { IconCircleCheckFilled } from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { PropertySaleChain, SalesMilestoneTypes, SalesNote } from '@/models/selling/property.model';
import { formatDate, formatDateTime, timeFromNow } from '../../../utils/stringFormat';
import httpClient from '@/utils/httpClient';
import classes from './SellingPropertySales.module.css';

interface PropertySalesProps {
  propertyId: number;
  showNotes: boolean;
  showDocuments: boolean;
}

interface AccordionLabelProps extends PropertySaleChain {
  label: string;
  personName: string;
}

function AccordionLabel({
  label,
  personName,
  lastUpdateDate,
  completionPercentage,
}: AccordionLabelProps) {
  const isMobile = useMediaQuery('(max-width: 800px)');

  return (
    <Group wrap="nowrap">
      <Stack gap={0}>
        <RingProgress
          size={isMobile ? 50 : 125}
          thickness={15}
          sections={[{ value: completionPercentage, color: 'teal' }]}
          label={
            isMobile ? undefined : (
              <Text c="teal" fw={700} ta="center" size="xl">
                {completionPercentage}%
              </Text>
            )
          }
        />

        {isMobile && (
          <Center>
            <Text c="teal" fw={700} size="lg">
              {completionPercentage}%
            </Text>
          </Center>
        )}
      </Stack>

      <div>
        <Text size="lg" fw={600}>
          {label}
        </Text>
        <Text size="sm" c="dimmed" fw={400}>
          {personName}
        </Text>
        {lastUpdateDate && (
          <Text size="sm" c="dimmed" fw={400}>
            Last Updated: {formatDateTime(lastUpdateDate)}
          </Text>
        )}
      </div>
    </Group>
  );
}

interface Milestone {
  label: string;
  date: string;
  isActive: boolean;
  isLineActive: boolean;
}

function Milestones(chain: PropertySaleChain) {
  const milestones: Milestone[] = [
    {
      label: 'Mortgage Valuation',
      date: chain.mortgageValuationDate,
      isActive: !!chain.mortgageValuationDate,
      isLineActive: !!chain.mortgageValuationDate && !!chain.surveyDate,
    },
    {
      label: 'Survey',
      date: chain.surveyDate,
      isActive: !!chain.surveyDate,
      isLineActive: !!chain.surveyDate && !!chain.draftContractIssuedDate,
    },
    {
      label: 'Draft Contract Issued',
      date: chain.draftContractIssuedDate,
      isActive: !!chain.draftContractIssuedDate,
      isLineActive: !!chain.draftContractIssuedDate && !!chain.searchesRequestedDate,
    },
    {
      label: 'Searches Requested',
      date: chain.searchesRequestedDate,
      isActive: !!chain.searchesRequestedDate,
      isLineActive: !!chain.searchesRequestedDate && !!chain.searchedReceivedDate,
    },
    {
      label: 'Searches Received',
      date: chain.searchedReceivedDate,
      isActive: !!chain.searchedReceivedDate,
      isLineActive: !!chain.searchedReceivedDate && !!chain.mortgageOfferReceivedDate,
    },
    {
      label: 'Mortgage Offer Received',
      date: chain.mortgageOfferReceivedDate,
      isActive: !!chain.mortgageOfferReceivedDate,
      isLineActive: !!chain.mortgageOfferReceivedDate && !!chain.searchesRequestedDate,
    },
    {
      label: 'Seller Signed Contract',
      date: chain.sellerSignedContractDate,
      isActive: !!chain.sellerSignedContractDate,
      isLineActive: !!chain.sellerSignedContractDate && !!chain.buyerSignedContractDate,
    },
    {
      label: 'Buyer Signed Contract',
      date: chain.buyerSignedContractDate,
      isActive: !!chain.buyerSignedContractDate,
      isLineActive: !!chain.buyerSignedContractDate && !!chain.exchangedDate,
    },
    {
      label: 'Exchanged',
      date: chain.exchangedDate,
      isActive: !!chain.exchangedDate,
      isLineActive: !!chain.exchangedDate && !!chain.completedDate,
    },
    {
      label: 'Completed',
      date: chain.completedDate,
      isActive: !!chain.completedDate,
      isLineActive: !!chain.completedDate,
    },
  ];

  return (
    <Timeline
      color="teal"
      bulletSize={28}
      lineWidth={3}
      styles={{ itemTitle: { lineHeight: '26px' } }}
    >
      {milestones.map((milestone: Milestone) => (
        <Timeline.Item
          key={milestone.label}
          title={milestone.label}
          bullet={milestone.isActive ? <IconCircleCheckFilled size={26} /> : null}
          className={milestone.isActive ? classes.activeBullet : ''}
          mt="lg"
        >
          {milestone.date && (
            <Text size="sm" mt={-4}>
              Milestone completed {timeFromNow(milestone.date)} ({formatDate(milestone.date)})
            </Text>
          )}
        </Timeline.Item>
      ))}
    </Timeline>
  );
}

function Notes({ notes }: { notes: SalesNote[] }) {
  return (
    <Timeline active={-1} lineWidth={2} bulletSize={16}>
      {notes.map((note: SalesNote, index) => (
        <Timeline.Item key={index} title={note.noteText}>
          <Text c="dimmed" size="sm">
            By {note.creatingAgentName}
            {note.type > 0 ? ` in relation to ${SalesMilestoneTypes[note.type]}` : ''}
          </Text>
          <Text size="xs" mt={4}>
            {timeFromNow(note.dateCreated)} ({formatDate(note.dateCreated)})
          </Text>
        </Timeline.Item>
      ))}
    </Timeline>
  );
}

function Documents({ documents }: { documents: any[] }) {
  return (
    <Table withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Name</Table.Th>
          <Table.Th>Uploaded</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {documents.map((row: any) => (
          <Table.Tr key={row.id}>
            <Table.Td>
              <Anchor href={row.filePath} target="_blank">
                {row.fileName}
              </Anchor>
            </Table.Td>
            <Table.Td>{formatDate(row.generatedDate, 'DD/MM/YYYY')}</Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
}

function useSalesData(propertyId: number) {
  return useQuery({
    queryKey: ['selling', 'sales', propertyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/properties/${propertyId}/sales-progression`);
      return data;
    },
    enabled: !!propertyId,
  });
}

function useSalesDocumentsData(propertyId: number) {
  return useQuery({
    queryKey: ['selling', 'salesDocuments', propertyId],
    queryFn: async () => {
      const { data } = await httpClient.get(
        `/properties/${propertyId}/sales-progression/documents`
      );
      return data;
    },
    enabled: !!propertyId,
  });
}

export function SellingPropertySales({ propertyId, showNotes, showDocuments }: PropertySalesProps) {
  const [sellerTab, setSellerTab] = useState('milestones');
  const [buyerTab, setBuyerTab] = useState('milestones');
  const { data, error, isPending } = useSalesData(propertyId);
  const { data: documents } = showDocuments ? useSalesDocumentsData(propertyId) : { data: [] };

  if (isPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }

  const sellerTabs = [{ label: 'Milestones', value: 'milestones' }];

  const buyerTabs = [{ label: 'Milestones', value: 'milestones' }];

  if (showNotes) {
    sellerTabs.push({ label: 'Notes', value: 'notes' });
    buyerTabs.push({ label: 'Notes', value: 'notes' });
  }

  if (showDocuments) {
    sellerTabs.push({ label: 'Documents', value: 'documents' });
  }

  return (
    <>
      {data && data.buyerName ? (
        <Paper shadow="xs" my="sm">
          {!data.sellerSalesProgression && !data.buyerSalesProgression && (
            <Title order={4} p="sm">
              We are preparing your sale to {data.buyerName}
            </Title>
          )}

          <Accordion chevronPosition="right">
            {data.sellerSalesProgression && (
              <Accordion.Item value="seller">
                <Accordion.Control>
                  <AccordionLabel
                    {...{
                      label: 'Your Sales Progression',
                      personName: data.sellerSalesProgression.sellerName,
                      ...data.sellerSalesProgression,
                    }}
                  />
                </Accordion.Control>
                <Accordion.Panel>
                  {sellerTabs.length > 1 && (
                    <SegmentedControl
                      value={sellerTab}
                      onChange={setSellerTab}
                      mb="lg"
                      data={sellerTabs}
                    />
                  )}
                  {sellerTab === 'milestones' && <Milestones {...data.sellerSalesProgression} />}
                  {sellerTab === 'notes' && showNotes && (
                    <Notes notes={data.sellerSalesProgression.notes} />
                  )}
                  {sellerTab === 'documents' && showDocuments && (
                    <Documents documents={documents} />
                  )}
                </Accordion.Panel>
              </Accordion.Item>
            )}

            {data.buyerSalesProgression && (
              <Accordion.Item value="buyer">
                <Accordion.Control>
                  <AccordionLabel
                    {...{
                      label: "Buyer's Sale Progression",
                      personName: data.buyerName,
                      ...data.buyerSalesProgression,
                    }}
                  />
                </Accordion.Control>
                <Accordion.Panel>
                  {buyerTabs.length > 1 && (
                    <SegmentedControl
                      value={buyerTab}
                      onChange={setBuyerTab}
                      mb="lg"
                      data={buyerTabs}
                    />
                  )}
                  {buyerTab === 'milestones' && <Milestones {...data.buyerSalesProgression} />}
                  {buyerTab === 'notes' && showNotes && (
                    <Notes notes={data.buyerSalesProgression.notes} />
                  )}
                </Accordion.Panel>
              </Accordion.Item>
            )}
          </Accordion>
        </Paper>
      ) : (
        <Title order={4} my="sm">
          No Sale found for this property
        </Title>
      )}
    </>
  );
}
