import { useRef, useState } from 'react';
import {
  Modal,
  Title,
  Stepper,
  Button,
  Group,
  Text,
  Container,
  TextInput,
  Checkbox,
  Textarea,
  Stack,
  Image,
  SimpleGrid,
  LoadingOverlay,
  Badge,
  Alert,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { IconInfoCircle } from '@tabler/icons-react';
import Uppy from '@uppy/core';
import Compressor from '@uppy/compressor';
import {
  MaintenanceCategory,
  MaintenanceCategoryData,
  MaintenanceJob,
  MaintenanceSubTypeData,
} from '@/models/tenant/maintenance.model';
import { MaintenanceCategorySelector } from '@/components/Tenant/TenancyMaintenance/RaiseMaintenanceIssueModal/MaintenanceCategorySelector';
import { MaintenanceImageUploader } from '@/components/Tenant/TenancyMaintenance/RaiseMaintenanceIssueModal/MaintenanceImageUploader';
import httpClient from '@/utils/httpClient';
import successSvg from '@/public/success.svg';
import classes from './RaiseMaintenanceIssueModal.module.css';

const UppyConfig = {
  autoProceed: true,
  restrictions: {
    allowedFileTypes: ['image/png', 'image/jpeg', 'image/jpg'],
    maxNumberOfFiles: 10,
    maxFileSize: 20 * 1024 ** 2, // 20MB
  },
};

interface RaiseMaintenanceIssueModalProps {
  tenancyId: number;
  opened: boolean;
  close: any;
}

export function RaiseMaintenanceIssueModal({
  tenancyId,
  opened,
  close,
}: RaiseMaintenanceIssueModalProps) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<MaintenanceCategoryData | null>(null);
  const [uppy] = useState(() => new Uppy(UppyConfig).use(Compressor, { quality: 0.8 }));
  const nextStep = () =>
    setActiveStep((current) => {
      if (current === 1 && !form.isValid()) {
        form.validate();
        return current;
      }
      return current < 4 ? current + 1 : current;
    });
  const prevStep = () => setActiveStep((current) => (current > 0 ? current - 1 : current));
  const summaryRef = useRef<HTMLInputElement>(null);
  const form = useForm({
    initialValues: {
      summary: '',
      emergency: false,
      details: '',
    },

    validate: {
      summary: (value) => (value ? null : 'Please enter a summary of the issue'),
    },
  });

  const handleSubTypeSelect = (subType: MaintenanceSubTypeData) => {
    let issueSummary = '';
    if (selectedCategory?.category === MaintenanceCategory.Other) {
      if (subType.label !== 'Other') {
        issueSummary = `${subType.label}`;
      }
    } else if (subType.label === 'Other') {
      issueSummary = `${selectedCategory?.label} - `;
    } else {
      issueSummary = `${selectedCategory?.label} - ${subType.label}`;
    }

    form.setFieldValue('summary', issueSummary);
    form.setFieldValue('emergency', subType.label.includes('emergency'));
    nextStep();
    setTimeout(() => summaryRef?.current?.focus(), 100);
  };

  const newIssueMutation = useMutation({
    mutationFn: (formData: any) => httpClient.post(`tenancies/${tenancyId}/maintenance`, formData),
    onSuccess: (response) => {
      setErrorMessage(null);
      queryClient.setQueryData(
        ['tenancy', 'maintenance', tenancyId],
        (oldData: MaintenanceJob[]) => (oldData ? [response.data, ...oldData] : oldData)
      );
      nextStep();
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
    onSettled: () => {
      setLoading(false);
    },
  });
  const onSubmitIssue = () => {
    setLoading(true);
    const data = new FormData();
    data.append('summary', form.values.summary);
    data.append('emergency', form.values.emergency.toString());
    data.append('details', form.values.details);
    const files = uppy.getFiles().map((file) => file.data);
    files.forEach((file) => data.append('files', file));
    newIssueMutation.mutate(data);
  };

  const resetFormAndClose = () => {
    close();
    setTimeout(() => resetForm(), 300);
  };

  const resetForm = () => {
    setSelectedCategory(null);
    form.reset();
    uppy.cancelAll();
    setActiveStep(0);
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        closeOnClickOutside={false}
        title="Raise a new Maintenance Issue"
        size="42rem"
        centered
      >
        <Stepper
          size="sm"
          active={activeStep}
          onStepClick={setActiveStep}
          allowNextStepsSelect={false}
          classNames={{
            steps: classes.stepperHeader,
            content: classes.stepperContent,
          }}
        >
          <Stepper.Step label="Category" description="Step 1">
            <MaintenanceCategorySelector
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              handleSubTypeSelect={handleSubTypeSelect}
            />
          </Stepper.Step>

          <Stepper.Step color={form.isValid() ? '' : 'red'} label="Details" description="Step 2">
            <Container>
              <Title order={4} mb="xs">
                Please provider further details on the issue.
              </Title>

              <form>
                <Stack>
                  <TextInput
                    ref={summaryRef}
                    withAsterisk
                    label="Summary of the issue"
                    maxLength={100}
                    {...form.getInputProps('summary')}
                  />

                  <Checkbox
                    label="This issue is an Emergency"
                    color="red"
                    {...form.getInputProps('emergency', { type: 'checkbox' })}
                  />

                  <Textarea
                    label="Issue Details"
                    description="Please include as much detail as possible to help us understand the issue."
                    autosize
                    minRows={3}
                    maxRows={6}
                    maxLength={4000}
                    {...form.getInputProps('details')}
                  />
                </Stack>
              </form>
            </Container>
          </Stepper.Step>

          <Stepper.Step label="Photos" description="Step 3">
            <MaintenanceImageUploader uppy={uppy} />
          </Stepper.Step>

          <Stepper.Step label="Summary" description="Step 4">
            <Container>
              <Stack gap="md">
                {errorMessage && (
                  <Alert
                    variant="light"
                    color="red"
                    title={errorMessage}
                    icon={<IconInfoCircle />}
                  />
                )}
                <Alert p="xs">
                  <Text size="sm">
                    Please confirm the details below are correct before submitting.
                  </Text>
                </Alert>
                <Stack gap={0}>
                  <Title order={4}>Issue Summary</Title>
                  <Text>{form.values.summary}</Text>
                  {form.values.emergency && (
                    <Badge color="red" size="lg" mt="xs">
                      This issue is an emergency
                    </Badge>
                  )}
                </Stack>
                <Stack gap={0}>
                  <Title order={4}>Issue Details</Title>
                  {form.values.details ? (
                    <Text>{form.values.details}</Text>
                  ) : (
                    <Text c="dimmed" fs="italic">
                      No details provided
                    </Text>
                  )}
                </Stack>
                <Stack gap={0}>
                  <Title order={4}>Photos</Title>
                  {uppy.getFiles().length === 0 ? (
                    <Text c="dimmed" fs="italic">
                      No photos provided
                    </Text>
                  ) : (
                    <SimpleGrid cols={{ base: 2, xs: 2, sm: 3, md: 4 }} mt="xs">
                      {uppy.getFiles().map((file, index) => (
                        <Image key={index} src={file.preview} />
                      ))}
                    </SimpleGrid>
                  )}
                </Stack>
              </Stack>
            </Container>
          </Stepper.Step>

          <Stepper.Completed>
            <Container>
              <Stack align="center" my="sm">
                <Image src={successSvg} w={140} h={140}></Image>
                <Title order={2}>Thank You!</Title>
                <Text>Your issue has been submitted.</Text>
                <Button
                  color="green"
                  variant="filled"
                  size="md"
                  onClick={resetFormAndClose}
                  mt="sm"
                  w={200}
                >
                  Close
                </Button>
              </Stack>
            </Container>
          </Stepper.Completed>
        </Stepper>

        {activeStep > 0 && activeStep < 4 && (
          <Group justify="end" mt="xl">
            <Button variant="default" onClick={prevStep} disabled={activeStep === 0}>
              Back
            </Button>
            {activeStep === 3 ? (
              <Button onClick={onSubmitIssue}>Submit</Button>
            ) : (
              <Button onClick={nextStep}>Next</Button>
            )}
          </Group>
        )}

        <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }} />
      </Modal>
    </>
  );
}
