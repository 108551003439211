import {
  Container,
  Card,
  Text,
  SimpleGrid,
  UnstyledButton,
  Group,
  Title,
  Skeleton,
} from '@mantine/core';
import {
  IconCircleLetterS,
  IconCircleLetterL,
  IconCircleLetterT,
  IconCircleLetterR,
  IconCircleLetterB,
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import classes from './Home.module.css';
import httpClient from '@/utils/httpClient';
import { UserAccess } from '@/models/auth/auth.model';

interface ModuleItem {
  title: string;
  icon: any;
  link: string;
}

function useUserAccess(): UseQueryResult<UserAccess, Error> {
  return useQuery({
    queryKey: ['userAccess'],
    queryFn: async () => {
      const { data } = await httpClient.get('/users/access');
      return data;
    },
  });
}

export function HomePage() {
  const { data, isPending } = useUserAccess();
  const [modules, setModules] = useState<ModuleItem[]>([]);

  useEffect(() => {
    const items = [];
    if (data?.isBuyer) {
      items.push({
        title: 'Buyer',
        icon: <IconCircleLetterB color="var(--mantine-color-violet-6)" size="3rem" />,
        link: '/buyer',
      });
    }
    if (data?.sellingProperties && data?.sellingProperties.length > 0) {
      items.push({
        title: 'Selling',
        icon: <IconCircleLetterS color="var(--mantine-color-violet-6)" size="3rem" />,
        link: '/selling',
      });
    }
    if (data?.isRenter) {
      items.push({
        title: 'Renter',
        icon: <IconCircleLetterR color="var(--mantine-color-red-6)" size="3rem" />,
        link: '/renter',
      });
    }
    if (data?.landlordProperties && data?.landlordProperties.length > 0) {
      items.push({
        title: 'Landlord',
        icon: <IconCircleLetterL color="var(--mantine-color-green-6)" size="3rem" />,
        link: '/landlord',
      });
    }
    if (data?.tenancies && data?.tenancies.length > 0) {
      items.push({
        title: 'Tenant',
        icon: <IconCircleLetterT color="var(--mantine-color-cyan-6)" size="3rem" />,
        link: data?.tenancies.length > 1 ? '/tenant' : `/tenant/tenancy/${data?.tenancies[0]}`,
      });
    }
    setModules(items);
  }, [data]);

  const items = modules.map((module) => (
    <UnstyledButton key={module.title} to={module.link} component={Link} className={classes.item}>
      {module.icon}
      <Text size="lg" mt={7} fw={600}>
        {module.title}
      </Text>
    </UnstyledButton>
  ));

  return (
    <Container p={0}>
      <Title fz={{ base: 40, md: 60 }} fw={900} className={classes.title} ta="center" mt={60}>
        Welcome to{' '}
        <Text inherit variant="gradient" component="span">
          PropertyHub
        </Text>
      </Title>

      <Card withBorder radius="md" mt={40} className={classes.card} p="lg">
        <Group justify="space-between" mb="md">
          <Text fw={700}>Services</Text>
        </Group>

        <SimpleGrid cols={{ base: 1, sm: 3, md: 4 }} spacing={{ base: 'sm', md: 'md' }}>
          {isPending ? <Skeleton h={120}></Skeleton> : items}
        </SimpleGrid>
      </Card>
    </Container>
  );
}
