export interface TeamData {
  teamLogo: string;
  teamName: string;
  teamAddress: string;
  teamPhoneNumber: string;
  teamEmail: string;
  teamWebsite: string;
}

export enum ViewingOpinion {
  Unknown = 0,
  Interested = 1,
  NotInterested = 2,
}
