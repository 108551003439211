import {
  Anchor,
  Badge,
  Box,
  Button,
  Center,
  Group,
  Loader,
  Menu,
  Paper,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowRight, IconFile } from '@tabler/icons-react';
import { LandlordCertificate } from '@/models/landlord/certificates.model';
import httpClient from '@/utils/httpClient';
import { formatDate } from '@/utils/stringFormat';
import classes from './LandlordPropertyCertificates.module.css';

function useCertificatesData(propertyId: number): UseQueryResult<LandlordCertificate[], Error> {
  return useQuery({
    queryKey: ['landlord', 'certificates', propertyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/landlord/${propertyId}/certificates`);
      return data;
    },
    enabled: !!propertyId,
  });
}

interface LandlordPropertyCertificatesProps {
  propertyId: number;
}

export function LandlordPropertyCertificates({ propertyId }: LandlordPropertyCertificatesProps) {
  const { data, error, isPending } = useCertificatesData(propertyId);
  const isMobile = useMediaQuery('(max-width: 800px)');

  if (isPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Title order={4} my="sm">
        There are no certificates on this property yet
      </Title>
    );
  }

  const viewFileButton = (fileUrls: string[]) => {
    if (fileUrls && fileUrls.length > 1) {
      return (
        <Menu trigger="hover" shadow="md" width={120} position="right-start">
          <Menu.Target>
            <Button variant="light" rightSection={<IconArrowRight size="1rem" />}>
              View
            </Button>
          </Menu.Target>

          <Menu.Dropdown p={0}>
            {fileUrls.map((file, index) => (
              <Anchor key={index} href={file} target="_blank" underline="never">
                <Menu.Item leftSection={<IconFile />}>File {index + 1}</Menu.Item>
              </Anchor>
            ))}
          </Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <Anchor href={fileUrls[0]} target="_blank" underline="never">
        <Button variant="light" rightSection={<IconArrowRight size="1rem" />}>
          View
        </Button>
      </Anchor>
    );
  };

  if (isMobile) {
    return (
      <Stack mt="xs">
        <Title order={3}>Certificates</Title>

        <Center>
          <Stack w="100%">
            {data.map((certificate, index) => (
              <Paper
                key={index}
                shadow="xs"
                p="md"
                className={certificate.isRequired && !certificate.exists ? classes.missingCert : ''}
              >
                <Stack gap={4}>
                  {certificate.isRequired && !certificate.exists ? (
                    <Badge color="red" mb={6}>
                      Missing
                    </Badge>
                  ) : (
                    <Badge color="teal" mb={6}>
                      Valid
                    </Badge>
                  )}
                  <Title order={4}>{certificate.certificateTypeName}</Title>
                  {certificate.expiryDate && <Text>Expires: {certificate.expiryDate}</Text>}
                  {certificate.dateJobBooked && (
                    <Text>
                      {certificate.isRequired && !certificate.exists ? 'Scheduled' : 'Renewal'}:{' '}
                      {formatDate(certificate.dateJobBooked)}
                    </Text>
                  )}
                  {certificate.expiryDate && (
                    <Group wrap="wrap" gap={6}>
                      <Text fw="bold">Expiry Date:</Text>
                      <Text>{formatDate(certificate.expiryDate)}</Text>
                    </Group>
                  )}
                  {certificate.files?.length > 0 && (
                    <Box mt={6}>{viewFileButton(certificate.files)}</Box>
                  )}
                </Stack>
              </Paper>
            ))}
          </Stack>
        </Center>
      </Stack>
    );
  }

  return (
    <Paper shadow="xs" my="sm">
      <Title order={3} mb="xs" px="md" pt="md">
        Certificates
      </Title>

      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th></Table.Th>
            <Table.Th>Certificate</Table.Th>
            <Table.Th>Expiry Date</Table.Th>
            <Table.Th>Scheduled Date</Table.Th>
            <Table.Th>Files</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data.map((row, index) => (
            <Table.Tr
              key={index}
              className={row.isRequired && !row.exists ? classes.missingCert : ''}
            >
              <Table.Td width={92}>
                {row.isRequired && !row.exists ? (
                  <Badge color="red">Missing</Badge>
                ) : (
                  <Badge color="teal">Valid</Badge>
                )}
              </Table.Td>
              <Table.Td>{row.certificateTypeName}</Table.Td>
              <Table.Td>{row.expiryDate && formatDate(row.expiryDate, 'DD/MM/YYYY')}</Table.Td>
              <Table.Td>
                {row.dateJobBooked && formatDate(row.dateJobBooked, 'DD/MM/YYYY')}
              </Table.Td>
              <Table.Td>{row.files?.length > 0 ? viewFileButton(row.files) : 'No files'}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Paper>
  );
}
