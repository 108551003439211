import { Text, Stack, Badge, Divider, Group, Title } from '@mantine/core';
import { Viewing } from '@/models/selling/viewings.model';
import { formatDate } from '@/utils/stringFormat';
import { ViewingOpinion } from '@/models/common.model';

interface PropertyViewingCardProps {
  viewing: Viewing;
  showFeedback: boolean;
  isListMode?: boolean;
}

export function SellingPropertyViewingCard({
  viewing,
  showFeedback,
  isListMode,
}: PropertyViewingCardProps) {
  return (
    <Stack gap={0} align="center">
      {!isListMode && (
        <Badge variant="outline" size="lg" mb={8}>
          {viewing.status}
        </Badge>
      )}

      <Title order={3} fz={{ base: 18, md: 22 }}>
        {formatDate(viewing.dateOfAppointment, 'ddd Do MMMM YYYY')}
      </Title>
      <Title order={2} fz={{ base: 20, md: 26 }}>
        {formatDate(viewing.dateOfAppointment, 'h:mma')}
      </Title>
      {(isListMode || viewing.viewingType !== 'Unaccompanied') && (
        <Divider style={{ width: '100%' }} my={8} />
      )}
      {isListMode && (
        <Group gap={4}>
          <Text fw={600} c="dimmed">
            Buyer:{' '}
          </Text>
          <Text c="dimmed">{viewing.buyerName}</Text>
        </Group>
      )}
      {viewing.viewingType !== 'Unaccompanied' && (
        <Group gap={4}>
          <Text fw={600} c="dimmed">
            Viewing Agent:{' '}
          </Text>
          <Text c="dimmed">{viewing.attendingAgentName}</Text>
        </Group>
      )}

      {viewing.status === 'Unconfirmed' && viewing.viewingType === 'Accompanied' && (
        <Group mt={8} gap="xs" justify="center">
          {viewing.buyerConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Buyer Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Buyer Unconfirmed
            </Badge>
          )}
          {viewing.sellerConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Seller Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Seller Unconfirmed
            </Badge>
          )}
          {viewing.agentConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Agent Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Agent Unconfirmed
            </Badge>
          )}
        </Group>
      )}
      {viewing.status === 'Unconfirmed' && viewing.viewingType === 'Unaccompanied' && (
        <Group mt={8} gap={4} justify="center">
          {viewing.buyerConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Buyer Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Buyer Unconfirmed
            </Badge>
          )}
          {viewing.sellerConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Seller Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Seller Unconfirmed
            </Badge>
          )}
        </Group>
      )}
      {viewing.status === 'Unconfirmed' && viewing.viewingType === 'Virtual' && (
        <Group mt={8} gap={4} justify="center">
          {viewing.buyerConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Buyer Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Buyer Unconfirmed
            </Badge>
          )}
          {viewing.agentConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Agent Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Agent Unconfirmed
            </Badge>
          )}
        </Group>
      )}

      {isListMode && (
        <>
          {viewing.status === 'Past' && (
            <Group mt={8} gap="xs">
              {viewing.didntTurnUp && (
                <Badge variant="light" color="red">
                  Didn&apos;t turn up
                </Badge>
              )}
              {viewing.buyerOpinion === ViewingOpinion.Interested && (
                <Badge variant="light" color="blue">
                  Interested
                </Badge>
              )}
              {viewing.buyerOpinion === ViewingOpinion.NotInterested && (
                <Badge variant="light" color="red">
                  Not Interested
                </Badge>
              )}
              {showFeedback && (
                <>
                  {viewing.buyerFeedback ? (
                    <Badge variant="light" color="teal">
                      Buyer Feedback
                    </Badge>
                  ) : (
                    <Badge color="gray" variant="outline">
                      No Feedback
                    </Badge>
                  )}
                </>
              )}
            </Group>
          )}
        </>
      )}
    </Stack>
  );
}
