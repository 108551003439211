import {
  Text,
  Stack,
  Title,
  Loader,
  Center,
  Button,
  rem,
  Paper,
  Group,
  Badge,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { IconArrowRight } from '@tabler/icons-react';
import httpClient from '@/utils/httpClient';
import { TenancyDashboardData } from '@/models/tenant/tenancy-dashboard.model';

function useTenancies(): UseQueryResult<TenancyDashboardData, Error> {
  return useQuery({
    queryKey: ['tenancy', 'dashboard'],
    queryFn: async () => {
      const { data } = await httpClient.get('/tenancies');
      return data;
    },
  });
}

export function TenanciesList() {
  const { data, error, isPending } = useTenancies();

  if (isPending) {
    return (
      <Center>
        <Loader size="lg" />
      </Center>
    );
  }

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }

  if (!data || (!data.activeTenancyId && data.otherTenancies.length === 0)) {
    return (
      <Stack gap={0} align="center">
        <Title order={4}>No tenancies are linked to your account yet.</Title>
        <Text>Tenancies shared by your estate agent will appear here.</Text>
      </Stack>
    );
  }

  return (
    <>
      {/*<Stack>*/}
      <Link to={`/tenant/tenancy/${data.activeTenancyId}`}>
        <Button
          variant="light"
          rightSection={<IconArrowRight style={{ width: rem(28), height: rem(28) }} stroke={2} />}
          radius="xl"
          size="lg"
          styles={{
            root: { paddingRight: rem(18), height: rem(48) },
            section: { marginLeft: rem(18) },
          }}
        >
          View My Tenancy
        </Button>
      </Link>

      {data.otherTenancies && data.otherTenancies.length > 0 && (
        <>
          <Title order={2} mt="lg" mb="sm" ta={{ base: 'center', xs: 'left' }}>
            Other Tenancies
          </Title>

          {data.otherTenancies.map((tenancy) => (
            <Link
              key={tenancy.tenancyId}
              to={`/tenant/tenancy/${tenancy.tenancyId}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Paper shadow="xs" p="xs">
                <Group justify="space-between">
                  <Group>
                    <Title order={4}>{tenancy.propertyAddress}</Title>
                    {tenancy.isPast && (
                      <Badge size="lg" color="teal">
                        Past
                      </Badge>
                    )}
                  </Group>
                  <IconArrowRight style={{ width: rem(28), height: rem(28) }} stroke={2} />
                </Group>
              </Paper>
            </Link>
          ))}
        </>
      )}
      {/*</Stack>*/}
    </>
  );
}
