import {
  Title,
  Text,
  Button,
  Stack,
  UnstyledButton,
  Group,
  Box,
  Paper,
  ScrollArea,
} from '@mantine/core';
import { IconArrowLeft, IconChevronRight } from '@tabler/icons-react';
import {
  MAINTENANCE_CATEGORIES,
  MaintenanceCategoryData,
  MaintenanceSubTypeData,
} from '@/models/tenant/maintenance.model';
import classes from './RaiseMaintenanceIssueModal.module.css';

interface MaintenanceCategorySelectorProps {
  selectedCategory: MaintenanceCategoryData | null;
  setSelectedCategory: (category: MaintenanceCategoryData | null) => void;
  handleSubTypeSelect: (subType: MaintenanceSubTypeData) => void;
}

export function MaintenanceCategorySelector({
  selectedCategory,
  setSelectedCategory,
  handleSubTypeSelect,
}: MaintenanceCategorySelectorProps) {
  return (
    <>
      {selectedCategory ? (
        <>
          <Group px="md" pb="xs">
            <Button
              justify="center"
              leftSection={<IconArrowLeft size={14} />}
              variant="subtle"
              h={26}
              onClick={() => setSelectedCategory(null)}
            >
              Back
            </Button>

            <Title order={4}>{selectedCategory.label}</Title>
          </Group>

          <ScrollArea h={500} type="auto">
            <Stack gap="xs" px="md" pb="md" pt="sm">
              {selectedCategory.subTypes.map((subType) => (
                <UnstyledButton key={subType.value} onClick={() => handleSubTypeSelect(subType)}>
                  <Paper withBorder shadow="sm" py={8} px="md" className={classes.categoryItem}>
                    <Group justify="space-between" wrap="nowrap">
                      <Title order={5} lh={1.2}>
                        {subType.label}
                      </Title>

                      <IconChevronRight size={20} />
                    </Group>
                  </Paper>
                </UnstyledButton>
              ))}
            </Stack>
          </ScrollArea>
        </>
      ) : (
        <>
          <Group px="md" pb="xs">
            <Title order={4}>What type of issue are you reporting?</Title>
          </Group>

          <ScrollArea h={500} type="auto">
            <Stack gap="xs" px="md" pb="md" pt="sm">
              {MAINTENANCE_CATEGORIES.map((category) => (
                <UnstyledButton key={category.label} onClick={() => setSelectedCategory(category)}>
                  <Paper withBorder shadow="sm" py="xs" px="md" className={classes.categoryItem}>
                    <Group justify="space-between">
                      <Group className={classes.categoryItemContent}>
                        <Box
                          bg="blue.4"
                          w={40}
                          h={40}
                          display="flex"
                          className={classes.categoryIcon}
                        >
                          <category.icon size="1.6rem" />
                        </Box>

                        <Stack gap={0}>
                          <Title order={4} lh={1.4}>
                            {category.label}
                          </Title>
                          <Text lh={1.2}>{category.description}</Text>
                        </Stack>
                      </Group>

                      <Box visibleFrom="sm">
                        <IconChevronRight size={20} />
                      </Box>
                    </Group>
                  </Paper>
                </UnstyledButton>
              ))}
            </Stack>
          </ScrollArea>
        </>
      )}
    </>
  );
}
