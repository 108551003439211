import { Title, Paper, Stack, Text } from '@mantine/core';
import { formatShortDate, getInitials, pluraliseWord } from '@/utils/stringFormat';
import { AgentDetailsCard } from '@/components/Common/AgentDetailsCard/AgentDetailsCard';
import {
  Application,
  RentalLeaseTypeLabels,
  TenancyDurationTypeLabels,
} from '@/models/landlord/application.model';
import { LandlordPropertyApplicationCard } from '@/components/Landlord/LandlordPropertyApplications/LandlordPropertyApplicationCard/LandlordPropertyApplicationCard';

interface ApplicationDrawerProps {
  application: Application;
}

export function LandlordPropertyApplicationDrawer({ application }: ApplicationDrawerProps) {
  const { termDurationNumber, termDurationDays } = application;
  const termDurationType = TenancyDurationTypeLabels[application.termDurationType];

  const termDuration = `${termDurationNumber} ${pluraliseWord(termDurationType, termDurationNumber)}`;
  const additionalDays =
    termDurationDays > 0
      ? ` and ${termDurationDays} ${pluraliseWord('day', termDurationDays)}`
      : '';

  return (
    <Stack>
      <Paper p="md" withBorder>
        <LandlordPropertyApplicationCard application={application} />
      </Paper>

      <Paper p="md" withBorder>
        <Stack gap={0}>
          <Title order={4}>Details</Title>
          <Title order={5} mt="sm">
            Applicant Name{application.renterNames.includes(',') ? 's' : ''}
          </Title>
          <Text>{application.renterNames}</Text>
          <Title order={5} mt="sm">
            Lease Type
          </Title>
          <Text>{RentalLeaseTypeLabels[application.leaseType]}</Text>
          <Title order={5} mt="sm">
            Requested Start Date
          </Title>
          <Text>{formatShortDate(application.startDate)}</Text>
          <Title order={5} mt="sm">
            Tenancy Length
          </Title>
          Enhancements to
          <Text>
            {termDuration}
            {additionalDays}
          </Text>
        </Stack>
      </Paper>

      {application.responsibleAgentName && (
        <AgentDetailsCard
          data={{
            label: 'Negotiator',
            name: application.responsibleAgentName,
            phone: application.responsibleAgentPhone,
            email: application.responsibleAgentEmail,
            avatar: application.responsibleAgentAvatar,
            initials: getInitials(application.responsibleAgentName),
          }}
        />
      )}
    </Stack>
  );
}
