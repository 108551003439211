import { AppShell, useMantineColorScheme } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { Header } from '@/components/Layout/Header';

export default function App() {
  const { colorScheme } = useMantineColorScheme();

  return (
    <AppShell
      header={{ height: 56 }}
      padding="md"
      styles={(theme) => ({
        main: {
          backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      })}
    >
      <AppShell.Header>
        <Header />
      </AppShell.Header>

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}
