import {
  Container,
  Title,
  Button,
  Text,
  PasswordInput,
  Center,
  Box,
  Group,
  Progress,
  Loader,
  SimpleGrid,
  Image,
} from '@mantine/core';
import { IconArrowLeft, IconCheck, IconX } from '@tabler/icons-react';
import React, { FormEventHandler, useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Link, useSearchParams } from 'react-router-dom';
import classes from './ResetPassword.module.css';
import httpClient from '@/utils/httpClient';
import ErrorSvg from '@/public/error.svg';

function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
  return (
    <Text c={meets ? 'teal' : 'red'} mt={5} size="sm">
      <Center inline>
        {meets ? <IconCheck size="0.9rem" stroke={1.5} /> : <IconX size="0.9rem" stroke={1.5} />}
        <Box ml={7}>{label}</Box>
      </Center>
    </Text>
  );
}

const requirements = [
  {
    re: /[0-9]/,
    label: 'Include a number',
  },
  {
    re: /[a-z]/,
    label: 'Include a lowercase letter',
  },
  {
    re: /[A-Z]/,
    label: 'Include an uppercase letter',
  },
];

function getStrength(password: string, passwordConfirm: string) {
  let multiplier = password.length > 7 ? 0 : 1;
  multiplier += password === passwordConfirm ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 2;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 2)) * multiplier, 0);
}

const ErrorElement = (
  <Container className={classes.root}>
    <SimpleGrid spacing={{ base: 40, sm: 80 }} cols={{ base: 1, sm: 2 }}>
      <Image src={ErrorSvg} className={classes.mobileImage} />
      <div>
        <Title className={classes.title}>Something is not right...</Title>
        <Text c="dimmed" size="lg">
          The page you are trying to open does not exist. Our advice is that you return to the login
          page and try again.
        </Text>
        <Button
          variant="outline"
          size="md"
          mt="xl"
          className={classes.control}
          component={Link}
          to="/auth/login"
        >
          Get back to login page
        </Button>
      </div>
      <Image src={ErrorSvg} className={classes.desktopImage} />
    </SimpleGrid>
  </Container>
);

export default function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const [passwordInfo, setPasswordInfo] = useState({
    password: '',
    passwordConfirm: '',
  });
  const [error, setError] = useState(false);
  const [resetToken, setResetToken] = useState();
  const [resetData, setResetData] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [visible, { toggle }] = useDisclosure(false);
  const [hasReset, setReset] = useState(false);

  const strength = getStrength(passwordInfo.password, passwordInfo.passwordConfirm);
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(passwordInfo.password)}
    />
  ));

  const bars = Array(5)
    .fill(0)
    .map((_, index) => (
      <Progress
        value={
          passwordInfo.password.length > 0 && index === 0
            ? 100
            : strength >= ((index + 1) / 5) * 100
              ? 100
              : 0
        }
        color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
        key={index}
        size={5}
      />
    ));

  useEffect(() => {
    const token = searchParams.get('id');
    if (token) {
      setResetToken(resetToken);
      httpClient
        .get(`/users/reset-password-token/${token}`)
        .then((res) => {
          setResetData(res.data);
        })
        .catch(() => setError(true));
    } else {
      setError(true);
    }
  }, [searchParams]);

  if (error) {
    return ErrorElement;
  }

  const submitPassword: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setLoading(true);

    httpClient
      .post('/users/reset-password', {
        password: passwordInfo.password,
        resetToken: searchParams.get('id'),
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setReset(true);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  const userName = `${resetData?.firstName} ${resetData?.lastName}`;

  if (hasReset) {
    return (
      <Container size={460} my={30}>
        <Title className={classes.title} ta="center">
          Password reset successfully!
        </Title>
        <div className={classes.backToLoginButton}>
          <Button leftSection={<IconArrowLeft size="1rem" />} component={Link} to="/auth/login">
            Back to the login page
          </Button>
        </div>
      </Container>
    );
  }

  if (resetData) {
    return (
      <div>
        <Container>
          <Center my={80}>
            <div className={classes.content}>
              <Title className={classes.title}>
                Reset your password for{' '}
                <Text component="span" inherit variant="gradient">
                  PropertyHub
                </Text>{' '}
                <br /> {userName}
              </Title>
              <Text c="dimmed" mt="md">
                Please reset your password to gain access again to your properties
              </Text>

              {/*<List*/}
              {/*  mt={30}*/}
              {/*  spacing="sm"*/}
              {/*  size="sm"*/}
              {/*  icon={*/}
              {/*    <ThemeIcon size={20} radius="xl">*/}
              {/*      <IconCheck size={rem(12)} stroke={1.5} />*/}
              {/*    </ThemeIcon>*/}
              {/*  }*/}
              {/*>*/}
              {/*  <List.Item>*/}
              {/*    <b>Reset your password</b>*/}
              {/*  </List.Item>*/}
              {/*</List>*/}

              <form onSubmit={submitPassword}>
                <div>
                  <div className={classes.form}>
                    <PasswordInput
                      label="New password"
                      placeholder="Your password"
                      mt="md"
                      size="md"
                      value={passwordInfo.password}
                      visible={visible}
                      onVisibilityChange={toggle}
                      // icon={<IconLock size="1rem" />}
                      onChange={({ target }) =>
                        setPasswordInfo({
                          ...passwordInfo,
                          password: target.value,
                        })
                      }
                    />

                    <PasswordInput
                      label="Confirm your new password"
                      placeholder="Your password"
                      mt="md"
                      size="md"
                      value={passwordInfo.passwordConfirm}
                      visible={visible}
                      onVisibilityChange={toggle}
                      // icon={<IconLock size="1rem" />}
                      onChange={({ target }) =>
                        setPasswordInfo({
                          ...passwordInfo,
                          passwordConfirm: target.value,
                        })
                      }
                    />

                    <Group gap={4} grow mt="xs" mb="md">
                      {bars}
                    </Group>

                    <b>Your password has to:</b>
                    <PasswordRequirement
                      label="Be at least 8 characters long"
                      meets={passwordInfo.password.length > 7}
                    />
                    {checks}
                    <PasswordRequirement
                      label="Match with the password confirmation"
                      meets={
                        passwordInfo.password === passwordInfo.passwordConfirm &&
                        passwordInfo.passwordConfirm.length > 0
                      }
                    />

                    <Button fullWidth mt="xl" size="md" type="submit" disabled={strength !== 100}>
                      {loading ? <Loader color="white" size="xs" variant="bars" /> : 'Reset'}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Center>
        </Container>
      </div>
    );
  }
}
