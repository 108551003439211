import { Badge, Group, Paper, Rating, Stack, Table, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { LandlordTenancyInspection } from '@/models/landlord/tenancy.model';
import { formatDateTime } from '@/utils/stringFormat';
import { TenancyInspectionStatus } from '@/models/tenant/inspections.model';

export default function LandlordPropertyTenancyInspections({
  inspections,
}: {
  inspections: LandlordTenancyInspection[];
}) {
  const isMobile = useMediaQuery('(max-width: 620px)');

  if (!inspections || inspections.length === 0) {
    return <Title order={4}>There are no inspections on this property yet</Title>;
  }

  if (isMobile) {
    return inspections.map((inspection) => (
      <Paper key={inspection.id} shadow="xs" p="md" withBorder>
        <Stack gap={4}>
          {inspection.status === TenancyInspectionStatus.Booked && (
            <Badge variant="light" size="lg">
              Booked
            </Badge>
          )}
          {inspection.status === TenancyInspectionStatus.Completed && (
            <Badge variant="light" color="green" size="lg">
              Completed
            </Badge>
          )}
          <Group wrap="wrap" gap={0}>
            <Text fw="bold" mr={6}>
              Inspection:
            </Text>
            <Text>{inspection.appointmentDate && formatDateTime(inspection.appointmentDate)}</Text>
          </Group>
          {inspection.status === TenancyInspectionStatus.Completed && (
            <Group wrap="wrap" gap={0}>
              <Text fw="bold" mr={6}>
                Rating:
              </Text>
              <Rating value={inspection.rating} readOnly />
            </Group>
          )}
          {inspection.status === TenancyInspectionStatus.Completed && (
            <Group wrap="wrap" gap={0}>
              <Text fw="bold" mr={6}>
                Comments:
              </Text>
              <Text>{inspection.comments}</Text>
            </Group>
          )}
        </Stack>
      </Paper>
    ));
  }

  const rows = inspections.map((row: LandlordTenancyInspection) => (
    <Table.Tr key={row.id}>
      <Table.Td>
        {row.status === TenancyInspectionStatus.Booked && (
          <Badge variant="light" size="lg">
            Booked
          </Badge>
        )}
        {row.status === TenancyInspectionStatus.Completed && (
          <Badge variant="light" color="green" size="lg">
            Completed
          </Badge>
        )}
      </Table.Td>
      <Table.Td>{row.appointmentDate && formatDateTime(row.appointmentDate)}</Table.Td>
      {row.status === TenancyInspectionStatus.Completed && (
        <Table.Td>
          <Rating value={row.rating} size="md" readOnly />
        </Table.Td>
      )}
      {row.status === TenancyInspectionStatus.Completed && <Table.Td>{row.comments}</Table.Td>}
    </Table.Tr>
  ));

  return (
    <Paper shadow="xs" withBorder>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Date & Time</Table.Th>
            <Table.Th>Rating</Table.Th>
            <Table.Th>Comments</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Paper>
  );
}
