/**
 * Rounds a number to a specified precision.
 *
 * @param {number} value - The number to be rounded.
 * @param {number} [precision=0] - The number of decimal places to round to. Defaults to 0 if not provided.
 * @returns {number} The rounded number.
 */
export const roundNumber = (value: number, precision: number = 0): number => {
  const multiplier = 10 ** precision;
  return Math.round(value * multiplier) / multiplier;
};
