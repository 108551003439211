import { Paper, Title, Text, Image, Stack, Grid } from '@mantine/core';
import classes from './TenancyHeader.module.css';
import { formatCurrency, formatShortDate } from '@/utils/stringFormat';
import { TenancyData } from '@/models/tenant/tenancy.model';
import { RentFrequencyToShortName } from '@/models/landlord/rental-property.model';

interface TenancyHeaderProps {
  headerData: TenancyData;
}

export function TenancyHeader({ headerData }: TenancyHeaderProps) {
  return (
    <Paper shadow="xs" p="0" style={{ overflow: 'hidden' }}>
      <Grid gutter={0}>
        <Grid.Col span={{ base: 12, xs: 'content' }}>
          <Image
            width={174}
            height={124}
            src={`${headerData.propertyPhoto}?width=495`}
            alt="Property Image"
            fallbackSrc="https://placehold.co/174x115?text=No%20Image"
            className={classes.imageContainer}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, xs: 'auto' }}>
          <Stack gap={0} p="sm">
            <Title className={classes.title} fz={{ base: 18, md: 22 }} fw={600}>
              {headerData.propertyAddress}
            </Title>
            <Text size="md">
              {formatCurrency(headerData.rentAmount)}{' '}
              {RentFrequencyToShortName[headerData.rentFrequency]}
            </Text>
            <Text size="md">
              Tenancy Period: {formatShortDate(headerData.startDate)}
              {headerData.endDate ? ` - ${formatShortDate(headerData.endDate)}` : ''}
            </Text>
            <Text size="sm" fs="italic">
              {headerData.rentersNames?.join(', ')}
            </Text>
          </Stack>
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
