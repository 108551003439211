import { useState, useEffect } from 'react';
import { Container, Tabs, Paper, Breadcrumbs, Anchor, Center, Loader, Title } from '@mantine/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { SellingPropertyHeader } from '@/components/Selling/SellingPropertyHeader/SellingPropertyHeader';
import { SellingPropertyMarketing } from '@/components/Selling/SellingPropertyMarketing/SellingPropertyMarketing';
import { SellingPropertyViewings } from '@/components/Selling/SellingPropertyViewings/SellingPropertyViewings';
import { SellingPropertyOffers } from '@/components/Selling/SellingPropertyOffers/SellingPropertyOffers';
import { SellingPropertySales } from '@/components/Selling/SellingPropertySales/SellingPropertySales';
import httpClient from '@/utils/httpClient';
import { TeamDetailsCard } from '@/components/Common/TeamDetailsCard/TeamDetailsCard';

const breadcrumbs = [
  { title: 'Home', href: '/selling' },
  { title: 'Property', href: '#' },
].map((item, index) => (
  <Anchor key={index} to={item.href} component={Link}>
    {item.title}
  </Anchor>
));

function usePropertySettings(propertyId: number) {
  return useQuery({
    queryKey: ['selling', 'settings', propertyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/properties/${propertyId}/settings`);
      return data;
    },
    enabled: !!propertyId,
  });
}

function useHeaderData(propertyId: number) {
  return useQuery({
    queryKey: ['selling', 'header', propertyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/properties/${propertyId}/header`);
      return data;
    },
    enabled: !!propertyId,
  });
}

export function SellingPropertyPage() {
  const { propertyId, activeTab } = useParams();
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const navigate = useNavigate();
  const {
    data: settings,
    error: settingsError,
    isPending: isSettingsPending,
  } = usePropertySettings(Number(propertyId));
  const {
    data: headerData,
    error: headerError,
    isPending: isHeaderPending,
  } = useHeaderData(Number(propertyId));

  useEffect(() => {
    if (settings) {
      setActiveTab(activeTab || null);
    }
  }, [activeTab, settings]);

  const setActiveTab = (tab: string | null) => {
    if ((tab === 'marketing' || tab === null) && settings.hasMarketing) {
      setSelectedTab('marketing');
    } else if ((tab === 'viewings' || tab === null) && settings.hasViewings) {
      setSelectedTab('viewings');
    } else if ((tab === 'offers' || tab === null) && settings.hasOffers) {
      setSelectedTab('offers');
    } else if ((tab === 'sales' || tab === null) && settings.hasSalesProgression) {
      setSelectedTab('sales');
    } else {
      setSelectedTab('contact');
    }
  };

  const changeTab = (value: string | null) => {
    navigate(`/selling/property/${propertyId}/${value}`);
  };

  if (settingsError || headerError) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {settingsError?.message || headerError?.message}
      </Title>
    );
  }

  if (!settings || isSettingsPending || isHeaderPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Container p={0}>
      <Breadcrumbs mb="md">{breadcrumbs}</Breadcrumbs>

      <SellingPropertyHeader
        headerData={headerData}
        showViewings={settings.hasViewings}
        showOffers={settings.hasOffers}
      />

      <Tabs value={selectedTab} onChange={(value) => changeTab(value)}>
        <Paper shadow="xs" mt="lg">
          <Tabs.List>
            {settings.hasMarketing && <Tabs.Tab value="marketing">Marketing</Tabs.Tab>}
            {settings.hasViewings && <Tabs.Tab value="viewings">Viewings</Tabs.Tab>}
            {settings.hasOffers && <Tabs.Tab value="offers">Offers</Tabs.Tab>}
            {settings.hasSalesProgression && <Tabs.Tab value="sales">Sales</Tabs.Tab>}
            <Tabs.Tab value="contact">Contact</Tabs.Tab>
          </Tabs.List>
        </Paper>

        {settings.hasMarketing && (
          <Tabs.Panel value="marketing" pt="xs">
            <SellingPropertyMarketing propertyId={Number(propertyId)} teamId={headerData.teamId} />
          </Tabs.Panel>
        )}

        {settings.hasViewings && (
          <Tabs.Panel value="viewings" pt="xs">
            <SellingPropertyViewings
              propertyId={Number(propertyId)}
              showFeedback={settings.hasViewingFeedback}
            />
          </Tabs.Panel>
        )}

        {settings.hasOffers && (
          <Tabs.Panel value="offers" pt="xs">
            <SellingPropertyOffers propertyId={Number(propertyId)} />
          </Tabs.Panel>
        )}

        {settings.hasSalesProgression && (
          <Tabs.Panel value="sales" pt="xs">
            <SellingPropertySales
              propertyId={Number(propertyId)}
              showDocuments={settings.hasSalesProgressionDocuments}
              showNotes={settings.hasSalesProgressionNotes}
            />
          </Tabs.Panel>
        )}

        <Tabs.Panel value="contact" pt="xs">
          <TeamDetailsCard teamId={headerData.teamId} my="sm" />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
}
