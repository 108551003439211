import axios from 'axios';
import Cookies from 'js-cookie';
import router from '@/Router';

const httpClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL as string,
});

httpClient.interceptors.request.use((config) => {
  const token = Cookies.get('_auth');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired, and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = Cookies.get('_auth_refresh');
        const response = await httpClient.post('/users/refresh-token', { refreshToken });
        const { jwtToken } = response.data;

        Cookies.set('_auth', jwtToken, {
          expires: new Date(new Date().getTime() + 60 * 60 * 1000), // 60 minutes
          sameSite: 'strict',
          secure: window.location.protocol === 'https:',
        });

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${jwtToken}`;
        return await httpClient(originalRequest);
      } catch {
        // Handle refresh token error or redirect to login
        Cookies.remove('_auth');
        Cookies.remove('_auth_refresh');
        Cookies.remove('_auth_user');
        await router.navigate('/auth/login');
      }
    }

    return Promise.reject(error);
  }
);

export default httpClient;
