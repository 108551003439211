import { Title, Text, Grid, Container, SimpleGrid, ThemeIcon } from '@mantine/core';
import {
  IconRepeat,
  IconCalendarPin,
  IconUserShare,
  IconMessage2,
  IconHomeDollar,
  IconHeart,
} from '@tabler/icons-react';
import classes from './Login.module.css';
import { LoginForm } from '@/components/Auth/LoginForm/LoginForm';

export const DATA = [
  {
    icon: IconRepeat,
    title: 'In the Loop',
    description:
      // eslint-disable-next-line max-len
      'Whether you are selling, buying, renting or letting – PropertyHub keeps you in the loop from the convenience of your phone, at any time to suit you',
  },
  {
    icon: IconUserShare,
    title: 'Tech & Touch',
    description:
      // eslint-disable-next-line max-len
      'Sharing information between agent & client leads to greater transparency and more trusting relationships. PropertyHub is an ideal mix of tech & touch',
  },
  {
    icon: IconCalendarPin,
    title: 'Track viewings',
    description:
      // eslint-disable-next-line max-len
      'Sellers can track viewing requests and forthcoming viewings. With their agent they can review historical feedback and assess pricing strategy',
  },
  {
    icon: IconHomeDollar,
    title: 'Track offers',
    description:
      // eslint-disable-next-line max-len
      'Sellers can review offers on their property and work with their agent to assess proceedability and make an informed decision about accepting the best offer',
  },
  {
    icon: IconMessage2,
    title: 'Live Updates',
    description:
      'Live updates on sales progression helps with communication and understanding with access to detailed notes and supporting documents',
  },
  {
    icon: IconHeart,
    title: 'Love, Loop, Life',
    description:
      // eslint-disable-next-line max-len
      'PropertyHub is built by the same people behind Loop, the market leading estate agency software system for leading property people all over the UK',
  },
];

interface FeatureProps {
  icon: React.FC<any>;
  title: React.ReactNode;
  description: React.ReactNode;
}

export function Feature({ icon: Icon, title, description }: FeatureProps) {
  return (
    <div>
      <ThemeIcon variant="light" size={40} radius={40}>
        <Icon size="1.1rem" stroke={1.5} />
      </ThemeIcon>
      <Text mt="sm" mb={7}>
        {title}
      </Text>
      <Text size="sm" c="dimmed" lh={1.6}>
        {description}
      </Text>
    </div>
  );
}

export default function LoginPage() {
  const features = DATA.map((feature, index) => <Feature {...feature} key={index} />);

  return (
    <Grid gutter={0}>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <LoginForm />
      </Grid.Col>

      <Grid.Col span="auto" mt={80} mb={40} mx={{ base: 'xl', sm: 100 }}>
        <Title className={classes.title} order={1} lh={1.2} mb="xs">
          PropertyHub connects, informs & delights people about property
        </Title>

        <Container size={560} p={0}>
          <Text size="sm" className={classes.description}>
            Modern estate agents use PropertyHub to provide their clients with real-time updates on
            their property matters.
          </Text>
        </Container>

        <SimpleGrid
          mt={60}
          cols={{ base: 1, md: 2, lg: 3 }}
          spacing={{ base: 'xl', md: 50 }}
          verticalSpacing={{ base: 'xl', md: 50 }}
        >
          {features}
        </SimpleGrid>
      </Grid.Col>
    </Grid>
  );
}
