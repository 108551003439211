import {
  TablerIconsProps,
  IconBell,
  IconBath,
  IconArmchair,
  IconLock,
  IconFlame,
  IconCloudRain,
  IconBulb,
  IconFence,
  IconDroplet,
  IconHomeQuestion,
  IconFridge,
} from '@tabler/icons-react';

export interface MaintenanceJob {
  id: number;
  completed: boolean;
  status: MaintenanceStatus;
  contractorName: string;
  dateReported: string;
  emergency: boolean;
  jobDate: string;
  photos: string[];
  reportedByNotes: string;
  summary: string;
}

export enum MaintenanceStatus {
  NotAcknowledged = 1,
  Pending = 2,
  Cancelled = 3,
  Approved = 4,
  Complete = 5,
  InvoiceReceived = 6,
  InvoicePaid = 7,
}

export enum MaintenanceCategory {
  AlarmsSmokeDetectors = 1,
  AppliancesKitchenHousehold = 2,
  BathroomsToilets = 3,
  BlindsCurtainsFurnitureFixturesFittings = 4,
  BoilersHeatingHotWater = 5,
  DoorsWindowsLocks = 6,
  DrainageGuttering = 7,
  ElectricityLighting = 8,
  GardenPropertyExterior = 9,
  LeaksRunningWaterTaps = 10,
  Other = 11,
}

export enum MaintenanceSubType {
  AlarmsSmokeDetectorsCarbonMonoxideAlarmProblem = 1,
  AlarmsSmokeDetectorsSecurityAlarmProblem = 2,
  AlarmsSmokeDetectorsSmokeAlarmBeeping = 3,
  AlarmsSmokeDetectorsSmokeAlarmNotWorking = 4,
  AlarmsSmokeDetectorsSmokeAlarmTooSensitive = 5,
  AlarmsSmokeDetectorsOther = 6,
  AppliancesKitchenHouseholdDishwasher = 7,
  AppliancesKitchenHouseholdElectricHeater = 8,
  AppliancesKitchenHouseholdExtractorFan = 9,
  AppliancesKitchenHouseholdExtractorHood = 10,
  AppliancesKitchenHouseholdFridgeFreezer = 11,
  AppliancesKitchenHouseholdMicrowave = 12,
  AppliancesKitchenHouseholdOvenCooker = 13,
  AppliancesKitchenHouseholdTelevision = 14,
  AppliancesKitchenHouseholdTumbleDryer = 15,
  AppliancesKitchenHouseholdWashingMachine = 16,
  AppliancesKitchenHouseholdOther = 17,
  BathroomsToiletsBathroomLeakNonEmergency = 18,
  BathroomsToiletsBathroomLeakSerious = 19,
  BathroomsToiletsExtractorFanNotWorking = 20,
  BathroomsToiletsFlooringCarpetProblem = 21,
  BathroomsToiletsNotWaterNotWorking = 22,
  BathroomsToiletsLightNotWorking = 23,
  BathroomsToiletsPipeLeaking = 24,
  BathroomsToiletsRadiatorsHeatersTowelRadiator = 25,
  BathroomsToiletsSealantGroutingTilesIssue = 26,
  BathroomsToiletsShowerProblem = 27,
  BathroomsToiletsTapDripping = 28,
  BathroomsToiletsTapLeaking = 29,
  BathroomsToiletsTapLoose = 30,
  BathroomsToiletsTapNotWorking = 31,
  BathroomsToiletsToiletBlockedToilet = 32,
  BathroomsToiletsToiletHandleBroken = 33,
  BathroomsToiletsToiletLeakOnToiletPipework = 34,
  BathroomsToiletsToiletLeakFromToiletBasin = 35,
  BathroomsToiletsToiletNotFlushingProperly = 36,
  BathroomsToiletsToiletSeatBroken = 37,
  BathroomsToiletsTowelRailIssue = 38,
  BathroomsToiletsWindowProblem = 39,
  BathroomsToiletsOther = 40,
  BlindsCurtainsFurnitureFixturesFittingsBlindBrokenProblem = 41,
  BlindsCurtainsFurnitureFixturesFittingsCurtainPoileFallenDownBroken = 42,
  BlindsCurtainsFurnitureFixturesFittingsFurnitureProblem = 43,
  BlindsCurtainsFurnitureFixturesFittingsWallFixingsProblem = 44,
  BlindsCurtainsFurnitureFixturesFittingsOther = 45,
  BoilersHeatingHotWaterBoilerIssues = 46,
  BoilersHeatingHotWaterNoHotWater = 47,
  BoilersHeatingHotWaterNoHeating = 48,
  BoilersHeatingHotWaterNoHotWaterAndNoHeating = 49,
  BoilersHeatingHotWaterNotWaterProblemToOneTap = 50,
  BoilersHeatingHotWaterRadiatorNotGettingHot = 51,
  BoilersHeatingHotWaterRadiatorLeaking = 52,
  BoilersHeatingHotWaterShowerHotWaterIssue = 53,
  BoilersHeatingHotWaterOther = 54,
  DoorsWindowsLocksBackDoorIssue = 55,
  DoorsWindowsLocksCupboardDoorIssue = 56,
  DoorsWindowsLocksDoorHandleIssue = 57,
  DoorsWindowsLocksExternalDoorLockIssue = 58,
  DoorsWindowsLocksFrontDoorIssue = 59,
  DoorsWindowsLocksInternalDoorLockIssue = 60,
  DoorsWindowsLocksPatioDoorIssue = 61,
  DoorsWindowsLocksWindowLatchProblem = 62,
  DoorsWindowsLocksWindowBroken = 63,
  DoorsWindowsLocksWindowCracked = 64,
  DoorsWindowsLocksWindowHandleProblem = 65,
  DoorsWindowsLocksOther = 66,
  DrainageGutteringBathDrainProblem = 67,
  DrainageGutteringBathroomSinkDrainProblem = 68,
  DrainageGutteringKitchenSinkDrainProblem = 69,
  DrainageGutteringShowerDrainProblem = 70,
  DrainageGutteringToiletSinkDrainProblem = 71,
  DrainageGutteringDrainsSmelling = 72,
  DrainageGutteringExternalDrainIssue = 73,
  DrainageGutteringExternalGutteringIssue = 74,
  DrainageGutteringLeakingWastePipe = 75,
  DrainageGutteringOther = 76,
  ElectricityLightingElectricFuseBoxTripping = 77,
  ElectricityLightingNoElectricsLightsAndSockets = 78,
  ElectricityLightingNoElectricsJustLights = 79,
  ElectricityLightingNoElectricsJustSockets = 80,
  ElectricityLightingPlugSocketNotWorking = 81,
  ElectricityLightingPlugSocketDamagedLoose = 82,
  ElectricityLightingLightBulbNeedsReplacing = 83,
  ElectricityLightingLightNotWorkingSingleLight = 84,
  ElectricityLightingLightNotWorkingAllLights = 85,
  ElectricityLightingLightSwitchNotWorkingBroken = 86,
  ElectricityLightingOther = 87,
  GardenPropertyExteriorBlockedExternalDrain = 88,
  GardenPropertyExteriorFencingDamaged = 89,
  GardenPropertyExteriorFencingFallenDown = 90,
  GardenPropertyExteriorGarageDoorProblem = 91,
  GardenPropertyExteriorGardenIssue = 92,
  GardenPropertyExteriorGutteringProblem = 93,
  GardenPropertyExteriorShedProblem = 94,
  GardenPropertyExteriorOutsideWallIssue = 95,
  GardenPropertyExteriorOther = 96,
  LeaksRunningWaterTapsLeakEmergency = 97,
  LeaksRunningWaterTapsLeakMinorNoDamage = 98,
  LeaksRunningWaterTapsFlatAboveLeaking = 99,
  LeaksRunningWaterTapsLeakIntoAnotherFloorOrProperty = 100,
  LeaksRunningWaterTapsEmergencyLeakToiletLeakPipeRadiator = 101,
  LeaksRunningWaterTapsWaterLeakOther = 102,
  LeaksRunningWaterTapsOverflowPipeIssue = 103,
  LeaksRunningWaterTapsRadiatorLeaking = 104,
  LeaksRunningWaterTapsTapNotWorking = 105,
  LeaksRunningWaterTapsTapDripping = 106,
  LeaksRunningWaterTapsTapLeaking = 107,
  LeaksRunningWaterTapsTapLoose = 108,
  LeaksRunningWaterTapsTapJammed = 109,
  LeaksRunningWaterTapsWashingMachineLeaking = 110,
  LeaksRunningWaterTapsOther = 111,
  OtherSmell = 112,
  OtherDamp = 113,
  OtherPestsMiceRats = 114,
  OtherWasp = 115,
  OtherOther = 116,
}

export interface MaintenanceCategoryData {
  label: string;
  description: string;
  category: MaintenanceCategory;
  subTypes: MaintenanceSubTypeData[];
  icon: (props: TablerIconsProps) => JSX.Element;
}

export interface MaintenanceSubTypeData {
  label: string;
  value: MaintenanceSubType;
}

export const MAINTENANCE_CATEGORIES: MaintenanceCategoryData[] = [
  {
    label: 'Bathroom & Toilets',
    description: 'Leaking, damaged or broken facilities',
    category: MaintenanceCategory.BathroomsToilets,
    icon: IconBath,
    subTypes: [
      {
        label: 'Bathroom leak',
        value: MaintenanceSubType.BathroomsToiletsBathroomLeakNonEmergency,
      },
      {
        label: 'Extractor fan not working',
        value: MaintenanceSubType.BathroomsToiletsExtractorFanNotWorking,
      },
      {
        label: 'Flooring/carpet issue',
        value: MaintenanceSubType.BathroomsToiletsFlooringCarpetProblem,
      },
      {
        label: 'No running water',
        value: MaintenanceSubType.BathroomsToiletsNotWaterNotWorking,
      },
      {
        label: 'Light not working',
        value: MaintenanceSubType.BathroomsToiletsLightNotWorking,
      },
      {
        label: 'Pipe leaking',
        value: MaintenanceSubType.BathroomsToiletsPipeLeaking,
      },
      {
        label: 'Radiator/heater/towel rail issue',
        value: MaintenanceSubType.BathroomsToiletsRadiatorsHeatersTowelRadiator,
      },
      {
        label: 'Sealant/grouting/tiles issue',
        value: MaintenanceSubType.BathroomsToiletsSealantGroutingTilesIssue,
      },
      {
        label: 'Shower issue',
        value: MaintenanceSubType.BathroomsToiletsShowerProblem,
      },
      {
        label: 'Tap dripping',
        value: MaintenanceSubType.BathroomsToiletsTapDripping,
      },
      {
        label: 'Tap leaking',
        value: MaintenanceSubType.BathroomsToiletsTapLeaking,
      },
      {
        label: 'Tap loose',
        value: MaintenanceSubType.BathroomsToiletsTapLoose,
      },
      {
        label: 'Tap not working',
        value: MaintenanceSubType.BathroomsToiletsTapNotWorking,
      },
      {
        label: 'Toilet blocked',
        value: MaintenanceSubType.BathroomsToiletsToiletBlockedToilet,
      },
      {
        label: 'Toilet handle broken',
        value: MaintenanceSubType.BathroomsToiletsToiletHandleBroken,
      },
      {
        label: 'Toilet pipework leak',
        value: MaintenanceSubType.BathroomsToiletsToiletLeakOnToiletPipework,
      },
      {
        label: 'Toilet basin leak',
        value: MaintenanceSubType.BathroomsToiletsToiletLeakFromToiletBasin,
      },
      {
        label: 'Toilet not flushing properly',
        value: MaintenanceSubType.BathroomsToiletsToiletNotFlushingProperly,
      },
      {
        label: 'Toilet seat broken',
        value: MaintenanceSubType.BathroomsToiletsToiletSeatBroken,
      },
      {
        label: 'Towel rail issue',
        value: MaintenanceSubType.BathroomsToiletsTowelRailIssue,
      },
      {
        label: 'Window issue',
        value: MaintenanceSubType.BathroomsToiletsWindowProblem,
      },
      {
        label: 'Other',
        value: MaintenanceSubType.BathroomsToiletsOther,
      },
    ],
  },
  {
    label: 'Kitchen/Household Appliances',
    description: 'Faulty, damaged or not working',
    category: MaintenanceCategory.AppliancesKitchenHousehold,
    icon: IconFridge,
    subTypes: [
      {
        label: 'Dishwasher',
        value: MaintenanceSubType.AppliancesKitchenHouseholdDishwasher,
      },
      {
        label: 'Electric heater',
        value: MaintenanceSubType.AppliancesKitchenHouseholdElectricHeater,
      },
      {
        label: 'Extractor fan',
        value: MaintenanceSubType.AppliancesKitchenHouseholdExtractorFan,
      },
      {
        label: 'Extractor hood',
        value: MaintenanceSubType.AppliancesKitchenHouseholdExtractorHood,
      },
      {
        label: 'Fridge freezer',
        value: MaintenanceSubType.AppliancesKitchenHouseholdFridgeFreezer,
      },
      {
        label: 'Microwave',
        value: MaintenanceSubType.AppliancesKitchenHouseholdMicrowave,
      },
      {
        label: 'Oven cooker',
        value: MaintenanceSubType.AppliancesKitchenHouseholdOvenCooker,
      },
      {
        label: 'Television',
        value: MaintenanceSubType.AppliancesKitchenHouseholdTelevision,
      },
      {
        label: 'Tumble dryer',
        value: MaintenanceSubType.AppliancesKitchenHouseholdTumbleDryer,
      },
      {
        label: 'Washing machine',
        value: MaintenanceSubType.AppliancesKitchenHouseholdWashingMachine,
      },
      {
        label: 'Other',
        value: MaintenanceSubType.AppliancesKitchenHouseholdOther,
      },
    ],
  },
  {
    label: 'Boilers, Heating & Hot Water',
    description: 'Faulty boiler, no heating or hot water',
    category: MaintenanceCategory.BoilersHeatingHotWater,
    icon: IconFlame,
    subTypes: [
      {
        label: 'Boiler issue',
        value: MaintenanceSubType.BoilersHeatingHotWaterBoilerIssues,
      },
      {
        label: 'No hot water',
        value: MaintenanceSubType.BoilersHeatingHotWaterNoHotWater,
      },
      {
        label: 'No heating',
        value: MaintenanceSubType.BoilersHeatingHotWaterNoHeating,
      },
      {
        label: 'No hot water and no heating',
        value: MaintenanceSubType.BoilersHeatingHotWaterNoHotWaterAndNoHeating,
      },
      {
        label: 'Hot water issue to one tap',
        value: MaintenanceSubType.BoilersHeatingHotWaterNotWaterProblemToOneTap,
      },
      {
        label: 'Radiator not getting hot',
        value: MaintenanceSubType.BoilersHeatingHotWaterRadiatorNotGettingHot,
      },
      {
        label: 'Radiator leaking',
        value: MaintenanceSubType.BoilersHeatingHotWaterRadiatorLeaking,
      },
      {
        label: 'Shower hot water issue',
        value: MaintenanceSubType.BoilersHeatingHotWaterShowerHotWaterIssue,
      },
      {
        label: 'Other',
        value: MaintenanceSubType.BoilersHeatingHotWaterOther,
      },
    ],
  },
  {
    label: 'Electrical & Lighting',
    description: 'No electricity, faulty sockets or switches',
    category: MaintenanceCategory.ElectricityLighting,
    icon: IconBulb,
    subTypes: [
      {
        label: 'Electric fuse box tripping',
        value: MaintenanceSubType.ElectricityLightingElectricFuseBoxTripping,
      },
      {
        label: 'No electrics (lights and sockets)',
        value: MaintenanceSubType.ElectricityLightingNoElectricsLightsAndSockets,
      },
      {
        label: 'No electrics (just lights)',
        value: MaintenanceSubType.ElectricityLightingNoElectricsJustLights,
      },
      {
        label: 'No electrics (just sockets)',
        value: MaintenanceSubType.ElectricityLightingNoElectricsJustSockets,
      },
      {
        label: 'Plug socket not working',
        value: MaintenanceSubType.ElectricityLightingPlugSocketNotWorking,
      },
      {
        label: 'Plug socket damaged/loose',
        value: MaintenanceSubType.ElectricityLightingPlugSocketDamagedLoose,
      },
      {
        label: 'Light bulb needs replacing',
        value: MaintenanceSubType.ElectricityLightingLightBulbNeedsReplacing,
      },
      {
        label: 'Light not working (single light)',
        value: MaintenanceSubType.ElectricityLightingLightNotWorkingSingleLight,
      },
      {
        label: 'Light not working (all lights)',
        value: MaintenanceSubType.ElectricityLightingLightNotWorkingAllLights,
      },
      {
        label: 'Light switch not working/broken',
        value: MaintenanceSubType.ElectricityLightingLightSwitchNotWorkingBroken,
      },
      {
        label: 'Other',
        value: MaintenanceSubType.ElectricityLightingOther,
      },
    ],
  },
  {
    label: 'Leaks, Running Water & Taps',
    description: 'No running water or leaking pipes',
    category: MaintenanceCategory.LeaksRunningWaterTaps,
    icon: IconDroplet,
    subTypes: [
      {
        label: 'Leak (emergency)',
        value: MaintenanceSubType.LeaksRunningWaterTapsLeakEmergency,
      },
      {
        label: 'Leak (minor, no damage)',
        value: MaintenanceSubType.LeaksRunningWaterTapsLeakMinorNoDamage,
      },
      {
        label: 'Flat above leaking',
        value: MaintenanceSubType.LeaksRunningWaterTapsFlatAboveLeaking,
      },
      {
        label: 'Leak into another floor or property',
        value: MaintenanceSubType.LeaksRunningWaterTapsLeakIntoAnotherFloorOrProperty,
      },
      {
        label: 'Overflow pipe issue',
        value: MaintenanceSubType.LeaksRunningWaterTapsOverflowPipeIssue,
      },
      {
        label: 'Radiator leaking',
        value: MaintenanceSubType.LeaksRunningWaterTapsRadiatorLeaking,
      },
      {
        label: 'Tap not working',
        value: MaintenanceSubType.LeaksRunningWaterTapsTapNotWorking,
      },
      {
        label: 'Tap dripping',
        value: MaintenanceSubType.LeaksRunningWaterTapsTapDripping,
      },
      {
        label: 'Tap leaking',
        value: MaintenanceSubType.LeaksRunningWaterTapsTapLeaking,
      },
      {
        label: 'Tap loose',
        value: MaintenanceSubType.LeaksRunningWaterTapsTapLoose,
      },
      {
        label: 'Tap jammed',
        value: MaintenanceSubType.LeaksRunningWaterTapsTapJammed,
      },
      {
        label: 'Washing machine leaking',
        value: MaintenanceSubType.LeaksRunningWaterTapsWashingMachineLeaking,
      },
      {
        label: 'Other',
        value: MaintenanceSubType.LeaksRunningWaterTapsOther,
      },
    ],
  },
  {
    label: 'Alarms & Smoke Detectors',
    description: 'Faulty, damaged or not working',
    category: MaintenanceCategory.AlarmsSmokeDetectors,
    icon: IconBell,
    subTypes: [
      {
        label: 'Carbon monoxide alarm issue',
        value: MaintenanceSubType.AlarmsSmokeDetectorsCarbonMonoxideAlarmProblem,
      },
      {
        label: 'Security alarm issue',
        value: MaintenanceSubType.AlarmsSmokeDetectorsSecurityAlarmProblem,
      },
      {
        label: 'Smoke alarm beeping',
        value: MaintenanceSubType.AlarmsSmokeDetectorsSmokeAlarmBeeping,
      },
      {
        label: 'Smoke alarm not working',
        value: MaintenanceSubType.AlarmsSmokeDetectorsSmokeAlarmNotWorking,
      },
      {
        label: 'Smoke alarm too sensitive',
        value: MaintenanceSubType.AlarmsSmokeDetectorsSmokeAlarmTooSensitive,
      },
      {
        label: 'Other',
        value: MaintenanceSubType.AlarmsSmokeDetectorsOther,
      },
    ],
  },
  {
    label: 'Furniture, Fixtures & Fittings',
    description: 'Damaged or not fit for purpose',
    category: MaintenanceCategory.BlindsCurtainsFurnitureFixturesFittings,
    icon: IconArmchair,
    subTypes: [
      {
        label: 'Blind broken',
        value: MaintenanceSubType.BlindsCurtainsFurnitureFixturesFittingsBlindBrokenProblem,
      },
      {
        label: 'Curtain pole fallen down/broken',
        value:
          MaintenanceSubType.BlindsCurtainsFurnitureFixturesFittingsCurtainPoileFallenDownBroken,
      },
      {
        label: 'Furniture issue',
        value: MaintenanceSubType.BlindsCurtainsFurnitureFixturesFittingsFurnitureProblem,
      },
      {
        label: 'Wall fixings issue',
        value: MaintenanceSubType.BlindsCurtainsFurnitureFixturesFittingsWallFixingsProblem,
      },
      {
        label: 'Other',
        value: MaintenanceSubType.BlindsCurtainsFurnitureFixturesFittingsOther,
      },
    ],
  },
  {
    label: 'Doors, Windows & Locks',
    description: 'Faulty, damaged or not closing properly',
    category: MaintenanceCategory.DoorsWindowsLocks,
    icon: IconLock,
    subTypes: [
      {
        label: 'Back door issue',
        value: MaintenanceSubType.DoorsWindowsLocksBackDoorIssue,
      },
      {
        label: 'Cupboard door issue',
        value: MaintenanceSubType.DoorsWindowsLocksCupboardDoorIssue,
      },
      {
        label: 'Door handle issue',
        value: MaintenanceSubType.DoorsWindowsLocksDoorHandleIssue,
      },
      {
        label: 'External door lock issue',
        value: MaintenanceSubType.DoorsWindowsLocksExternalDoorLockIssue,
      },
      {
        label: 'Front door issue',
        value: MaintenanceSubType.DoorsWindowsLocksFrontDoorIssue,
      },
      {
        label: 'Internal door lock issue',
        value: MaintenanceSubType.DoorsWindowsLocksInternalDoorLockIssue,
      },
      {
        label: 'Patio door issue',
        value: MaintenanceSubType.DoorsWindowsLocksPatioDoorIssue,
      },
      {
        label: 'Window latch issue',
        value: MaintenanceSubType.DoorsWindowsLocksWindowLatchProblem,
      },
      {
        label: 'Window broken',
        value: MaintenanceSubType.DoorsWindowsLocksWindowBroken,
      },
      {
        label: 'Window cracked',
        value: MaintenanceSubType.DoorsWindowsLocksWindowCracked,
      },
      {
        label: 'Window handle issue',
        value: MaintenanceSubType.DoorsWindowsLocksWindowHandleProblem,
      },
      {
        label: 'Other',
        value: MaintenanceSubType.DoorsWindowsLocksOther,
      },
    ],
  },
  {
    label: 'Drainage & Guttering',
    description: 'Blocked drains or gutters',
    category: MaintenanceCategory.DrainageGuttering,
    icon: IconCloudRain,
    subTypes: [
      {
        label: 'Bath drain issue',
        value: MaintenanceSubType.DrainageGutteringBathDrainProblem,
      },
      {
        label: 'Bathroom sink drain issue',
        value: MaintenanceSubType.DrainageGutteringBathroomSinkDrainProblem,
      },
      {
        label: 'Kitchen sink drain issue',
        value: MaintenanceSubType.DrainageGutteringKitchenSinkDrainProblem,
      },
      {
        label: 'Shower drain issue',
        value: MaintenanceSubType.DrainageGutteringShowerDrainProblem,
      },
      {
        label: 'Toilet sink drain issue',
        value: MaintenanceSubType.DrainageGutteringToiletSinkDrainProblem,
      },
      {
        label: 'Drains smelling',
        value: MaintenanceSubType.DrainageGutteringDrainsSmelling,
      },
      {
        label: 'External drain issue',
        value: MaintenanceSubType.DrainageGutteringExternalDrainIssue,
      },
      {
        label: 'External guttering issue',
        value: MaintenanceSubType.DrainageGutteringExternalGutteringIssue,
      },
      {
        label: 'Leaking waste pipe',
        value: MaintenanceSubType.DrainageGutteringLeakingWastePipe,
      },
      {
        label: 'Other',
        value: MaintenanceSubType.DrainageGutteringOther,
      },
    ],
  },
  {
    label: 'Garden & Property Exterior',
    description: 'No electricity, faulty sockets or switches',
    category: MaintenanceCategory.GardenPropertyExterior,
    icon: IconFence,
    subTypes: [
      {
        label: 'Blocked external drain',
        value: MaintenanceSubType.GardenPropertyExteriorBlockedExternalDrain,
      },
      {
        label: 'Fencing damaged',
        value: MaintenanceSubType.GardenPropertyExteriorFencingDamaged,
      },
      {
        label: 'Fencing fallen down',
        value: MaintenanceSubType.GardenPropertyExteriorFencingFallenDown,
      },
      {
        label: 'Garage door issue',
        value: MaintenanceSubType.GardenPropertyExteriorGarageDoorProblem,
      },
      {
        label: 'Garden issue',
        value: MaintenanceSubType.GardenPropertyExteriorGardenIssue,
      },
      {
        label: 'Guttering issue',
        value: MaintenanceSubType.GardenPropertyExteriorGutteringProblem,
      },
      {
        label: 'Shed issue',
        value: MaintenanceSubType.GardenPropertyExteriorShedProblem,
      },
      {
        label: 'Outside wall issue',
        value: MaintenanceSubType.GardenPropertyExteriorOutsideWallIssue,
      },
      {
        label: 'Other',
        value: MaintenanceSubType.GardenPropertyExteriorOther,
      },
    ],
  },
  {
    label: 'Other',
    description: 'Issue not listed? Select this option',
    category: MaintenanceCategory.Other,
    icon: IconHomeQuestion,
    subTypes: [
      {
        label: 'Smell',
        value: MaintenanceSubType.OtherSmell,
      },
      {
        label: 'Damp',
        value: MaintenanceSubType.OtherDamp,
      },
      {
        label: 'Pests (mice, rats)',
        value: MaintenanceSubType.OtherPestsMiceRats,
      },
      {
        label: 'Wasp',
        value: MaintenanceSubType.OtherWasp,
      },
      {
        label: 'Other',
        value: MaintenanceSubType.OtherOther,
      },
    ],
  },
];
