export interface PropertiesResponse {
  properties: PropertySummary[];
}

export interface PropertySummary {
  lifecycleId: number;
  teamId: string;
  addressLine1: string;
  addressLine2: string;
  agentLogo: string;
  daysOnMarket: number;
  lifecycleStatus: string;
  marketingPrice: number;
  marketingStatus: string;
  numberOfOffers: number;
  personId: number;
  priceQualifier: string;
  upcomingViewings: number;
  propertyImage: string;
}

export interface PropertySale {
  buyerName: string;
  progressingAgentName: string;
  progressingAgentThumbnail: string;
  progressingAgentInitials: string;
  progressingAgentEmail: string;
  progressingAgentPhoneNumber: string;
  sellerSalesProgression: PropertySaleChain;
  buyerSalesProgression: PropertySaleChain;
}

export interface PropertySaleChain {
  sellerName: string;
  lastChainUpdateDate: string;
  lastNotesUpdateDate: string;
  progressingAgentInitials: string;
  progressingAgentEmail: string;
  progressingAgentPhoneNumber: string;
  mortgageValuationDate: string;
  surveyDate: string;
  draftContractIssuedDate: string;
  searchesRequestedDate: string;
  searchedReceivedDate: string;
  mortgageOfferReceivedDate: string;
  sellerSignedContractDate: string;
  buyerSignedContractDate: string;
  exchangedDate: string;
  completedDate: string;
  mortgageValuationAtRisk: boolean;
  surveyAtRisk: boolean;
  draftContractIssuedAtRisk: boolean;
  searchesRequestedAtRisk: boolean;
  searchedReceivedAtRisk: boolean;
  mortgageOfferReceivedAtRisk: boolean;
  sellerSignedContractAtRisk: boolean;
  buyerSignedContractAtRisk: boolean;
  exchangedAtRisk: boolean;
  completedAtRisk: boolean;
  completionPercentage: number;
  lastUpdateDate: string;
  notes: SalesNote[];
}

export interface SalesNote {
  creatingAgentName: string;
  dateCreated: string;
  noteText: string;
  noteType: number;
  type: SalesMilestoneType;
}

export interface PropertyMarketingChannel {
  channel: number;
  dateListed: string;
  enquiries: number;
  listingURL: string;
}

export interface PropertyMarketingStatSegment {
  label: string;
  count: string;
  part: number;
  color: string;
}

export enum SalesMilestoneType {
  General = -1,
  MortgageValuation = 1,
  Survey = 2,
  DraftContractIssued = 3,
  SearchesRequested = 4,
  SearchesReceived = 5,
  MortgageOfferReceived = 6,
  SellerSignedContract = 7,
  BuyerSignedContract = 8,
  Exchanged = 9,
  Completed = 10,
}

export const SalesMilestoneTypes = {
  [SalesMilestoneType.General]: '',
  [SalesMilestoneType.MortgageValuation]: 'Mortgage Valuation',
  [SalesMilestoneType.Survey]: 'Survey',
  [SalesMilestoneType.DraftContractIssued]: 'Draft Contract Issued',
  [SalesMilestoneType.SearchesRequested]: 'Searches Requested',
  [SalesMilestoneType.SearchesReceived]: 'Searches Received',
  [SalesMilestoneType.MortgageOfferReceived]: 'Mortgage Offer Received',
  [SalesMilestoneType.SellerSignedContract]: 'Seller Signed Contract',
  [SalesMilestoneType.BuyerSignedContract]: 'Buyer Signed Contract',
  [SalesMilestoneType.Exchanged]: 'Exchanged',
  [SalesMilestoneType.Completed]: 'Completed',
};
