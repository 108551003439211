import { Accordion, Center, Group, Loader, Title, Text } from '@mantine/core';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { LandlordPropertyTenancy, TenancyStatusLabels } from '@/models/landlord/tenancy.model';
import httpClient from '@/utils/httpClient';
import classes from './LandlordPropertyTenancies.module.css';
import { LandlordPropertyTenancyContent } from '@/components/Landlord/LandlordPropertyTenancies/LandlordPropertyTenancyContent';

function useTenanciesData(propertyId: number): UseQueryResult<LandlordPropertyTenancy[], Error> {
  return useQuery({
    queryKey: ['landlord', 'tenancies', propertyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/landlord/${propertyId}/tenancies`);
      return data;
    },
    enabled: !!propertyId,
  });
}

interface LandlordPropertyTenanciesProps {
  propertyId: number;
  showInspections: boolean;
}

export function LandlordPropertyTenancies({
  propertyId,
  showInspections,
}: LandlordPropertyTenanciesProps) {
  const { data: tenancies, error, isPending } = useTenanciesData(propertyId);
  const [searchParams] = useSearchParams();

  if (isPending) {
    return (
      <Center>
        <Loader my="lg" size="lg" variant="bars" />
      </Center>
    );
  }

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }

  if (!tenancies || tenancies.length === 0) {
    return (
      <Title order={4} my="sm">
        This property is currently not listed anywhere
      </Title>
    );
  }

  const items = tenancies.map((tenancy) => (
    <Accordion.Item key={tenancy.id} value={String(tenancy.id)}>
      <Accordion.Control>
        <Group wrap="nowrap">
          <div>
            <Text>{tenancy.renters}</Text>
            <Text size="sm" c="dimmed" fw={400}>
              {TenancyStatusLabels[tenancy.status]}
            </Text>
          </div>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <LandlordPropertyTenancyContent tenancy={tenancy} showInspections={showInspections} />
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <>
      <Accordion
        maw="100%"
        my="sm"
        variant="contained"
        defaultValue={searchParams.get('t')}
        classNames={{ root: classes.root, item: classes.item, chevron: classes.chevron }}
      >
        {items}
      </Accordion>
    </>
  );
}
