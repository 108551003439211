import { Badge, Group, Paper, Stack, Table, Title, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { LandlordTenancyTerm } from '@/models/landlord/tenancy.model';
import { formatCurrency, formatDate, pluraliseWord } from '@/utils/stringFormat';
import { RentFrequencyToShortName } from '@/models/landlord/rental-property.model';
import {
  RentalLeaseTypeLabels,
  TenancyDurationTypeLabels,
} from '@/models/landlord/application.model';

function TenancyTableRow({ term, isActive }: { term: LandlordTenancyTerm; isActive: boolean }) {
  const { termDurationNumber, termDurationDays } = term;
  const termDurationType = TenancyDurationTypeLabels[term.termDurationType];
  const termDuration = `${termDurationNumber} ${pluraliseWord(termDurationType, termDurationNumber)}`;
  const additionalDays =
    termDurationDays > 0
      ? ` and ${termDurationDays} ${pluraliseWord('day', termDurationDays)}`
      : '';

  return (
    <Table.Tr>
      <Table.Td w={96}>
        {isActive && (
          <Badge variant="light" color="teal" size="lg">
            Active
          </Badge>
        )}
      </Table.Td>
      <Table.Td>
        {formatDate(term.startDate, 'DD/MM/YYYY')}
        {term.endDate && ` - ${formatDate(term.endDate, 'DD/MM/YYYY')}`}
        {termDurationNumber > 0 && ` (${termDuration}${additionalDays})`}
      </Table.Td>
      <Table.Td>{RentalLeaseTypeLabels[term.leaseType]}</Table.Td>
      <Table.Td>
        {formatCurrency(term.rent)} {RentFrequencyToShortName[term.rentFrequency]}
      </Table.Td>
    </Table.Tr>
  );
}

function TenancyMobileItem({ term, isActive }: { term: LandlordTenancyTerm; isActive: boolean }) {
  const { termDurationNumber, termDurationDays } = term;
  const termDurationType = TenancyDurationTypeLabels[term.termDurationType];
  const termDuration = `${termDurationNumber} ${pluraliseWord(termDurationType, termDurationNumber)}`;
  const additionalDays =
    termDurationDays > 0
      ? ` and ${termDurationDays} ${pluraliseWord('day', termDurationDays)}`
      : '';

  return (
    <Paper shadow="xs" p="md" withBorder bg={isActive ? 'teal.0' : ''}>
      <Stack gap={4}>
        {isActive && (
          <Badge variant="light" color="teal" size="lg">
            Active
          </Badge>
        )}
        <Group wrap="wrap" gap={0}>
          <Text fw="bold" mr={6}>
            Tenancy Period:
          </Text>
          <Text>
            {formatDate(term.startDate, 'DD/MM/YYYY')}
            {term.endDate && ` - ${formatDate(term.endDate, 'DD/MM/YYYY')}`}
            {termDurationNumber > 0 && ` (${termDuration}${additionalDays})`}
          </Text>
        </Group>
        <Group wrap="wrap" gap={0}>
          <Text fw="bold" mr={6}>
            Lease Type:
          </Text>
          <Text>{RentalLeaseTypeLabels[term.leaseType]}</Text>
        </Group>
        <Group wrap="wrap" gap={0}>
          <Text fw="bold" mr={6}>
            Rent:
          </Text>
          <Text>
            {formatCurrency(term.rent)} {RentFrequencyToShortName[term.rentFrequency]}
          </Text>
        </Group>
      </Stack>
    </Paper>
  );
}

interface LandlordPropertyTenancyTermsProps {
  activeTermId: number;
  terms: LandlordTenancyTerm[];
}

export default function LandlordPropertyTenancyTerms({
  activeTermId,
  terms,
}: LandlordPropertyTenancyTermsProps) {
  const isMobile = useMediaQuery('(max-width: 620px)');

  if (!terms || terms.length === 0) {
    return <Title order={4}>There are no terms on this property yet</Title>;
  }

  if (isMobile) {
    return (
      <Stack>
        {terms.map((term) => (
          <TenancyMobileItem term={term} isActive={term.id === activeTermId} />
        ))}
      </Stack>
    );
  }

  return (
    <Paper shadow="xs" withBorder>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Tenancy Period</Table.Th>
            <Table.Th>Lease Type</Table.Th>
            <Table.Th>Rent</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {terms.map((term) => (
            <TenancyTableRow key={term.id} term={term} isActive={term.id === activeTermId} />
          ))}
        </Table.Tbody>
      </Table>
    </Paper>
  );
}
