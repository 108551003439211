export interface Offer {
  offerId: number;
  offerStatus: OfferStatus;
  offerAmount: number;
  offerDate: string;
  marketingPrice: number;
  buyerName: string;
  financialPosition: string;
  sellingPosition: string;
  attendingAgentAvatar: string;
  attendingAgentEmail: string;
  attendingAgentName: string;
  attendingAgentPhone: string;
}

export enum OfferStatus {
  New = 0,
  SentToSeller,
  Accepted,
  Rejected,
  Withdrawn,
  FallenThrough,
}
