import { Checkbox, createTheme } from '@mantine/core';

export const theme = createTheme({
  /** Put your mantine theme override here */
  fontFamily: 'Inter, sans-serif',
  cursorType: 'pointer',
  components: {
    Checkbox: Checkbox.extend({
      styles: {
        label: { paddingLeft: '0.5rem' },
      },
    }),
  },
});
