export interface TenancyInspection {
  id: number;
  appointmentDate: string;
  status: TenancyInspectionStatus;
  rating: number;
  comments: string;
}

export enum TenancyInspectionStatus {
  NotBooked = 1,
  Booked = 2,
  Completed = 3,
}
