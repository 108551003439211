import { Text, Stack, Badge, Divider, Group, Title } from '@mantine/core';
import { formatDate } from '@/utils/stringFormat';
import { RentalViewing } from '@/models/landlord/rental-viewing.model';
import { ViewingOpinion } from '@/models/common.model';

interface PropertyViewingCardProps {
  viewing: RentalViewing;
  showFeedback: boolean;
  isListMode?: boolean;
}

export function LandlordPropertyViewingCard({
  viewing,
  showFeedback,
  isListMode,
}: PropertyViewingCardProps) {
  return (
    <Stack gap={0} align="center">
      {!isListMode && (
        <Badge variant="outline" size="lg" mb={8}>
          {viewing.status}
        </Badge>
      )}

      <Title order={3} fz={{ base: 18, md: 22 }}>
        {formatDate(viewing.dateOfAppointment, 'ddd Do MMMM YYYY')}
      </Title>
      <Title order={2} fz={{ base: 20, md: 26 }}>
        {formatDate(viewing.dateOfAppointment, 'h:mma')}
      </Title>
      {(isListMode || viewing.viewingType !== 'Unaccompanied') && (
        <Divider style={{ width: '100%' }} my={8} />
      )}
      {isListMode && (
        <Group gap={4}>
          <Text fw={600} c="dimmed">
            Renter:{' '}
          </Text>
          <Text c="dimmed">{viewing.renterName}</Text>
        </Group>
      )}
      {viewing.viewingType !== 'Unaccompanied' && (
        <Group gap={4}>
          <Text fw={600} c="dimmed">
            Viewing Agent:{' '}
          </Text>
          <Text c="dimmed">{viewing.attendingAgentName}</Text>
        </Group>
      )}

      {viewing.status === 'Unconfirmed' && viewing.viewingType === 'Accompanied' && (
        <Group mt={8} gap="xs" justify="center">
          {viewing.renterConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Renter Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Renter Unconfirmed
            </Badge>
          )}
          {viewing.landlordConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Landlord Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Landlord Unconfirmed
            </Badge>
          )}
          {viewing.agentConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Agent Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Agent Unconfirmed
            </Badge>
          )}
        </Group>
      )}
      {viewing.status === 'Unconfirmed' && viewing.viewingType === 'Unaccompanied' && (
        <Group mt={8} gap={4} justify="center">
          {viewing.renterConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Renter Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Renter Unconfirmed
            </Badge>
          )}
          {viewing.landlordConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Landlord Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Landlord Unconfirmed
            </Badge>
          )}
        </Group>
      )}
      {viewing.status === 'Unconfirmed' && viewing.viewingType === 'Virtual' && (
        <Group mt={8} gap={4} justify="center">
          {viewing.renterConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Renter Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Renter Unconfirmed
            </Badge>
          )}
          {viewing.agentConfirmed ? (
            <Badge variant="filled" color="green" size="sm">
              Agent Confirmed
            </Badge>
          ) : (
            <Badge variant="filled" color="red" size="sm">
              Agent Unconfirmed
            </Badge>
          )}
        </Group>
      )}

      {isListMode && (
        <>
          {viewing.status === 'Past' && (
            <Group mt={8} gap="xs">
              {viewing.didntTurnUp && (
                <Badge variant="light" color="red">
                  Didn&apos;t turn up
                </Badge>
              )}
              {viewing.renterOpinion === ViewingOpinion.Interested && (
                <Badge variant="light" color="blue">
                  Interested
                </Badge>
              )}
              {viewing.renterOpinion === ViewingOpinion.NotInterested && (
                <Badge variant="light" color="red">
                  Not Interested
                </Badge>
              )}
              {showFeedback && (
                <>
                  {viewing.renterFeedback ? (
                    <Badge variant="light" color="teal">
                      Renter Feedback
                    </Badge>
                  ) : (
                    <Badge color="gray" variant="outline">
                      No Feedback
                    </Badge>
                  )}
                </>
              )}
            </Group>
          )}
        </>
      )}
    </Stack>
  );
}
