import { createBrowserRouter, Navigate, RouterProvider, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { SellingDashboardPage } from '@/pages/Selling/SellingDashboard.page';
import { LandlordDashboardPage } from '@/pages/Landlord/LandlordDashboard.page';
import App from '@/App';
import { SellingPropertyPage } from '@/pages/Selling/SellingProperty.page';
import LoginPage from '@/pages/Auth/Login/Login.page';
import NotFoundPage from '@/pages/Error/NotFound.page';
import ResetPasswordPage from '@/pages/Auth/ResetPassword/ResetPassword.page';
import ForgotPasswordPage from '@/pages/Auth/ForgotPassword/ForgotPassword.Page';
import RegisterPage from '@/pages/Auth/Register/Register.page';
import { TenantDashboardPage } from '@/pages/Tenant/TenantDashboard.page';
import { HomePage } from '@/pages/Home/Home.page';
import { TenantTenancyPage } from '@/pages/Tenant/TenantTenancy.page';
import { LandlordPropertyPage } from '@/pages/Landlord/LandlordProperty.page';

const PrivateRoutes = () => {
  const location = useLocation();
  const isAuthenticated = Cookies.get('_auth') ? true : !!Cookies.get('_auth_refresh');

  return isAuthenticated ? (
    <App />
  ) : (
    <Navigate to="/auth/login" replace state={{ from: location }} />
  );
};

const router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    element: <PrivateRoutes />,
    errorElement: <NotFoundPage />,
    children: [
      {
        index: true,
        Component: HomePage,
      },
      {
        path: 'selling',
        Component: SellingDashboardPage,
      },
      {
        path: 'selling/property/:propertyId/:activeTab?',
        Component: SellingPropertyPage,
      },
      {
        path: 'landlord',
        Component: LandlordDashboardPage,
      },
      {
        path: 'landlord/property/:propertyId/:activeTab?',
        Component: LandlordPropertyPage,
      },
      {
        path: 'tenant',
        Component: TenantDashboardPage,
      },
      {
        path: 'tenant/tenancy/:tenancyId/:activeTab?',
        Component: TenantTenancyPage,
      },
    ],
  },
  {
    path: 'auth/login',
    Component: LoginPage,
  },
  {
    path: 'auth/forgot-password',
    Component: ForgotPasswordPage,
  },
  {
    path: 'auth/reset-password',
    Component: ResetPasswordPage,
  },
  {
    path: 'auth/register',
    Component: RegisterPage,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);

export default router;

export function Router() {
  return <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />;
}
