import { useEffect, useState } from 'react';
import {
  Title,
  Paper,
  Table,
  Badge,
  Alert,
  Drawer,
  Stack,
  Text,
  Center,
  Loader,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconAlertCircle } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import classes from './SellingPropertyOffers.module.css';
import { Offer, OfferStatus } from '@/models/selling/offers.model';
import { formatCurrency, formatDateTime, getInitials } from '@/utils/stringFormat';
import { SellingPropertyOfferCard } from '@/components/Selling/SellingPropertyOffers/SellingPropertyOfferCard/SellingPropertyOfferCard';
import { AgentDetailsCard } from '@/components/Common/AgentDetailsCard/AgentDetailsCard';
import httpClient from '@/utils/httpClient';

interface PropertyOffersProps {
  propertyId: number;
}

function useOffersData(propertyId: number) {
  return useQuery({
    queryKey: ['selling', 'offers', propertyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/properties/${propertyId}/offers`);
      return data;
    },
    enabled: !!propertyId,
  });
}

export function SellingPropertyOffers({ propertyId }: PropertyOffersProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const [activeOffer, setActiveOffer] = useState<Offer | null>(null);
  const isMobile = useMediaQuery('(max-width: 800px)');
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, error, isPending } = useOffersData(propertyId);

  const onClickOffer = (offer: Offer) => {
    setActiveOffer(offer);
    open();
    const newParams = new URLSearchParams({ o: String(offer.offerId) });
    setSearchParams(newParams);
  };

  const onCloseOffer = () => {
    close();
    setSearchParams(undefined);
  };

  useEffect(() => {
    const deepLinkId = searchParams.get('o');
    if (deepLinkId && data) {
      const offer = data.offers.find((o: Offer) => o.offerId === Number(deepLinkId));
      if (offer) {
        onClickOffer(offer);
      }
    } else if (opened) {
      close();
    }
  }, [searchParams, data]);

  if (isPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }

  const rows = data.offers.map((row: Offer) => (
    <Table.Tr key={row.offerId} className={classes.tableRow} onClick={() => onClickOffer(row)}>
      <Table.Td>
        {row.offerStatus === OfferStatus.New && (
          <Badge variant="light" size="lg">
            New
          </Badge>
        )}
        {row.offerStatus === OfferStatus.SentToSeller && <Badge size="lg">New</Badge>}
        {row.offerStatus === OfferStatus.Accepted && (
          <Badge variant="light" color="green" size="lg">
            Accepted
          </Badge>
        )}
        {row.offerStatus === OfferStatus.Rejected && (
          <Badge variant="light" color="red" size="lg">
            Rejected
          </Badge>
        )}
        {row.offerStatus === OfferStatus.Withdrawn && (
          <Badge variant="light" color="red" size="lg">
            Withdrawn
          </Badge>
        )}
        {row.offerStatus === OfferStatus.FallenThrough && (
          <Badge variant="light" color="red" size="lg">
            Fallen Through
          </Badge>
        )}
      </Table.Td>
      <Table.Td>{formatCurrency(row.offerAmount)}</Table.Td>
      <Table.Td>{row.buyerName}</Table.Td>
      <Table.Td>{formatDateTime(row.offerDate)}</Table.Td>
    </Table.Tr>
  ));

  const mobileOffers = data.offers.map((offer: Offer) => (
    <Paper
      key={offer.offerId}
      shadow="xs"
      p="md"
      onClick={() => onClickOffer(offer)}
      className={classes.offerCard}
    >
      <SellingPropertyOfferCard offer={offer} />
    </Paper>
  ));

  return (
    <>
      <Drawer size="sm" opened={opened} onClose={onCloseOffer} title="Offer" position="right">
        {activeOffer && (
          <Stack>
            <Paper p="md" withBorder>
              <SellingPropertyOfferCard offer={activeOffer} />
            </Paper>

            <Paper p="md" withBorder>
              <Stack gap={0}>
                <Title order={4}>Buyer Details</Title>

                <Title order={5} mt="sm">
                  Name
                </Title>
                <Text>{activeOffer.buyerName}</Text>

                {activeOffer.sellingPosition && (
                  <>
                    <Title order={5} mt="sm">
                      Selling Position
                    </Title>
                    <Text>{activeOffer.sellingPosition}</Text>
                  </>
                )}

                {activeOffer.financialPosition && (
                  <>
                    <Title order={5} mt="sm">
                      Financial Position
                    </Title>
                    <Text>{activeOffer.financialPosition}</Text>
                  </>
                )}
              </Stack>
            </Paper>

            {activeOffer.attendingAgentEmail && (
              <AgentDetailsCard
                data={{
                  label: 'Negotiator',
                  name: activeOffer.attendingAgentName,
                  phone: activeOffer.attendingAgentPhone,
                  email: activeOffer.attendingAgentEmail,
                  avatar: activeOffer.attendingAgentAvatar,
                  initials: getInitials(activeOffer.attendingAgentName),
                }}
              />
            )}
          </Stack>
        )}
      </Drawer>

      {data && data.offers.length > 0 ? (
        <>
          <Alert icon={<IconAlertCircle size="1rem" />} my="sm">
            Please contact us to discuss any offers you have received.
          </Alert>

          {!isMobile && (
            <Paper shadow="xs">
              <Title order={3} mb="xs" px="md" pt="md">
                Offers
              </Title>

              <Table highlightOnHover>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th />
                    <Table.Th>Amount</Table.Th>
                    <Table.Th>Offered By</Table.Th>
                    <Table.Th>Date Received</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
              </Table>
            </Paper>
          )}

          {isMobile && (
            <Center>
              <Stack style={{ width: '420px', maxWidth: '100%' }}>{mobileOffers}</Stack>
            </Center>
          )}
        </>
      ) : (
        <Title order={4} my="sm">
          There are no offers for this property yet
        </Title>
      )}
    </>
  );
}
