import { useEffect, useState } from 'react';
import { Title, Paper, Table, Badge, Alert, Drawer, Stack, Center, Loader } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconAlertCircle } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import classes from './LandlordPropertyApplications.module.css';
import { formatCurrency, formatDateTime } from '@/utils/stringFormat';
import httpClient from '@/utils/httpClient';
import { Application, ApplicationStatus } from '@/models/landlord/application.model';
import { LandlordPropertyApplicationCard } from '@/components/Landlord/LandlordPropertyApplications/LandlordPropertyApplicationCard/LandlordPropertyApplicationCard';
import { RentFrequencyToShortName } from '@/models/landlord/rental-property.model';
import { LandlordPropertyApplicationDrawer } from '@/components/Landlord/LandlordPropertyApplications/LandlordPropertyApplicationDrawer/LandlordPropertyApplicationDrawer';

function useApplicationsData(propertyId: number): UseQueryResult<Application[], Error> {
  return useQuery({
    queryKey: ['landlord', 'applications', propertyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/landlord/${propertyId}/applications`);
      return data;
    },
    enabled: !!propertyId,
  });
}

interface LandlordPropertyApplicationsProps {
  propertyId: number;
}

export function LandlordPropertyApplications({ propertyId }: LandlordPropertyApplicationsProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const [activeApplication, setActiveApplication] = useState<Application | null>(null);
  const isMobile = useMediaQuery('(max-width: 800px)');
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, error, isPending } = useApplicationsData(propertyId);

  const onClickApplication = (application: Application) => {
    setActiveApplication(application);
    open();
    const newParams = new URLSearchParams({ a: String(application.id) });
    setSearchParams(newParams);
  };

  const onCloseApplication = () => {
    close();
    setSearchParams(undefined);
  };

  useEffect(() => {
    const deepLinkId = searchParams.get('a');
    if (deepLinkId && data) {
      const application = data.find((a: Application) => a.id === Number(deepLinkId));
      if (application) {
        onClickApplication(application);
      }
    } else if (opened) {
      close();
    }
  }, [searchParams, data]);

  if (isPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }

  const rows = data.map((row: Application) => (
    <Table.Tr key={row.id} className={classes.tableRow} onClick={() => onClickApplication(row)}>
      <Table.Td>
        {row.status === ApplicationStatus.NewApplication && (
          <Badge variant="light" size="lg">
            New
          </Badge>
        )}
        {row.status === ApplicationStatus.Accepted && (
          <Badge variant="light" color="green" size="lg">
            Accepted
          </Badge>
        )}
        {row.status === ApplicationStatus.HoldingDepositPaid && (
          <Badge variant="light" color="teal" size="lg">
            Holding Deposit Paid
          </Badge>
        )}
        {row.status === ApplicationStatus.Rejected && (
          <Badge variant="light" color="red" size="lg">
            Rejected
          </Badge>
        )}
        {row.status === ApplicationStatus.Withdrawn && (
          <Badge variant="light" color="red" size="lg">
            Withdrawn
          </Badge>
        )}
      </Table.Td>
      <Table.Td>
        {formatCurrency(row.rentOffered)} {RentFrequencyToShortName[row.rentFrequency]}
      </Table.Td>
      <Table.Td>{row.renterNames}</Table.Td>
      <Table.Td>{formatDateTime(row.dateApplied)}</Table.Td>
    </Table.Tr>
  ));

  const mobileApplications = data.map((application: Application) => (
    <Paper
      key={application.id}
      shadow="xs"
      p="md"
      onClick={() => onClickApplication(application)}
      className={classes.applicationCard}
    >
      <LandlordPropertyApplicationCard application={application} />
    </Paper>
  ));

  return (
    <>
      <Drawer
        size="sm"
        opened={opened}
        onClose={onCloseApplication}
        title="Application"
        position="right"
      >
        {activeApplication && <LandlordPropertyApplicationDrawer application={activeApplication} />}
      </Drawer>

      {data && data.length > 0 ? (
        <>
          <Alert icon={<IconAlertCircle size="1rem" />} my="sm">
            Please contact us to discuss any applications you have received.
          </Alert>

          {isMobile ? (
            <Center>
              <Stack style={{ width: '420px', maxWidth: '100%' }}>{mobileApplications}</Stack>
            </Center>
          ) : (
            <Paper shadow="xs">
              <Title order={3} mb="xs" px="md" pt="md">
                Applications
              </Title>

              <Table highlightOnHover>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th />
                    <Table.Th>Amount</Table.Th>
                    <Table.Th>Applicant</Table.Th>
                    <Table.Th>Date Received</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
              </Table>
            </Paper>
          )}
        </>
      ) : (
        <Title order={4} my="sm">
          There are no applications for this property yet
        </Title>
      )}
    </>
  );
}
