import { Text, Stack, Title, Loader, Center, Box, SegmentedControl } from '@mantine/core';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useState } from 'react';
import httpClient from '@/utils/httpClient';
import { LandlordDashboardData } from '@/models/landlord/rental-property.model';
import { LandlordProperties } from '@/components/Landlord/LandlordDashboardContent/LandlordProperties/LandlordProperties';
import { LandlordTenancies } from '@/components/Landlord/LandlordDashboardContent/LandlordTenancies/LandlordTenancies';

function useLandlordDashboard(): UseQueryResult<LandlordDashboardData, Error> {
  return useQuery({
    queryKey: ['landlord', 'dashboard'],
    queryFn: async () => {
      const { data } = await httpClient.get('/landlord');
      return data;
    },
  });
}

export function LandlordDashboardContent() {
  const [selectedSegment, setSelectedSegment] = useState('properties');
  const { data, error, isPending } = useLandlordDashboard();

  if (isPending) {
    return (
      <Center>
        <Loader size="lg" />
      </Center>
    );
  }

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }

  if (!data || !data.properties || data.properties.length === 0) {
    return (
      <Stack gap={0} align="center">
        <Title order={4}>No Properties are linked to your account yet.</Title>
        <Text>Properties shared by your estate agent will appear here.</Text>
      </Stack>
    );
  }

  return (
    <Box>
      <Center mb="lg">
        <SegmentedControl
          size="xl"
          value={selectedSegment}
          onChange={setSelectedSegment}
          data={[
            { label: 'Properties', value: 'properties' },
            { label: 'Tenancies', value: 'tenancies' },
          ]}
        />
      </Center>

      {selectedSegment === 'properties' ? (
        <LandlordProperties properties={data.properties} />
      ) : (
        <LandlordTenancies tenancies={data.tenancies} />
      )}
    </Box>
  );
}
