import {
  Paper,
  Title,
  Text,
  TextInput,
  Button,
  Container,
  Group,
  Anchor,
  Center,
  Box,
  Loader,
  Alert,
  rem,
} from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import React, { FormEventHandler, useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './ForgotPassword.module.css';
import httpClient from '@/utils/httpClient';

export default function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [emailInfo, setEmailInfo] = useState({
    email: '',
  });

  const submitRequest: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setLoading(true);

    httpClient
      .post('/users/password-reset-request', {
        email: emailInfo.email,
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setSubmitted(true);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  return (
    <Container size={460} my={80}>
      <Title className={classes.title} ta="center">
        Forgot your password?
      </Title>
      <Text c="dimmed" fz="sm" ta="center">
        Enter your email to get a reset link
      </Text>

      <Paper withBorder shadow="md" p={30} radius="md" mt="md">
        <form onSubmit={submitRequest}>
          {submitted ? (
            <Alert color="green" className={classes.alert}>
              You will receive a reset link shortly if the email you entered is associated with an
              account.
            </Alert>
          ) : (
            <TextInput
              label="Your email"
              placeholder="me@gmail.com"
              required
              value={emailInfo.email}
              type="email"
              onChange={({ target }) =>
                setEmailInfo({
                  ...emailInfo,
                  email: target.value,
                })
              }
            />
          )}

          <Group justify="space-between" mt="lg" className={classes.controls}>
            <Anchor
              c="dimmed"
              size="sm"
              className={classes.control}
              component={Link}
              to="/auth/login"
            >
              <Center inline>
                <IconArrowLeft style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                <Box ml={5}>Back to the login page</Box>
              </Center>
            </Anchor>
            {!submitted && (
              <Button type="submit" className={classes.control}>
                {loading ? <Loader color="white" size="xs" variant="bars" /> : 'Reset password'}
              </Button>
            )}
          </Group>
        </form>
      </Paper>
    </Container>
  );
}
