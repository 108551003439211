import { useState, useEffect } from 'react';
import { Container, Tabs, Paper, Breadcrumbs, Anchor, Center, Loader, Title } from '@mantine/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import httpClient from '@/utils/httpClient';
import { TenancyHeader } from '@/components/Tenant/TenancyHeader/TenancyHeader';
import { TenancyData, TenancySettings } from '@/models/tenant/tenancy.model';
import { TenancyDetails } from '@/components/Tenant/TenancyDetails/TenancyDetails';
import { TenancyInspections } from '@/components/Tenant/TenancyInspections/TenancyInspections';
import { TenancyCertificates } from '@/components/Tenant/TenancyCertificates/TenancyCertificates';
import { TenancyMaintenance } from '@/components/Tenant/TenancyMaintenance/TenancyMaintenance';

const breadcrumbs = [
  { title: 'Home', href: '/tenant' },
  { title: 'Tenancy', href: '#' },
].map((item, index) => (
  <Anchor key={index} to={item.href} component={Link}>
    {item.title}
  </Anchor>
));

function useTenancySettings(tenancyId: string | undefined): UseQueryResult<TenancySettings, Error> {
  return useQuery({
    queryKey: ['tenancy', 'settings', tenancyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/tenancies/${tenancyId}/settings`);
      return data;
    },
    enabled: !!tenancyId,
  });
}

function useTenancyDetails(tenancyId: string | undefined): UseQueryResult<TenancyData, Error> {
  return useQuery({
    queryKey: ['tenancy', 'details', tenancyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/tenancies/${tenancyId}/details`);
      return data;
    },
    enabled: !!tenancyId,
  });
}

export function TenantTenancyPage() {
  const { tenancyId, activeTab } = useParams();
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const navigate = useNavigate();
  const {
    data: settings,
    error: settingsError,
    isPending: isSettingsPending,
  } = useTenancySettings(tenancyId);
  const {
    data: tenancyDetails,
    error: detailsError,
    isPending: isDetailsPending,
  } = useTenancyDetails(tenancyId);

  useEffect(() => {
    if (settings) {
      setActiveTab(activeTab || null);
    }
  }, [activeTab, settings]);

  const setActiveTab = (tab: string | null) => {
    if (tab === 'details' || tab === null) {
      setSelectedTab('details');
    } else if (tab === 'inspections' && settings?.hasInspections) {
      setSelectedTab('inspections');
    } else if (tab === 'certificates' && settings?.hasCertificates) {
      setSelectedTab('certificates');
    } else if (tab === 'maintenance' && settings?.hasMaintenance) {
      setSelectedTab('maintenance');
    } else {
      setSelectedTab('details');
    }
  };

  const changeTab = (value: string | null) => {
    navigate(`/tenant/tenancy/${tenancyId}/${value}`);
  };

  if (settingsError || detailsError) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {settingsError?.message || detailsError?.message}
      </Title>
    );
  }

  if (!settings || isSettingsPending || isDetailsPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Container p={0}>
      <Breadcrumbs mb="md">{breadcrumbs}</Breadcrumbs>

      <TenancyHeader headerData={tenancyDetails} />

      <Tabs value={selectedTab} onChange={(value) => changeTab(value)}>
        <Paper shadow="xs" mt="lg">
          <Tabs.List>
            <Tabs.Tab value="details">Details</Tabs.Tab>
            {settings.hasInspections && <Tabs.Tab value="inspections">Inspections</Tabs.Tab>}
            {settings.hasCertificates && <Tabs.Tab value="certificates">Certificates</Tabs.Tab>}
            {settings.hasMaintenance && <Tabs.Tab value="maintenance">Maintenance</Tabs.Tab>}
          </Tabs.List>
        </Paper>

        <Tabs.Panel value="details" pt="xs">
          <TenancyDetails tenancyDetails={tenancyDetails} />
        </Tabs.Panel>

        {settings.hasInspections && (
          <Tabs.Panel value="inspections" pt="xs">
            <TenancyInspections
              tenancyId={Number(tenancyId)}
              showFeedback={settings.hasInspectionFeedback}
            />
          </Tabs.Panel>
        )}

        {settings.hasCertificates && (
          <Tabs.Panel value="certificates" pt="xs">
            <TenancyCertificates tenancyId={Number(tenancyId)} />
          </Tabs.Panel>
        )}

        {settings.hasMaintenance && (
          <Tabs.Panel value="maintenance" pt="xs">
            <TenancyMaintenance
              tenancyId={Number(tenancyId)}
              allowRaiseMaintenance={settings.allowRaiseMaintenance}
            />
          </Tabs.Panel>
        )}
      </Tabs>
    </Container>
  );
}
