import { Container } from '@mantine/core';
import { DashboardHero } from '@/components/Common/DashboardHero/DashboardHero';
import { TenanciesList } from '@/components/Tenant/TenanciesList/TenanciesList';

export function TenantDashboardPage() {
  return (
    <Container p={0}>
      <DashboardHero />

      <TenanciesList />
    </Container>
  );
}
