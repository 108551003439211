import { Text, Paper, Center, Avatar, Stack, Group, Anchor } from '@mantine/core';
import { IconInbox, IconPhone } from '@tabler/icons-react';

interface AgentDetailsCardProps {
  data: {
    label: string;
    name: string;
    phone: string;
    email: string;
    avatar: string;
    initials: string;
  };
}

export function AgentDetailsCard({ data }: AgentDetailsCardProps) {
  return (
    <Paper p="md" withBorder mt={20}>
      <Center>
        <Avatar size={50} mt={-40} radius="xl" variant="filled" src={data.avatar} alt={data.name}>
          {data.initials}
        </Avatar>
      </Center>

      <Stack gap={4} mt={6}>
        <Group gap={6}>
          {data.label && <Text fw={600}>{data.label}:</Text>}
          <Text>{data.name}</Text>
        </Group>

        {data.phone && (
          <Group gap={8}>
            <IconPhone stroke={1.5} />
            <Anchor href={`tel:${data.phone}`} target="_blank">
              {data.phone}
            </Anchor>
          </Group>
        )}

        {data.email && (
          <Group gap={8}>
            <IconInbox stroke={1.5} />
            <Anchor href={`mailto:${data.email}`} target="_blank">
              {data.email}
            </Anchor>
          </Group>
        )}
      </Stack>
    </Paper>
  );
}
