import { SegmentedControl, Center, Box, useMantineColorScheme } from '@mantine/core';
import { IconMoonStars, IconSun } from '@tabler/icons-react';

export function ColorSchemeToggle() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <SegmentedControl
      m="xs"
      fullWidth
      defaultValue={colorScheme}
      onChange={() => toggleColorScheme()}
      data={[
        {
          value: 'light',
          label: (
            <Center>
              <IconSun size="1rem" />
              <Box ml={10}>Light</Box>
            </Center>
          ),
        },
        {
          value: 'dark',
          label: (
            <Center>
              <IconMoonStars size="1rem" />
              <Box ml={10}>Dark</Box>
            </Center>
          ),
        },
      ]}
    />
  );
}
