import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

/**
 * Formats a number as a currency string in GBP (£).
 *
 * @param {number} value - The number to be formatted as currency.
 * @param {boolean} [removeDecimal=true] - A flag indicating whether to remove the decimal part of the currency. Defaults to true if not provided.
 * @returns {string} The formatted currency string. If `removeDecimal` is true, the '.00' part is removed from the result.
 */
export const formatCurrency = (value: number, removeDecimal = true) => {
  if (removeDecimal) {
    return value
      ?.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
      })
      .replace('.00', '');
  }
  return value?.toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });
};

export const formatShortDate = (dateTime: string, format?: string) =>
  dayjs(dateTime).format(format || 'ddd Do MMM YYYY');

export const formatDate = (dateTime: string, format?: string) =>
  dayjs(dateTime).format(format || 'dddd Do MMMM YYYY');

export const formatDateTime = (dateTime: string, format?: string) =>
  dayjs(dateTime).format(format || 'dddd Do MMMM YYYY h:mma');

export const formatShortDateTime = (dateTime: string, format?: string) =>
  dayjs(dateTime).format(format || 'ddd Do MMM YYYY h:mma');

export const formatTime = (dateTime: string, format?: string) =>
  dayjs(dateTime).format(format || 'h:mma');

export const timeFromNow = (dateTime: string) => dayjs(dateTime).fromNow();

export const getGreetingMessage = (): string => {
  const hour = dayjs().hour();
  if (hour >= 0 && hour < 12) {
    return 'Good morning';
  }
  if (hour >= 12 && hour < 18) {
    return 'Good afternoon';
  }
  return 'Good evening';
};

/**
 * Extracts the initials from a given name.
 *
 * @param {string} name - The full name from which to extract initials. The name should be in the format 'First Last'. If the name has more than two parts, only the first and last parts are considered.
 * @returns {string} The initials of the given name. The initials are in uppercase. If the name has only one part, the initial of that part is returned. If the name has two or more parts, the initial of the first and last parts are returned.
 */
export const getInitials = (name: string) => {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const pluraliseWord = (word: string, amount: number) => word + (amount === 1 ? '' : 's');

export const getNumberSuffix = (number: number) => {
  switch (number) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
