import {
  Paper,
  Title,
  Center,
  Loader,
  Table,
  Badge,
  Stack,
  Rating,
  Group,
  Text,
} from '@mantine/core';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMediaQuery } from '@mantine/hooks';
import { TenancyInspection, TenancyInspectionStatus } from '@/models/tenant/inspections.model';
import { formatDateTime } from '@/utils/stringFormat';
import httpClient from '@/utils/httpClient';

function useInspections(tenancyId: number): UseQueryResult<TenancyInspection[], Error> {
  return useQuery({
    queryKey: ['tenancy', 'inspections', tenancyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/tenancies/${tenancyId}/inspections`);
      return data;
    },
    enabled: !!tenancyId,
  });
}

interface TenancyInspectionsProps {
  tenancyId: number;
  showFeedback: boolean;
}

export function TenancyInspections({ tenancyId, showFeedback }: TenancyInspectionsProps) {
  const { data, error, isPending } = useInspections(tenancyId);
  const isMobile = useMediaQuery('(max-width: 800px)');

  if (isPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Title order={4} my="sm">
        There are no inspections on this property yet
      </Title>
    );
  }

  const rows = data.map((row: TenancyInspection) => (
    <Table.Tr key={row.id}>
      <Table.Td>
        {row.status === TenancyInspectionStatus.Booked && (
          <Badge variant="light" size="lg">
            Booked
          </Badge>
        )}
        {row.status === TenancyInspectionStatus.Completed && (
          <Badge variant="light" color="green" size="lg">
            Completed
          </Badge>
        )}
      </Table.Td>
      <Table.Td>{row.appointmentDate && formatDateTime(row.appointmentDate)}</Table.Td>
      {showFeedback && row.status === TenancyInspectionStatus.Completed && (
        <Table.Td>
          <Rating value={row.rating} size="md" readOnly />
        </Table.Td>
      )}
      {showFeedback && row.status === TenancyInspectionStatus.Completed && (
        <Table.Td>{row.comments}</Table.Td>
      )}
    </Table.Tr>
  ));

  const mobileInspections = data.map((inspection: TenancyInspection) => (
    <Paper key={inspection.id} shadow="xs" p="md">
      <Stack gap={4}>
        {inspection.status === TenancyInspectionStatus.Booked && (
          <Badge variant="light" size="lg">
            Booked
          </Badge>
        )}
        {inspection.status === TenancyInspectionStatus.Completed && (
          <Badge variant="light" color="green" size="lg">
            Completed
          </Badge>
        )}
        <Group wrap="wrap" gap={0}>
          <Text fw="bold" mr={6}>
            Inspection:
          </Text>
          <Text>{inspection.appointmentDate && formatDateTime(inspection.appointmentDate)}</Text>
        </Group>
        {showFeedback && inspection.status === TenancyInspectionStatus.Completed && (
          <Group wrap="wrap" gap={0}>
            <Text fw="bold" mr={6}>
              Rating:
            </Text>
            <Rating value={inspection.rating} readOnly />
          </Group>
        )}
        {showFeedback && inspection.status === TenancyInspectionStatus.Completed && (
          <Group wrap="wrap" gap={0}>
            <Text fw="bold" mr={6}>
              Comments:
            </Text>
            <Text>{inspection.comments}</Text>
          </Group>
        )}
      </Stack>
    </Paper>
  ));

  return (
    <>
      {!isMobile && (
        <Paper shadow="xs" my="sm">
          <Title order={3} mb="xs" px="md" pt="md">
            Inspections
          </Title>

          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th />
                <Table.Th>Date & Time</Table.Th>
                {showFeedback && <Table.Th>Rating</Table.Th>}
                {showFeedback && <Table.Th>Comments</Table.Th>}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </Paper>
      )}

      {isMobile && (
        <Stack mt="xs">
          <Title order={3}>Inspections</Title>

          <Center>
            <Stack w="100%">{mobileInspections}</Stack>
          </Center>
        </Stack>
      )}
    </>
  );
}
