import {
  Flex,
  Card,
  Image,
  Group,
  Text,
  Badge,
  Button,
  Stack,
  Title,
  Loader,
  Center,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconChevronRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import classes from './SellingProperties.module.css';
import { PropertiesResponse, PropertySummary } from '@/models/selling/property.model';
import { formatCurrency, pluraliseWord } from '@/utils/stringFormat';
import httpClient from '@/utils/httpClient';

function useProperties(): UseQueryResult<PropertiesResponse, Error> {
  return useQuery({
    queryKey: ['selling', 'dashboard'],
    queryFn: async () => {
      const { data } = await httpClient.get('/people/me/selling');
      return data;
    },
  });
}

export function SellingProperties() {
  const { data, error, isPending } = useProperties();
  const { hovered, ref } = useHover();

  if (isPending) {
    return (
      <Center>
        <Loader size="lg" />
      </Center>
    );
  }

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }

  if (!data || !data.properties || data.properties.length === 0) {
    return (
      <Stack gap={0} align="center">
        <Title order={4}>No Properties are linked to your account yet.</Title>
        <Text>Properties shared by your estate agent will appear here.</Text>
      </Stack>
    );
  }

  return (
    <Flex
      direction={{ base: 'row' }}
      gap={{ base: 'md' }}
      justify={{ base: 'center', xs: 'center', sm: 'flex-start' }}
      wrap="wrap"
    >
      {data.properties?.map((property: PropertySummary) => (
        <Link
          key={property.lifecycleId}
          to={`/selling/property/${property.lifecycleId}`}
          style={{ textDecoration: 'none' }}
        >
          <Card
            ref={ref}
            shadow={hovered ? 'lg' : 'sm'}
            padding="lg"
            className={classes.cardWrapper}
          >
            <Card.Section style={{ position: 'relative' }}>
              <Image
                src={property.propertyImage}
                height={156}
                fallbackSrc="https://placehold.co/156x156?text=No%20Image"
                alt="Property"
              />
              <Badge size="lg" radius={0} variant="filled" className={classes.statusBadge}>
                {property.lifecycleStatus}
              </Badge>
            </Card.Section>

            <Group justify="space-between" mt="md">
              <Text fw={500}>{property.addressLine1}</Text>
            </Group>

            <Text fw={500}>{property.addressLine2}</Text>

            <Text>
              {property.priceQualifier} {formatCurrency(property.marketingPrice)}
            </Text>

            <Group mt="xs" gap={8}>
              {property.upcomingViewings !== null && (
                <Badge variant="outline" color="violet">
                  {property.upcomingViewings} upcoming{' '}
                  {pluraliseWord('viewing', property.upcomingViewings)}
                </Badge>
              )}
              {property.numberOfOffers !== null && (
                <Badge variant="outline" color="grape">
                  {property.numberOfOffers} pending{' '}
                  {pluraliseWord('offer', property.numberOfOffers)}
                </Badge>
              )}
            </Group>

            <Button
              mt="sm"
              variant="subtle"
              mb={-8}
              size="compact-xs"
              fullWidth
              rightSection={<IconChevronRight size="1rem" />}
            >
              View Details
            </Button>
          </Card>
        </Link>
      ))}
    </Flex>
  );
}
