import { Badge, Divider, Grid, Group, Stack, Text } from '@mantine/core';
import { formatDate } from '@/utils/stringFormat';
import { RentalViewing } from '@/models/landlord/rental-viewing.model';
import { ViewingOpinion } from '@/models/common.model';

interface PropertyViewingItemProps {
  viewing: RentalViewing;
  showFeedback: boolean;
}

export function LandlordPropertyViewingItem({ viewing, showFeedback }: PropertyViewingItemProps) {
  return (
    <Grid>
      <Grid.Col span="content">
        <Group>
          <Stack gap={0} style={{ width: '154px' }}>
            <Text fw={600} style={{ lineHeight: 1.2 }}>
              {formatDate(viewing.dateOfAppointment, 'ddd Do MMMM')}
            </Text>
            <Text fw={600} style={{ lineHeight: 1.2 }}>
              {formatDate(viewing.dateOfAppointment, 'h:mma')}
            </Text>
            <Text size="sm" c="dimmed">
              {viewing.viewingType}
            </Text>
          </Stack>
          <Divider style={{ height: '60px' }} size="sm" orientation="vertical" />
        </Group>
      </Grid.Col>

      <Grid.Col span="auto">
        <Text fw={600}>{viewing.renterName}</Text>
        {viewing.viewingType !== 'Unaccompanied' && (
          <Text fz={14} c="dimmed">
            Viewing Agent: {viewing.attendingAgentName}
          </Text>
        )}
        {viewing.status === 'Unconfirmed' && viewing.viewingType === 'Accompanied' && (
          <Group mt={8} gap={4}>
            {viewing.renterConfirmed ? (
              <Badge variant="filled" color="green" size="sm">
                Renter Confirmed
              </Badge>
            ) : (
              <Badge variant="filled" color="red" size="sm">
                Renter Unconfirmed
              </Badge>
            )}
            {viewing.landlordConfirmed ? (
              <Badge variant="filled" color="green" size="sm">
                Landlord Confirmed
              </Badge>
            ) : (
              <Badge variant="filled" color="red" size="sm">
                Landlord Unconfirmed
              </Badge>
            )}
            {viewing.agentConfirmed ? (
              <Badge variant="filled" color="green" size="sm">
                Agent Confirmed
              </Badge>
            ) : (
              <Badge variant="filled" color="red" size="sm">
                Agent Unconfirmed
              </Badge>
            )}
          </Group>
        )}
        {viewing.status === 'Unconfirmed' && viewing.viewingType === 'Unaccompanied' && (
          <Group mt={8} gap={4}>
            {viewing.renterConfirmed ? (
              <Badge variant="filled" color="green" size="sm">
                Renter Confirmed
              </Badge>
            ) : (
              <Badge variant="filled" color="red" size="sm">
                Renter Unconfirmed
              </Badge>
            )}
            {viewing.landlordConfirmed ? (
              <Badge variant="filled" color="green" size="sm">
                Landlord Confirmed
              </Badge>
            ) : (
              <Badge variant="filled" color="red" size="sm">
                Landlord Unconfirmed
              </Badge>
            )}
          </Group>
        )}
        {viewing.status === 'Unconfirmed' && viewing.viewingType === 'Virtual' && (
          <Group mt={8} gap={4}>
            {viewing.renterConfirmed ? (
              <Badge variant="filled" color="green" size="sm">
                Renter Confirmed
              </Badge>
            ) : (
              <Badge variant="filled" color="red" size="sm">
                Renter Unconfirmed
              </Badge>
            )}
            {viewing.agentConfirmed ? (
              <Badge variant="filled" color="green" size="sm">
                Agent Confirmed
              </Badge>
            ) : (
              <Badge variant="filled" color="red" size="sm">
                Agent Unconfirmed
              </Badge>
            )}
          </Group>
        )}

        {viewing.status === 'Past' && (
          <Group mt={8} gap={4}>
            {viewing.didntTurnUp && (
              <Badge variant="light" color="red">
                Didn&apos;t turn up
              </Badge>
            )}
            {viewing.renterOpinion === ViewingOpinion.Interested && (
              <Badge variant="light" color="blue">
                Interested
              </Badge>
            )}
            {viewing.renterOpinion === ViewingOpinion.NotInterested && (
              <Badge variant="light" color="red">
                Not Interested
              </Badge>
            )}
            {showFeedback && (
              <>
                {viewing.renterFeedback ? (
                  <Badge variant="light" color="teal">
                    Renter Feedback
                  </Badge>
                ) : (
                  <Badge variant="outline" color="gray">
                    No Feedback
                  </Badge>
                )}
              </>
            )}
          </Group>
        )}
      </Grid.Col>
    </Grid>
  );
}
