import {
  Paper,
  Title,
  Stack,
  Text,
  Badge,
  Group,
  Image,
  Flex,
  Spoiler,
  Alert,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { IconHammer, IconUrgent } from '@tabler/icons-react';
import { useState } from 'react';
import { MaintenanceJob, MaintenanceStatus } from '@/models/tenant/maintenance.model';
import { formatDate } from '@/utils/stringFormat';
import classes from './TenancyMaintenance.module.css';

interface TenancyMaintenanceDrawerProps {
  issue: MaintenanceJob;
}

export function TenancyMaintenanceDrawer({ issue }: TenancyMaintenanceDrawerProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const slides = issue.photos?.map((url) => ({ src: url }));

  return (
    <>
      <Stack>
        {!issue.completed && issue.jobDate && (
          <Alert icon={<IconHammer />} p="xs">
            <Text lh={1.2}>
              {issue.contractorName || 'A contractor'} is scheduled to inspect/repair this issue on{' '}
              {formatDate(issue.jobDate)}
            </Text>
          </Alert>
        )}

        <Paper p="md" withBorder>
          <Stack gap="sm">
            <Group justify="space-between">
              <Title order={4}>Issue Details</Title>

              {issue.status === MaintenanceStatus.Cancelled ? (
                <Badge color="red">Cancelled</Badge>
              ) : issue.completed ? (
                <Badge color="green">Fixed</Badge>
              ) : (
                <Badge color="grey">Pending</Badge>
              )}
            </Group>

            {issue.emergency && (
              <Alert
                icon={<IconUrgent />}
                color="red"
                p="xs"
                title={
                  issue.completed
                    ? 'This issue was an emergency'
                    : 'This issue is marked as an emergency and will be attended to as soon as possible'
                }
              />
            )}

            <Stack gap={0}>
              <Title order={5}>Summary</Title>
              <Text>{issue.summary}</Text>
            </Stack>

            <Stack gap={0}>
              <Title order={5}>Details</Title>
              {issue.reportedByNotes ? (
                <Spoiler maxHeight={200} showLabel="Show more" hideLabel="Hide">
                  <Text>{issue.reportedByNotes}</Text>
                </Spoiler>
              ) : (
                <Text c="dimmed" fs="italic">
                  No details provided
                </Text>
              )}
            </Stack>

            <Text c="dimmed" size="sm">
              Reported on {formatDate(issue.dateReported)}
            </Text>
          </Stack>
        </Paper>

        <Paper p="md" withBorder>
          <Stack gap="sm">
            <Title order={4}>Images</Title>

            {issue.photos?.length > 0 ? (
              <Flex wrap="wrap" gap="sm">
                {issue.photos?.map((url, index) => (
                  <Image
                    key={index}
                    src={`${url}?width=140&height=105&aspect_ratio=140:105`}
                    w={140}
                    h={105}
                    fit="cover"
                    onClick={() => {
                      setSlideIndex(index);
                      open();
                    }}
                    className={classes.imageHover}
                  />
                ))}
              </Flex>
            ) : (
              <Text c="dimmed" fs="italic">
                No images provided
              </Text>
            )}
          </Stack>
        </Paper>
      </Stack>

      <Lightbox open={opened} close={() => close()} slides={slides} index={slideIndex} />
    </>
  );
}
