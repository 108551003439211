import { Container } from '@mantine/core';
import { DashboardHero } from '@/components/Common/DashboardHero/DashboardHero';
import { LandlordDashboardContent } from '@/components/Landlord/LandlordDashboardContent/LandlordDashboardContent';

export function LandlordDashboardPage() {
  return (
    <Container p={0}>
      <DashboardHero />

      <LandlordDashboardContent />
    </Container>
  );
}
