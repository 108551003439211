import { useEffect, useState } from 'react';
import {
  Paper,
  Title,
  Center,
  Stack,
  Text,
  Drawer,
  Group,
  Badge,
  Divider,
  HoverCard,
  ActionIcon,
  Spoiler,
  Loader,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconInfoCircle } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import classes from './LandlordPropertyViewings.module.css';
import { LandlordPropertyViewingItem } from '@/components/Landlord/LandlordPropertyViewings/LandlordPropertyViewingItem/LandlordPropertyViewingItem';
import { LandlordPropertyViewingCard } from '@/components/Landlord/LandlordPropertyViewings/LandlordPropertyViewingCard/LandlordPropertyViewingCard';
import { AgentDetailsCard } from '@/components/Common/AgentDetailsCard/AgentDetailsCard';
import { pluraliseWord, getNumberSuffix } from '@/utils/stringFormat';
import httpClient from '@/utils/httpClient';
import { RentalViewing, RentalViewingsWrapper } from '@/models/landlord/rental-viewing.model';
import { ViewingOpinion } from '@/models/common.model';

interface PropertyViewingsProps {
  propertyId: number;
  showFeedback: boolean;
}

function useViewingsData(propertyId: number): UseQueryResult<RentalViewingsWrapper, Error> {
  return useQuery({
    queryKey: ['landlord', 'viewings', propertyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/landlord/${propertyId}/viewings`);
      return data;
    },
    enabled: !!propertyId,
  });
}

export function LandlordPropertyViewings({ propertyId, showFeedback }: PropertyViewingsProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const [activeViewing, setActiveViewing] = useState<RentalViewing>();
  const isMobile = useMediaQuery('(max-width: 800px)');
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, error, isPending } = useViewingsData(propertyId);

  const onClickViewing = (viewing: RentalViewing) => {
    setActiveViewing(viewing);
    open();
    const newParams = new URLSearchParams({ v: String(viewing.id) });
    setSearchParams(newParams);
  };

  const onCloseViewing = () => {
    close();
    setSearchParams(undefined);
  };

  useEffect(() => {
    const deepLinkId = searchParams.get('v');
    if (deepLinkId && data) {
      const viewing = [...data.unconfirmed, ...data.upcoming, ...data.past, ...data.cancelled].find(
        (v) => v.id === Number(deepLinkId)
      );
      if (viewing) {
        onClickViewing(viewing);
      }
    } else if (opened) {
      close();
    }
  }, [searchParams, data]);

  if (isPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }

  const unconfirmedViewings = data.unconfirmed.map((viewing: RentalViewing) => (
    <Paper
      key={viewing.id}
      shadow="xs"
      withBorder
      p="xs"
      onClick={() => onClickViewing(viewing)}
      className={classes.hoverCard}
    >
      <LandlordPropertyViewingItem viewing={viewing} showFeedback={showFeedback} />
    </Paper>
  ));

  const upcomingViewings = data.upcoming.map((viewing: RentalViewing) => (
    <Paper
      key={viewing.id}
      shadow="xs"
      withBorder
      p="xs"
      onClick={() => onClickViewing(viewing)}
      className={classes.hoverCard}
    >
      <LandlordPropertyViewingItem viewing={viewing} showFeedback={showFeedback} />
    </Paper>
  ));

  const pastViewings = data.past.map((viewing: RentalViewing) => (
    <Paper
      key={viewing.id}
      shadow="xs"
      withBorder
      p="xs"
      onClick={() => onClickViewing(viewing)}
      className={classes.hoverCard}
    >
      <LandlordPropertyViewingItem viewing={viewing} showFeedback={showFeedback} />
    </Paper>
  ));

  const cancelledViewings = data.cancelled.map((viewing: RentalViewing) => (
    <Paper
      key={viewing.id}
      shadow="xs"
      withBorder
      p="xs"
      onClick={() => onClickViewing(viewing)}
      className={classes.hoverCard}
    >
      <LandlordPropertyViewingItem viewing={viewing} showFeedback={showFeedback} />
    </Paper>
  ));

  const mobileUnconfirmedViewings = data.unconfirmed.map((viewing: RentalViewing) => (
    <Paper
      key={viewing.id}
      shadow="xs"
      withBorder
      p="md"
      onClick={() => onClickViewing(viewing)}
      className={classes.hoverCard}
    >
      <LandlordPropertyViewingCard viewing={viewing} showFeedback={showFeedback} isListMode />
    </Paper>
  ));

  const mobileUpcomingViewings = data.upcoming.map((viewing: RentalViewing) => (
    <Paper
      key={viewing.id}
      shadow="xs"
      withBorder
      p="md"
      onClick={() => onClickViewing(viewing)}
      className={classes.hoverCard}
    >
      <LandlordPropertyViewingCard viewing={viewing} showFeedback={showFeedback} isListMode />
    </Paper>
  ));

  const mobilePastViewings = data.past.map((viewing: RentalViewing) => (
    <Paper
      key={viewing.id}
      shadow="xs"
      withBorder
      p="md"
      onClick={() => onClickViewing(viewing)}
      className={classes.hoverCard}
    >
      <LandlordPropertyViewingCard viewing={viewing} showFeedback={showFeedback} isListMode />
    </Paper>
  ));

  const mobileCancelledViewings = data.cancelled.map((viewing: RentalViewing) => (
    <Paper
      key={viewing.id}
      shadow="xs"
      withBorder
      p="md"
      onClick={() => onClickViewing(viewing)}
      className={classes.hoverCard}
    >
      <LandlordPropertyViewingCard viewing={viewing} showFeedback={showFeedback} isListMode />
    </Paper>
  ));

  return (
    <>
      <Drawer
        size="sm"
        opened={opened}
        onClose={onCloseViewing}
        title={`${activeViewing?.viewingType} Viewing`}
        position="right"
      >
        {activeViewing && (
          <Stack>
            <Paper p="md" withBorder>
              <LandlordPropertyViewingCard viewing={activeViewing} showFeedback={showFeedback} />
            </Paper>

            <Paper p="md" withBorder>
              <Stack gap={0}>
                <Title order={4}>Renter Details</Title>

                <Title order={5} mt="sm">
                  Name
                </Title>
                <Text>{activeViewing.renterName}</Text>

                <Group gap={12} mt="sm">
                  {activeViewing.didntTurnUp && (
                    <Badge variant="light" color="red">
                      Didn&apos;t turn up
                    </Badge>
                  )}
                  {activeViewing.renterOpinion === ViewingOpinion.Interested && (
                    <Badge variant="light" color="blue">
                      Interested
                    </Badge>
                  )}
                  {activeViewing.renterOpinion === ViewingOpinion.NotInterested && (
                    <Badge variant="light" color="red">
                      Not Interested
                    </Badge>
                  )}
                  {activeViewing.renterViewingNumber && (
                    <Badge variant="light" color="blue">
                      {activeViewing.renterViewingNumber}
                      {getNumberSuffix(activeViewing.renterViewingNumber)} viewing
                    </Badge>
                  )}
                </Group>
              </Stack>
            </Paper>

            {activeViewing.cancellationReason && (
              <Paper p="md" withBorder>
                <Stack gap={0}>
                  <Title order={4}>Cancellation Reason</Title>

                  <Spoiler maxHeight={140} showLabel="Show More" hideLabel="Show Less">
                    <Text mt="sm" style={{ whiteSpace: 'pre-line' }}>
                      {activeViewing.cancellationReason}
                    </Text>
                  </Spoiler>
                </Stack>
              </Paper>
            )}

            {showFeedback && activeViewing.renterFeedback && (
              <Paper p="md" withBorder>
                <Stack gap={0}>
                  <Title order={4}>Renter Feedback</Title>

                  <Spoiler maxHeight={140} showLabel="Show More" hideLabel="Show Less">
                    <Text mt="sm" style={{ whiteSpace: 'pre-line' }}>
                      {activeViewing.renterFeedback}
                    </Text>
                  </Spoiler>
                </Stack>
              </Paper>
            )}

            {activeViewing.attendingAgentEmail && (
              <AgentDetailsCard
                data={{
                  label: 'Negotiator',
                  name: activeViewing.attendingAgentName,
                  phone: activeViewing.attendingAgentPhone,
                  email: activeViewing.attendingAgentEmail,
                  avatar: activeViewing.attendingAgentAvatar,
                  initials: activeViewing.attendingAgentInitials,
                }}
              />
            )}
          </Stack>
        )}
      </Drawer>

      {unconfirmedViewings && unconfirmedViewings.length ? (
        <>
          <Group mb="sm" mt="sm" gap={4} align="center">
            <Title order={3}>
              {unconfirmedViewings.length} Unconfirmed{' '}
              {pluraliseWord('Viewing', unconfirmedViewings.length)}
            </Title>
            <HoverCard width={280} shadow="lg" offset={0} position="top" withArrow>
              <HoverCard.Target>
                <ActionIcon radius="xl" variant="subtle">
                  <IconInfoCircle size="1.25rem" />
                </ActionIcon>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text size="sm">
                  Viewings where at least one of the attending parties are unconfirmed.
                </Text>
                <Text size="sm" c="dimmed" fs="italic">
                  Soonest first.
                </Text>
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>

          <Stack mb="xl">
            {isMobile ? (
              <Center>
                <Stack style={{ width: '420px', maxWidth: '100%' }}>
                  {mobileUnconfirmedViewings}
                </Stack>
              </Center>
            ) : (
              <>{unconfirmedViewings}</>
            )}
          </Stack>
        </>
      ) : (
        <>
          <Title order={3} mt={8}>
            No Unconfirmed Viewings
          </Title>
          <Text c="dimmed" size="sm" fs="italic" mb="lg">
            Viewings where at least one of the attending parties are unconfirmed.
          </Text>
        </>
      )}

      <Divider mb="md" />

      {upcomingViewings && upcomingViewings.length ? (
        <>
          <Group mb="sm" gap={4} align="center">
            <Title order={3}>
              {upcomingViewings.length} Upcoming {pluraliseWord('Viewing', upcomingViewings.length)}
            </Title>
            <HoverCard width={280} shadow="lg" offset={0} position="top" withArrow>
              <HoverCard.Target>
                <ActionIcon radius="xl" variant="subtle">
                  <IconInfoCircle size="1.25rem" />
                </ActionIcon>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text size="sm">Viewings that are scheduled to take place.</Text>
                <Text size="sm" c="dimmed" fs="italic">
                  Soonest first.
                </Text>
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>

          <Stack mb="xl">
            {isMobile ? (
              <Center>
                <Stack style={{ width: '420px', maxWidth: '100%' }}>{mobileUpcomingViewings}</Stack>
              </Center>
            ) : (
              <>{upcomingViewings}</>
            )}
          </Stack>
        </>
      ) : (
        <>
          <Title order={3}>No Upcoming Viewings</Title>
          <Text c="dimmed" size="sm" fs="italic" mb="lg">
            Viewings that are scheduled to take place.
          </Text>
        </>
      )}

      <Divider mb="md" />

      {pastViewings && pastViewings.length ? (
        <>
          <Group mb="sm" gap={4} align="center">
            <Title order={3}>
              {pastViewings.length} Past {pluraliseWord('Viewing', pastViewings.length)}
            </Title>
            <HoverCard width={280} shadow="lg" offset={0} position="top" withArrow>
              <HoverCard.Target>
                <ActionIcon radius="xl" variant="subtle">
                  <IconInfoCircle size="1.25rem" />
                </ActionIcon>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text size="sm">Viewings that have taken place.</Text>
                <Text size="sm" c="dimmed" fs="italic">
                  Most recent first
                </Text>
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>

          <Stack mb="xl">
            {isMobile ? (
              <Center>
                <Stack style={{ width: '420px', maxWidth: '100%' }}>{mobilePastViewings}</Stack>
              </Center>
            ) : (
              <>{pastViewings}</>
            )}
          </Stack>
        </>
      ) : (
        <>
          <Title order={3}>No Past Viewings</Title>
          <Text c="dimmed" size="sm" fs="italic" mb="lg">
            Viewings that have taken place.
          </Text>
        </>
      )}

      <Divider mb="md" />

      {cancelledViewings && cancelledViewings.length ? (
        <>
          <Group mb="sm" gap={4} align="center">
            <Title order={3}>
              {cancelledViewings.length} Cancelled{' '}
              {pluraliseWord('Viewing', cancelledViewings.length)}
            </Title>
            <HoverCard width={280} shadow="lg" offset={0} position="top" withArrow>
              <HoverCard.Target>
                <ActionIcon radius="xl" variant="subtle">
                  <IconInfoCircle size="1.25rem" />
                </ActionIcon>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text size="sm">Viewings that have been cancelled.</Text>
                <Text size="sm" c="dimmed" fs="italic">
                  Most recent first
                </Text>
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>

          <Stack mb="xl">
            {isMobile ? (
              <Center>
                <Stack style={{ width: '420px', maxWidth: '100%' }}>
                  {mobileCancelledViewings}
                </Stack>
              </Center>
            ) : (
              <>{cancelledViewings}</>
            )}
          </Stack>
        </>
      ) : (
        <>
          <Title order={3}>No Cancelled Viewings</Title>
          <Text c="dimmed" size="sm" fs="italic" mb="lg">
            Viewings that have been cancelled.
          </Text>
        </>
      )}
    </>
  );
}
