import {
  Title,
  Text,
  Paper,
  Flex,
  Image,
  Group,
  Stack,
  Anchor,
  Center,
  Loader,
} from '@mantine/core';
import { IconPhone, IconInbox, IconWorldWww, IconMap } from '@tabler/icons-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { TeamData } from '@/models/common.model';
import classes from './TeamDetailsCard.module.css';
import httpClient from '@/utils/httpClient';

interface TeamDetailsCardProps {
  teamId: string;
  my?: string;
}

function useTeamData(teamId: string): UseQueryResult<TeamData, Error> {
  return useQuery({
    queryKey: ['teamData', teamId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/users/team/${teamId}`);
      return data;
    },
    enabled: !!teamId,
  });
}

export function TeamDetailsCard({ teamId, my }: TeamDetailsCardProps) {
  const { data, isPending, error } = useTeamData(teamId);

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error?.message}
      </Title>
    );
  }

  if (isPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Paper shadow="xs" my={my} p="md">
      <Flex gap="md" wrap="wrap-reverse">
        <div className={classes.imageWrapper}>
          <Image
            width={260}
            height={182}
            fit="contain"
            src={data.teamLogo}
            alt="Agent Logo"
            fallbackSrc="https://placehold.co/260x182?text=No%20Image"
            className={classes.imageContainer}
          />
        </div>

        <div className={classes.infoWrapper}>
          <Stack gap={8}>
            <Title order={3}>{data.teamName}</Title>
            <Group gap={8}>
              <IconPhone stroke={1.5} />
              <Anchor href={`tel:${data.teamPhoneNumber}`} target="_blank">
                {data.teamPhoneNumber}
              </Anchor>
            </Group>
            <Group gap={8}>
              <IconInbox stroke={1.5} />
              <Anchor href={`mailto:${data.teamEmail}`} target="_blank">
                {data.teamEmail}
              </Anchor>
            </Group>
            <Group gap={8}>
              <IconWorldWww stroke={1.5} />
              <Anchor href={data.teamWebsite} target="_blank">
                {data.teamWebsite}
              </Anchor>
            </Group>
            <Group gap={8} wrap="nowrap" align="flex-start">
              <IconMap stroke={1.5} style={{ flexShrink: 0 }} />
              <Text>{data.teamAddress}</Text>
            </Group>
          </Stack>
        </div>
      </Flex>
    </Paper>
  );
}
