import { Box, Flex, Grid, Group, Paper, Stack, Table, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { LandlordPropertyTenancy } from '@/models/landlord/tenancy.model';

export default function LandlordPropertyTenancyDetails(tenancy: LandlordPropertyTenancy) {
  const isMobile = useMediaQuery('(max-width: 620px)');

  return (
    <Grid p={0}>
      <Grid.Col span={{ base: 12, sm: 5 }}>
        <Paper shadow="xs" p="md" h="100%" withBorder>
          <Title order={4}>Tenancy Details</Title>

          <Flex direction="column" mt={8}>
            <Text lh={1.2} c="dimmed">
              Additional Occupants
            </Text>
            <Text fw={500}>{tenancy.additionalOccupants || 'None'}</Text>
          </Flex>

          <Flex direction="column" mt={8}>
            <Text lh={1.2} c="dimmed">
              Deposit Scheme
            </Text>
            <Text fw={500}>{tenancy.depositScheme || 'Unknown'}</Text>
          </Flex>
        </Paper>
      </Grid.Col>

      <Grid.Col span={{ base: 12, sm: 7 }}>
        <Paper shadow="xs" h="100%" p={isMobile ? 'md' : '0'} withBorder>
          {isMobile ? (
            <Stack gap="xs">
              <Box>
                <Title order={4}>
                  Gas {tenancy.utilities.gasProvider ? `(${tenancy.utilities.gasProvider})` : ''}
                </Title>

                <Group gap={4}>
                  <Text fw={500}>Meter Reading In:</Text>
                  <Text>{tenancy.utilities.gasReadingOut || 'Unknown'}</Text>
                </Group>

                <Group gap={4}>
                  <Text fw={500}>Meter Reading Out:</Text>
                  <Text>{tenancy.utilities.gasReadingOut || 'Unknown'}</Text>
                </Group>
              </Box>

              <Box>
                <Title order={4}>
                  Water{' '}
                  {tenancy.utilities.waterProvider ? `(${tenancy.utilities.waterProvider})` : ''}
                </Title>

                <Group gap={4}>
                  <Text fw={500}>Meter Reading In:</Text>
                  <Text>{tenancy.utilities.waterReadingIn || 'Unknown'}</Text>
                </Group>

                <Group gap={4}>
                  <Text fw={500}>Meter Reading Out:</Text>
                  <Text>{tenancy.utilities.waterReadingOut || 'Unknown'}</Text>
                </Group>
              </Box>

              <Box>
                <Title order={4}>
                  Electricity{' '}
                  {tenancy.utilities.electricProvider
                    ? `(${tenancy.utilities.electricProvider})`
                    : ''}
                </Title>

                <Group gap={4}>
                  <Text fw={500}>Meter Reading In:</Text>
                  <Text>{tenancy.utilities.electricReadingIn || 'Unknown'}</Text>
                </Group>

                <Group gap={4}>
                  <Text fw={500}>Meter Reading Out:</Text>
                  <Text>{tenancy.utilities.electricReadingOut || 'Unknown'}</Text>
                </Group>
              </Box>

              <Box>
                <Title order={4}>Telecoms</Title>

                <Group gap={4}>
                  <Text fw={500}>Provider:</Text>
                  <Text>{tenancy.utilities.telecomsProvider || 'Unknown'}</Text>
                </Group>
              </Box>
            </Stack>
          ) : (
            <>
              <Box pt="md" px="md">
                <Title order={4}>Utilities</Title>
              </Box>

              <Table>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th></Table.Th>
                    <Table.Th>Provider</Table.Th>
                    <Table.Th>Reading In</Table.Th>
                    <Table.Th>Reading Out</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td miw={86}>
                      <Text fw={600} fz="sm">
                        Gas
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      {tenancy.utilities.gasProvider || (
                        <Text c="dimmed" fs="italic" fz="sm">
                          Unknown
                        </Text>
                      )}
                    </Table.Td>
                    <Table.Td>{tenancy.utilities.gasReadingIn}</Table.Td>
                    <Table.Td>{tenancy.utilities.gasReadingOut}</Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td miw={86}>
                      <Text fw={600} fz="sm">
                        Water
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      {tenancy.utilities.waterProvider || (
                        <Text c="dimmed" fs="italic" fz="sm">
                          Unknown
                        </Text>
                      )}
                    </Table.Td>
                    <Table.Td>{tenancy.utilities.waterReadingIn}</Table.Td>
                    <Table.Td>{tenancy.utilities.waterReadingOut}</Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td miw={86}>
                      <Text fw={600} fz="sm">
                        Electricity
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      {tenancy.utilities.electricProvider || (
                        <Text c="dimmed" fs="italic" fz="sm">
                          Unknown
                        </Text>
                      )}
                    </Table.Td>
                    <Table.Td>{tenancy.utilities.electricReadingIn}</Table.Td>
                    <Table.Td>{tenancy.utilities.electricReadingOut}</Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td miw={86}>
                      <Text fw={600} fz="sm">
                        Telecoms
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      {tenancy.utilities.telecomsProvider || (
                        <Text c="dimmed" fs="italic" fz="sm">
                          Unknown
                        </Text>
                      )}
                    </Table.Td>
                    <Table.Td></Table.Td>
                    <Table.Td></Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            </>
          )}
        </Paper>
      </Grid.Col>
    </Grid>
  );
}
