import {
  Badge,
  Center,
  Drawer,
  Group,
  Loader,
  Paper,
  ScrollArea,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconUrgent } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { clsx } from 'clsx';
import httpClient from '@/utils/httpClient';
import { MaintenanceStatus } from '@/models/tenant/maintenance.model';
import { formatDate, formatShortDate } from '@/utils/stringFormat';
import classes from './LandlordPropertyMaintenance.module.css';
import { LandlordPropertyMaintenanceDrawer } from '@/components/Landlord/LandlordPropertyMaintenance/LandlordPropertyMaintenanceDrawer';
import { LandlordPropertyMaintenanceJob } from '@/models/landlord/maintenance.model';

function useMaintenanceJobs(
  propertyId: number
): UseQueryResult<LandlordPropertyMaintenanceJob[], Error> {
  return useQuery({
    queryKey: ['landlord', 'maintenance', propertyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/landlord/${propertyId}/maintenance`);
      return data;
    },
    enabled: !!propertyId,
  });
}

interface LandlordMaintenanceProps {
  propertyId: number;
}

export function LandlordPropertyMaintenance({ propertyId }: LandlordMaintenanceProps) {
  const theme = useMantineTheme();
  const { data, error, isPending } = useMaintenanceJobs(propertyId);
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] = useDisclosure(false);
  const [activeIssue, setActiveIssue] = useState<LandlordPropertyMaintenanceJob | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery('(max-width: 800px)');

  const onClickIssue = (maintenanceJob: LandlordPropertyMaintenanceJob) => {
    setActiveIssue(maintenanceJob);
    openDrawer();
    const newParams = new URLSearchParams({ m: String(maintenanceJob.id) });
    setSearchParams(newParams);
  };

  const onCloseIssueDrawer = () => {
    closeDrawer();
    setSearchParams(undefined);
  };

  useEffect(() => {
    const deepLinkId = searchParams.get('m');
    if (deepLinkId && data) {
      const issue = data.find((m: LandlordPropertyMaintenanceJob) => m.id === Number(deepLinkId));
      if (issue) {
        onClickIssue(issue);
      }
    } else if (drawerOpened) {
      closeDrawer();
    }
  }, [searchParams, data]);

  if (isPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }

  const rows = data?.map((row) => (
    <Table.Tr key={row.id} className={classes.tableRow} onClick={() => onClickIssue(row)}>
      <Table.Td width={44}>
        {row.isEmergency && (
          <Tooltip label="Emergency">
            <IconUrgent color={theme.colors.red[6]} />
          </Tooltip>
        )}
      </Table.Td>
      <Table.Td px={0}>
        {(row.status === MaintenanceStatus.NotAcknowledged ||
          row.status === MaintenanceStatus.Pending) && <Badge color="grey">Pending</Badge>}
        {row.status === MaintenanceStatus.Approved && <Badge color="yellow">Approved</Badge>}
        {(row.status === MaintenanceStatus.Complete ||
          row.status === MaintenanceStatus.InvoiceReceived) && (
          <Badge variant="light" color="green">
            Fixed - Not Paid
          </Badge>
        )}
        {row.status === MaintenanceStatus.InvoicePaid && <Badge color="green">Fixed - Paid</Badge>}
        {row.status === MaintenanceStatus.Cancelled && <Badge color="red">Cancelled</Badge>}
      </Table.Td>
      <Table.Td>{row.summary}</Table.Td>
      <Table.Td>{formatShortDate(row.dateReported)}</Table.Td>
      <Table.Td>{row.jobDate && formatShortDate(row.jobDate)}</Table.Td>
      <Table.Td>
        {row.landlordCost ? (
          <Text>{row.landlordCost}</Text>
        ) : row.estimatedCost ? (
          <Text>{row.estimatedCost} (est.)</Text>
        ) : (
          <Text c="dimmed" fs="italic">
            Awaiting quotes
          </Text>
        )}
      </Table.Td>
    </Table.Tr>
  ));

  const mobileIssues = data?.map((issue) => (
    <Paper
      key={issue.id}
      shadow="xs"
      p="md"
      onClick={() => onClickIssue(issue)}
      className={clsx(classes.issueCard, issue.isEmergency && classes.emergencyIssue)}
    >
      <Stack gap={4}>
        <Group gap="sm">
          {issue.isEmergency && (
            <Tooltip label="Emergency">
              <IconUrgent color={theme.colors.red[6]} />
            </Tooltip>
          )}
          {(issue.status === MaintenanceStatus.NotAcknowledged ||
            issue.status === MaintenanceStatus.Pending) && <Badge color="grey">Pending</Badge>}
          {issue.status === MaintenanceStatus.Approved && <Badge color="yellow">Approved</Badge>}
          {(issue.status === MaintenanceStatus.Complete ||
            issue.status === MaintenanceStatus.InvoiceReceived) && (
            <Badge variant="light" color="green">
              Fixed - Not Paid
            </Badge>
          )}
          {issue.status === MaintenanceStatus.InvoicePaid && (
            <Badge color="green">Fixed - Paid</Badge>
          )}
          {issue.status === MaintenanceStatus.Cancelled && <Badge color="red">Cancelled</Badge>}
        </Group>
        <Title order={5}>{issue.summary}</Title>
        {issue.jobDate && (
          <Group wrap="wrap" gap={0}>
            <Text fw="600" mr={6}>
              Scheduled Fix:
            </Text>
            <Text>{formatDate(issue.jobDate)}</Text>
          </Group>
        )}
        <Group wrap="wrap" gap={0}>
          <Text fw="600" mr={6}>
            Cost:
          </Text>
          {issue.landlordCost ? (
            <Text>{issue.landlordCost}</Text>
          ) : issue.estimatedCost ? (
            <Text>{issue.estimatedCost} (est.)</Text>
          ) : (
            <Text c="dimmed" fs="italic">
              Awaiting quotes
            </Text>
          )}
        </Group>
      </Stack>
    </Paper>
  ));

  return (
    <>
      <Drawer
        size="sm"
        opened={drawerOpened}
        onClose={onCloseIssueDrawer}
        title="Maintenance Issue"
        position="right"
      >
        {activeIssue && <LandlordPropertyMaintenanceDrawer issue={activeIssue} />}
      </Drawer>

      {data && data.length > 0 ? (
        <>
          {!isMobile && (
            <Paper shadow="xs" my="sm">
              <Title order={3} mb="xs" px="md" pt="md">
                Maintenance Issues
              </Title>

              <ScrollArea h={500}>
                <Table stickyHeader highlightOnHover>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th></Table.Th>
                      <Table.Th></Table.Th>
                      <Table.Th>Summary</Table.Th>
                      <Table.Th>Date Reported</Table.Th>
                      <Table.Th>Scheduled Repair Date</Table.Th>
                      <Table.Th>Cost</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>{rows}</Table.Tbody>
                </Table>
              </ScrollArea>
            </Paper>
          )}

          {isMobile && (
            <Stack mt="xs">
              <Title order={3}>Maintenance Issues</Title>

              <Center>
                <Stack w="100%">{mobileIssues}</Stack>
              </Center>
            </Stack>
          )}
        </>
      ) : (
        <Group justify="space-between" wrap="wrap-reverse" my="sm">
          <Title order={4}>There are no maintenance issues on this property</Title>
        </Group>
      )}
    </>
  );
}
