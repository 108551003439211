export interface LandlordDashboardData {
  properties: LandlordPropertySummary[];
  tenancies: LandlordTenancySummary[];
}

export interface LandlordPropertySummary {
  id: number;
  teamId: string;
  personId: number;
  addressLine1: string;
  addressLine2: string;
  marketingRent: number;
  rentFrequency: RentFrequencyEnum;
  daysOnMarket: number;
  upcomingViewings: number;
  newApplications: number;
  status: string;
  tenancyStatus: string;
  isTenanted: boolean;
  agentLogo: string;
  propertyImage: string;
}

export interface LandlordTenancySummary {
  id: number;
  propertyId: number;
  addressLine1: string;
  addressLine2: string;
  propertyImage: string;
  startDate: string;
  endDate: string;
  currentRent: number;
  currentRentFrequency: RentFrequencyEnum;
  tenantNames: string;
  status: string;
}

export interface LandlordPropertySettings {
  propertyId: number;
  hasAccess: boolean;
  hasMarketing: boolean;
  hasViewings: boolean;
  hasViewingFeedback: boolean;
  hasApplications: boolean;
  hasTenancies: boolean;
  hasTenancyInspections: boolean;
  hasCertificates: boolean;
  hasMaintenanceJobs: boolean;
}

export interface LandlordPropertyMarketingChannel {
  channel: number;
  dateListed: string;
  listingURL: string;
}

export enum RentFrequencyEnum {
  PerWeek = 2,
  PerCalendarMonth = 3,
  PerAnnum = 4,
  Per4Weeks = 5,
  PerQuarter = 6,
  PerSixMonths = 7,
  PerAnnumExclusive = 8,
}

export const RentFrequencyToShortName = {
  [RentFrequencyEnum.PerWeek]: 'pw',
  [RentFrequencyEnum.PerCalendarMonth]: 'pcm',
  [RentFrequencyEnum.PerAnnum]: 'pa',
  [RentFrequencyEnum.Per4Weeks]: 'p4w',
  [RentFrequencyEnum.PerQuarter]: 'pq',
  [RentFrequencyEnum.PerSixMonths]: 'p6m',
  [RentFrequencyEnum.PerAnnumExclusive]: 'pax',
};
