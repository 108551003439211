import { Badge, Button, Card, Flex, Group, Image, Stack, Text, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useHover } from '@mantine/hooks';
import { IconChevronRight } from '@tabler/icons-react';
import {
  LandlordTenancySummary,
  RentFrequencyToShortName,
} from '@/models/landlord/rental-property.model';
import { formatCurrency, formatDate } from '@/utils/stringFormat';
import classes from './LandlordTenancies.module.css';

interface LandlordTenanciesProps {
  tenancies: LandlordTenancySummary[];
}

export function LandlordTenancies({ tenancies }: LandlordTenanciesProps) {
  const { hovered, ref } = useHover();

  if (!tenancies || tenancies.length === 0) {
    return (
      <Stack gap={0} align="center">
        <Title order={4}>No tenancies are linked to your account yet.</Title>
        <Text>Tenancies shared by your estate agent will appear here.</Text>
      </Stack>
    );
  }

  return (
    <Flex
      direction={{ base: 'row' }}
      gap={{ base: 'md' }}
      justify={{ base: 'center', xs: 'center', sm: 'flex-start' }}
      wrap="wrap"
    >
      {tenancies?.map((tenancy) => (
        <Link
          key={tenancy.id}
          to={`/landlord/property/${tenancy.propertyId}/tenancies?t=${tenancy.id}`}
          style={{ textDecoration: 'none' }}
        >
          <Card
            ref={ref}
            shadow={hovered ? 'lg' : 'sm'}
            padding="lg"
            className={classes.cardWrapper}
          >
            <Card.Section style={{ position: 'relative' }}>
              <Image
                width={298}
                height={156}
                src={`${tenancy.propertyImage}?width=298&height=156&aspect_ratio=298:156`}
                fallbackSrc="https://placehold.co/156x156?text=No%20Image"
                alt="Property"
              />
              <Badge size="lg" radius={0} variant="filled" className={classes.statusBadge}>
                {tenancy.status}
              </Badge>
            </Card.Section>

            <Group justify="space-between" mt="md">
              <Text fw={500}>{tenancy.addressLine1}</Text>
            </Group>

            <Text>{tenancy.tenantNames}</Text>

            <Text>
              {formatCurrency(tenancy.currentRent)}{' '}
              {RentFrequencyToShortName[tenancy.currentRentFrequency]}
            </Text>

            <Text>
              {formatDate(tenancy.startDate, 'DD/MM/YYYY')}
              {tenancy.endDate && ` - ${formatDate(tenancy.endDate, 'DD/MM/YYYY')}`}
            </Text>

            <Button
              mt="sm"
              variant="subtle"
              mb={-8}
              size="compact-xs"
              fullWidth
              rightSection={<IconChevronRight size="1rem" />}
            >
              View Details
            </Button>
          </Card>
        </Link>
      ))}
    </Flex>
  );
}
