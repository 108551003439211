import { useContext, createContext, useState } from 'react';
import Cookies from 'js-cookie';
import { AuthUser } from '@/models/auth/auth.model';

const AuthContext = createContext({} as AuthContextType);

type AuthContextType = {
  token: string;
  refreshToken: string;
  user: AuthUser | null;
  loginAction: (data: any) => void;
  logOut: () => void;
};

const AuthProvider = ({ children }: any) => {
  const [token, setToken] = useState(Cookies.get('_auth') || '');
  const [refreshToken, setRefreshToken] = useState(Cookies.get('_auth_refresh') || '');
  const [user, setUser] = useState<AuthUser>(JSON.parse(Cookies.get('_auth_user') || 'null'));

  const loginAction = (data: any) => {
    setToken(data.jwtToken);
    Cookies.set('_auth', data.jwtToken, {
      expires: new Date(new Date().getTime() + 60 * 60 * 1000), // 60 minutes
      sameSite: 'strict',
      secure: window.location.protocol === 'https:',
    });

    setRefreshToken(data.refreshToken);
    Cookies.set('_auth_refresh', data.refreshToken, {
      expires: 14, // 14 days
      sameSite: 'strict',
      secure: window.location.protocol === 'https:',
    });

    const userData = {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    };
    setUser(userData);
    Cookies.set('_auth_user', JSON.stringify(userData), {
      expires: 14, // 14 days
      sameSite: 'strict',
      secure: window.location.protocol === 'https:',
    });
  };

  const logOut = () => {
    setToken('');
    setRefreshToken('');
    setUser(JSON.parse('null'));
    Cookies.remove('_auth');
    Cookies.remove('_auth_refresh');
    Cookies.remove('_auth_user');
  };

  return (
    <AuthContext.Provider value={{ token, refreshToken, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
