import {
  Paper,
  Title,
  Center,
  Loader,
  Table,
  Stack,
  Button,
  Group,
  ScrollArea,
  Badge,
  Tooltip,
  Drawer,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconUrgent } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { clsx } from 'clsx';
import httpClient from '@/utils/httpClient';
import { RaiseMaintenanceIssueModal } from './RaiseMaintenanceIssueModal';
import { MaintenanceJob, MaintenanceStatus } from '@/models/tenant/maintenance.model';
import { formatDate, formatShortDate } from '@/utils/stringFormat';
import classes from './TenancyMaintenance.module.css';
import { TenancyMaintenanceDrawer } from '@/components/Tenant/TenancyMaintenance/TenancyMaintenanceDrawer';

function useMaintenanceJobs(tenancyId: number): UseQueryResult<MaintenanceJob[], Error> {
  return useQuery({
    queryKey: ['tenancy', 'maintenance', tenancyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/tenancies/${tenancyId}/maintenance`);
      return data;
    },
    enabled: !!tenancyId,
  });
}

interface TenancyMaintenanceProps {
  tenancyId: number;
  allowRaiseMaintenance: boolean;
}

export function TenancyMaintenance({ tenancyId, allowRaiseMaintenance }: TenancyMaintenanceProps) {
  const theme = useMantineTheme();
  const { data, error, isPending } = useMaintenanceJobs(tenancyId);
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] = useDisclosure(false);
  const [activeIssue, setActiveIssue] = useState<MaintenanceJob | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery('(max-width: 800px)');

  const onClickIssue = (maintenanceJob: MaintenanceJob) => {
    setActiveIssue(maintenanceJob);
    openDrawer();
    const newParams = new URLSearchParams({ i: String(maintenanceJob.id) });
    setSearchParams(newParams);
  };

  const onCloseIssueDrawer = () => {
    closeDrawer();
    setSearchParams(undefined);
  };

  useEffect(() => {
    const deepLinkId = searchParams.get('i');
    if (deepLinkId && data) {
      const issue = data.find((m: MaintenanceJob) => m.id === Number(deepLinkId));
      if (issue) {
        onClickIssue(issue);
      }
    } else if (drawerOpened) {
      closeDrawer();
    }
  }, [searchParams, data]);

  if (isPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }

  const rows = data?.map((row: MaintenanceJob) => (
    <Table.Tr key={row.id} className={classes.tableRow} onClick={() => onClickIssue(row)}>
      <Table.Td width={44}>
        {row.emergency && (
          <Tooltip label="Emergency">
            <IconUrgent color={theme.colors.red[6]} />
          </Tooltip>
        )}
      </Table.Td>
      <Table.Td px={0}>
        {row.status === MaintenanceStatus.Cancelled ? (
          <Badge color="red">Cancelled</Badge>
        ) : row.completed ? (
          <Badge color="green">Fixed</Badge>
        ) : (
          <Badge color="grey">Pending</Badge>
        )}
      </Table.Td>
      <Table.Td>{row.summary}</Table.Td>
      <Table.Td>{formatShortDate(row.dateReported)}</Table.Td>
      <Table.Td>{row.jobDate && formatShortDate(row.jobDate)}</Table.Td>
    </Table.Tr>
  ));

  const mobileIssues = data?.map((issue: MaintenanceJob) => (
    <Paper
      key={issue.id}
      shadow="xs"
      p="md"
      onClick={() => onClickIssue(issue)}
      className={clsx(classes.issueCard, issue.emergency && classes.emergencyIssue)}
    >
      <Stack gap={4}>
        <Group gap="sm">
          {issue.emergency && (
            <Tooltip label="Emergency">
              <IconUrgent color={theme.colors.red[6]} />
            </Tooltip>
          )}
          {issue.status === MaintenanceStatus.Cancelled ? (
            <Badge color="red">Cancelled</Badge>
          ) : issue.completed ? (
            <Badge color="green">Fixed</Badge>
          ) : (
            <Badge color="grey">Pending</Badge>
          )}
        </Group>
        <Title order={4}>{issue.summary}</Title>
        {issue.jobDate && (
          <Group wrap="wrap" gap={0}>
            <Text fw="600" mr={6}>
              Scheduled Fix:
            </Text>
            <Text>{formatDate(issue.jobDate)}</Text>
          </Group>
        )}
      </Stack>
    </Paper>
  ));

  return (
    <>
      <Drawer
        size="sm"
        opened={drawerOpened}
        onClose={onCloseIssueDrawer}
        title="Maintenance Issue"
        position="right"
      >
        {activeIssue && <TenancyMaintenanceDrawer issue={activeIssue} />}
      </Drawer>

      {data && data.length > 0 ? (
        <>
          {!isMobile && (
            <Paper shadow="xs" my="sm">
              <Group justify="space-between" mb="xs" px="md" pt="md">
                <Title order={3}>Maintenance Issues</Title>
                {allowRaiseMaintenance && <Button onClick={openModal}>Raise Issue</Button>}
              </Group>

              <ScrollArea h={500}>
                <Table stickyHeader highlightOnHover>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th></Table.Th>
                      <Table.Th></Table.Th>
                      <Table.Th>Summary</Table.Th>
                      <Table.Th>Date Reported</Table.Th>
                      <Table.Th>Scheduled Repair Date</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>{rows}</Table.Tbody>
                </Table>
              </ScrollArea>
            </Paper>
          )}

          {isMobile && (
            <Stack mt="xs">
              <Group justify="space-between" wrap="wrap-reverse">
                <Title order={3}>Maintenance Issues</Title>
                {allowRaiseMaintenance && <Button onClick={openModal}>Raise Issue</Button>}
              </Group>

              <Center>
                <Stack w="100%">{mobileIssues}</Stack>
              </Center>
            </Stack>
          )}
        </>
      ) : (
        <Group justify="space-between" wrap="wrap-reverse" my="sm">
          <Title order={4}>There are no maintenance issues on this property</Title>
          {allowRaiseMaintenance && <Button onClick={openModal}>Raise Issue</Button>}
        </Group>
      )}

      <RaiseMaintenanceIssueModal tenancyId={tenancyId} opened={modalOpened} close={closeModal} />
    </>
  );
}
