import {
  Paper,
  TextInput,
  PasswordInput,
  Button,
  Title,
  Anchor,
  Group,
  Loader,
  Alert,
} from '@mantine/core';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons-react';
import { useState } from 'react';
import classes from './LoginForm.module.css';
import httpClient from '@/utils/httpClient';
import { useAuth } from '@/hooks/useAuth';

export function LoginForm() {
  const location = useLocation();
  const [errorMessage, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) => (value.length > 0 ? null : 'Invalid email'),
      password: (value) => (value.length > 0 ? null : 'Invalid password'),
    },
  });

  const onSubmit = (values: any) => {
    setLoading(true);
    setError('');

    httpClient
      .post('/users/authenticate', values)
      .then((res) => {
        auth.loginAction(res.data);
        const from = location.state?.from;
        const url = from?.pathname ? from.pathname + from.search : '/';
        if (url === '/auth/login') {
          navigate('/');
        } else {
          navigate(url);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
        throw new Error(err);
      });
  };

  return (
    <Paper className={classes.form} radius={0} p={30}>
      <Title order={2} className={classes.title} ta="center" mt="md" mb={40}>
        Welcome to PropertyHub
      </Title>

      <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        <TextInput
          withAsterisk
          label="Email address"
          placeholder="hello@gmail.com"
          size="md"
          {...form.getInputProps('email')}
        />
        <PasswordInput
          withAsterisk
          label="Password"
          placeholder="Your password"
          mt="md"
          size="md"
          {...form.getInputProps('password')}
        />

        <Group mt="lg">
          <Anchor component={Link} to="/auth/forgot-password">
            Forgot password?
          </Anchor>
        </Group>

        {errorMessage !== '' && (
          <Alert
            mt="md"
            icon={<IconAlertCircle size="1rem" />}
            title="Oops!"
            color="red"
            className={classes.error}
          >
            Username or password is incorrect
          </Alert>
        )}

        <Button fullWidth mt="md" size="md" type="submit" disabled={loading}>
          {loading ? <Loader color="blue" size="xs" variant="bars" /> : 'Login'}
        </Button>
      </form>
    </Paper>
  );
}
