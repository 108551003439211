import { Flex, Card, Image, Group, Text, Badge, Button, Stack, Title } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconChevronRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import classes from './LandlordProperties.module.css';
import { formatCurrency, pluraliseWord } from '@/utils/stringFormat';
import {
  LandlordPropertySummary,
  RentFrequencyToShortName,
} from '@/models/landlord/rental-property.model';

interface LandlordPropertiesProps {
  properties: LandlordPropertySummary[];
}

export function LandlordProperties({ properties }: LandlordPropertiesProps) {
  const { hovered, ref } = useHover();

  if (!properties || properties.length === 0) {
    return (
      <Stack gap={0} align="center">
        <Title order={4}>No properties are linked to your account yet.</Title>
        <Text>Properties shared by your estate agent will appear here.</Text>
      </Stack>
    );
  }

  return (
    <Flex
      direction={{ base: 'row' }}
      gap={{ base: 'md' }}
      justify={{ base: 'center', xs: 'center', sm: 'flex-start' }}
      wrap="wrap"
    >
      {properties?.map((property: LandlordPropertySummary) => (
        <Link
          key={property.id}
          to={`/landlord/property/${property.id}`}
          style={{ textDecoration: 'none' }}
        >
          <Card
            ref={ref}
            shadow={hovered ? 'lg' : 'sm'}
            padding="lg"
            className={classes.cardWrapper}
          >
            <Card.Section style={{ position: 'relative' }}>
              <Image
                width={298}
                height={156}
                src={`${property.propertyImage}?width=298&height=156&aspect_ratio=298:156`}
                fallbackSrc="https://placehold.co/156x156?text=No%20Image"
                alt="Property"
              />
              <Badge size="lg" radius={0} variant="filled" className={classes.statusBadge}>
                {property.status} - {property.tenancyStatus}
              </Badge>
            </Card.Section>

            <Group justify="space-between" mt="md">
              <Text fw={500}>{property.addressLine1}</Text>
            </Group>

            <Text fw={500}>{property.addressLine2}</Text>

            <Text>
              {formatCurrency(property.marketingRent)}{' '}
              {RentFrequencyToShortName[property.rentFrequency]}
            </Text>

            <Group mt="xs" gap={8}>
              {property.upcomingViewings !== null && (
                <Badge variant="outline" color="violet">
                  {property.upcomingViewings} upcoming{' '}
                  {pluraliseWord('viewing', property.upcomingViewings)}
                </Badge>
              )}
              {property.newApplications !== null && (
                <Badge variant="outline" color="grape">
                  {property.newApplications} new{' '}
                  {pluraliseWord('application', property.newApplications)}
                </Badge>
              )}
            </Group>

            <Button
              mt="sm"
              variant="subtle"
              mb={-8}
              size="compact-xs"
              fullWidth
              rightSection={<IconChevronRight size="1rem" />}
            >
              View Details
            </Button>
          </Card>
        </Link>
      ))}
    </Flex>
  );
}
