import { Title, Badge, Stack, Text, Group, Divider } from '@mantine/core';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import { formatCurrency, formatShortDateTime } from '@/utils/stringFormat';
import { Application, ApplicationStatus } from '@/models/landlord/application.model';
import { RentFrequencyToShortName } from '@/models/landlord/rental-property.model';
import { roundNumber } from '@/utils/numberFormat';

interface PropertyApplicationCardProps {
  application: Application;
}

export function LandlordPropertyApplicationCard({ application }: PropertyApplicationCardProps) {
  const getAskingRentText = (difference: number) => {
    if (difference < 0) {
      return (
        <>
          <IconArrowDown size={16} color="red" />
          <Text>{roundNumber(Math.abs(difference), 1)}% below asking rent</Text>
        </>
      );
    }

    return (
      <>
        <IconArrowUp size={16} color="green" />
        <Text>{roundNumber(difference, 1)}% above asking rent</Text>
      </>
    );
  };

  return (
    <Stack gap={6} align="center">
      {application.status === ApplicationStatus.NewApplication && (
        <Badge variant="light" size="lg">
          New
        </Badge>
      )}
      {application.status === ApplicationStatus.Accepted && (
        <Badge variant="light" color="green" size="lg">
          Accepted
        </Badge>
      )}
      {application.status === ApplicationStatus.HoldingDepositPaid && (
        <Badge variant="light" color="teal" size="lg">
          Holding Deposit Paid
        </Badge>
      )}
      {application.status === ApplicationStatus.Rejected && (
        <Badge variant="light" color="red" size="lg">
          Rejected
        </Badge>
      )}
      {application.status === ApplicationStatus.Withdrawn && (
        <Badge variant="light" color="red" size="lg">
          Withdrawn
        </Badge>
      )}

      <Group gap={4} align="flex-end">
        <Title>{formatCurrency(application.rentOffered)} </Title>
        <Text size="xl">{RentFrequencyToShortName[application.rentFrequency]}</Text>
      </Group>

      {application.percentageDifference !== 0 && application.rentOffered ? (
        <Group gap={4}>{getAskingRentText(application.percentageDifference)}</Group>
      ) : (
        <Text fs="italic">Matches the asking rent</Text>
      )}
      <Divider style={{ width: '100%' }} />
      <Text c="dimmed">Received: {formatShortDateTime(application.dateApplied)}</Text>
    </Stack>
  );
}
