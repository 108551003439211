import {
  Text,
  Title,
  Paper,
  Table,
  Image,
  Button,
  Stack,
  Group,
  Center,
  Loader,
  useMantineColorScheme,
} from '@mantine/core';
import { IconExternalLink } from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import classes from './LandlordPropertyMarketing.module.css';
import { formatDate } from '@/utils/stringFormat';
import httpClient from '@/utils/httpClient';
import HomeflowLogo from '@/public/logos/homeflow.png';
import OnTheMarketLogo from '@/public/logos/onthemarket.png';
import RightmoveLogo from '@/public/logos/rightmove.png';
import RightmoveDarkLogo from '@/public/logos/rightmove_dark.png';
import ZooplaLogo from '@/public/logos/zoopla.png';
import { TeamData } from '@/models/common.model';
import { LandlordPropertyMarketingChannel } from '@/models/landlord/rental-property.model';

function useMarketingData(
  propertyId: number
): UseQueryResult<LandlordPropertyMarketingChannel[], Error> {
  return useQuery({
    queryKey: ['landlord', 'marketing', propertyId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/landlord/${propertyId}/marketing-summary`);
      return data;
    },
    enabled: !!propertyId,
  });
}

function useTeamData(teamId: string): UseQueryResult<TeamData, Error> {
  return useQuery({
    queryKey: ['teamData', teamId],
    queryFn: async () => {
      const { data } = await httpClient.get(`/users/team/${teamId}`);
      return data;
    },
    enabled: !!teamId,
  });
}

interface LandlordPropertyMarketingProps {
  propertyId: number;
  teamId: string;
}

export function LandlordPropertyMarketing({ propertyId, teamId }: LandlordPropertyMarketingProps) {
  const { colorScheme } = useMantineColorScheme();
  const isMobile = useMediaQuery('(max-width: 620px)');
  const { data: marketingData, error, isPending } = useMarketingData(propertyId);
  const { data: teamData } = useTeamData(teamId);

  if (error) {
    return (
      <Title my="lg" order={4}>
        An error has occurred: {error.message}
      </Title>
    );
  }
  if (isPending) {
    return (
      <Center>
        <Loader my="lg" size="lg" variant="bars" />
      </Center>
    );
  }

  const getChannelImage = (channel: number) => {
    if (channel === 1) {
      return (
        <Image
          w={113}
          h={23}
          src={colorScheme === 'dark' ? RightmoveDarkLogo : RightmoveLogo}
          alt="Rightmove"
        />
      );
    }
    if (channel === 2) {
      return <Image w={94} h={23} src={OnTheMarketLogo} alt="OnTheMarket" />;
    }
    if (channel === 3) {
      return <Image w={101} h={18} src={ZooplaLogo} alt="Zoopla" />;
    }
    if (channel === 9) {
      return (
        <Image w={135} h={23} src={HomeflowLogo} alt="Homeflow" className={classes.homeflowLogo} />
      );
    }
    if (channel === 100) {
      return (
        <Text fw={600} size={isMobile ? 'lg' : 'sm'}>
          {teamData?.teamWebsite ? teamData.teamWebsite.replace('https://', '') : 'Website'}
        </Text>
      );
    }
    return <></>;
  };

  const rows = marketingData.map((row) => (
    <Table.Tr key={row.channel}>
      <Table.Td style={{ paddingLeft: '1rem' }}>{getChannelImage(row.channel)}</Table.Td>
      <Table.Td miw={110}>{formatDate(row.dateListed)}</Table.Td>
      <Table.Td style={{ width: '152px', paddingRight: '1rem' }}>
        {row.listingURL && row.channel !== 100 && (
          <Button
            component="a"
            target="_blank"
            href={row.listingURL}
            variant="outline"
            size="compact-sm"
            leftSection={<IconExternalLink size="1rem" />}
          >
            Open Listing
          </Button>
        )}
      </Table.Td>
    </Table.Tr>
  ));

  const mobileChannels = marketingData.map((row) => (
    <Paper key={row.channel} shadow="xs" p="md">
      <Stack gap={4} align="center">
        {getChannelImage(row.channel)}

        <Group gap={4} mt={4}>
          <Text fw={600}>Listed:</Text>
          <Text>{formatDate(row.dateListed, 'ddd Do MMMM YYYY')}</Text>
        </Group>

        {row.listingURL && row.channel !== 100 && (
          <Button
            component="a"
            target="_blank"
            href={row.listingURL}
            variant="filled"
            size="sm"
            mt={4}
            leftSection={<IconExternalLink size="1rem" />}
          >
            Open Listing
          </Button>
        )}
      </Stack>
    </Paper>
  ));

  return (
    <>
      {marketingData && marketingData.length > 0 ? (
        <>
          {isMobile ? (
            <Stack gap="sm" my="sm">
              {mobileChannels}
            </Stack>
          ) : (
            <Paper shadow="xs" my="sm">
              <Title order={3} mb="xs" px="md" pt="md">
                Marketing
              </Title>

              <div className={classes.tableWrapper}>
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th />
                      <Table.Th>Listed</Table.Th>
                      <Table.Th />
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>{rows}</Table.Tbody>
                </Table>
              </div>
            </Paper>
          )}
        </>
      ) : (
        <Title order={4} my="sm">
          This property is currently not listed anywhere
        </Title>
      )}
    </>
  );
}
