import { Title, Badge, Stack, Text, Group, Divider } from '@mantine/core';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import { Offer, OfferStatus } from '@/models/selling/offers.model';
import { formatCurrency, formatShortDateTime } from '@/utils/stringFormat';
import { roundNumber } from '@/utils/numberFormat';

interface PropertyOfferCardProps {
  offer: Offer;
}

export function SellingPropertyOfferCard({ offer }: PropertyOfferCardProps) {
  const getPercentBelowPrice = (price: number, amount: number) =>
    roundNumber(((price - amount) / price) * 100, 1);
  const getPercentAbovePrice = (price: number, amount: number) =>
    roundNumber(((amount - price) / price) * 100, 1);

  const getAskingPriceText = (marketingPrice: number, amount: number) => {
    if (marketingPrice > amount) {
      return (
        <>
          <IconArrowDown size={16} color="red" />
          <Text>{getPercentBelowPrice(marketingPrice, amount)}% below asking price</Text>
        </>
      );
    }
    if (marketingPrice < amount) {
      return (
        <>
          <IconArrowUp size={16} color="green" />
          <Text>{getPercentAbovePrice(marketingPrice, amount)}% above asking price</Text>
        </>
      );
    }
    return (
      <>
        <Text>Matches the asking price</Text>
      </>
    );
  };

  return (
    <Stack gap={6} align="center">
      {offer.offerStatus === OfferStatus.New && (
        <Badge variant="light" size="lg">
          New
        </Badge>
      )}
      {offer.offerStatus === OfferStatus.SentToSeller && (
        <Badge variant="light" size="lg">
          New
        </Badge>
      )}
      {offer.offerStatus === OfferStatus.Accepted && (
        <Badge variant="light" color="green" size="lg">
          Accepted
        </Badge>
      )}
      {offer.offerStatus === OfferStatus.Rejected && (
        <Badge variant="light" color="red" size="lg">
          Rejected
        </Badge>
      )}
      {offer.offerStatus === OfferStatus.Withdrawn && (
        <Badge variant="light" color="red" size="lg">
          Withdrawn
        </Badge>
      )}
      {offer.offerStatus === OfferStatus.FallenThrough && (
        <Badge variant="light" color="red" size="lg">
          Fallen Through
        </Badge>
      )}

      <Title>{formatCurrency(offer.offerAmount)}</Title>

      {offer.marketingPrice && offer.offerAmount && (
        <Group gap={4}>{getAskingPriceText(offer.marketingPrice, offer.offerAmount)}</Group>
      )}
      <Divider style={{ width: '100%' }} />
      <Text c="dimmed">Received: {formatShortDateTime(offer.offerDate)}</Text>
    </Stack>
  );
}
