import { Text, Title, Grid, Image } from '@mantine/core';
import classes from './DashboardHero.module.css';
import { getGreetingMessage } from '@/utils/stringFormat';
import HeroImg from '@/public/property_image.svg';
import { useAuth } from '@/hooks/useAuth';

export function DashboardHero() {
  const auth = useAuth();

  return (
    <Grid className={classes.wrapper}>
      <Grid.Col span={{ base: 12, md: 3 }} className={classes.imageWrapper}>
        <Image w={150} h={92} src={HeroImg} alt="" className={classes.image} />
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 9 }} className={classes.textWrapper}>
        <Title fz={{ base: 28, md: 32 }} fw={800} className={classes.title}>
          {getGreetingMessage()}{' '}
          <Text component="span" className={classes.highlight} variant="gradient" inherit>
            {`${auth.user?.firstName} ${auth.user?.lastName}`}
          </Text>
          !
        </Title>
      </Grid.Col>
    </Grid>
  );
}
